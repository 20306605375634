import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"

import Card from "../../components/Card"
import Stack from "../../components/Stack"
import Button from "../../components/Button"
import Text from "../../components/Text"
import Toggle from "../../components/Toggle"
import { Column, Columns } from "../../components/Column"
import { WebPushContext } from "../../context/WebPushContext"
import Dropdown, { DropdownOption } from "../Dropdown"
import Alert from "../Alert"
import { PushNotificationType } from "../../generated/graphql"

const PREFERENCE_OPTIONS: {
  [K in PushNotificationType]: DropdownOption<number>[]
} = {
  order_reminder: [
    { label: "Every 10 minutes", value: 10 },
    { label: "Every 15 minutes", value: 15 },
    { label: "Every 30 minutes", value: 30 }
  ],
  shipment_reminder: [
    { label: "Every 10 minutes", value: 10 },
    { label: "Every hour", value: 60 },
    { label: "Every 24 hours", value: 24 * 60 }
  ]
}

export const Notifications = (): JSX.Element => {
  const webpush = useContext(WebPushContext)

  const permission = webpush.getPermissionStatus()

  const [preferences, setPreferences] = useState<Map<PushNotificationType, number>>(new Map())

  const notificationsEnabled = useMemo(() => {
    if (permission === "granted") {
      return webpush.subscribed
    }
    return false
  }, [permission, webpush.subscribed])

  const requestPermission = async () => {
    const result = await webpush.requestPermission()
    if (result === "granted") {
      webpush.createSubscription()
    }
  }

  const getPreferenceValue = useCallback(
    (type: PushNotificationType) => {
      const savedPreference = webpush.subscription?.preferences.find(
        (p) => p.notification_type === type
      )
      const statePreference = preferences.get(type)
      return (
        statePreference ||
        savedPreference?.minimum_interval_minutes ||
        PREFERENCE_OPTIONS[type][0].value
      )
    },
    [webpush.subscription, preferences]
  )

  const handleToggle: React.ChangeEventHandler<HTMLInputElement> = () => {
    if (permission === "default") {
      // eslint-disable-next-line no-console
      console.log("Permission is default, requesting...")
      requestPermission()
    } else if (permission === "granted" && !webpush.subscribed) {
      // eslint-disable-next-line no-console
      console.log("Permission already granted, creating subscription")
      webpush.createSubscription()
    }
  }

  const handleDropdownChange: React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    const notification_type = ev.target.name
    const min_interval_minutes = parseInt(ev.target.value)

    const newMap = new Map(preferences)
    newMap.set(notification_type as unknown as PushNotificationType, min_interval_minutes)
    setPreferences(newMap)
  }

  const handleSave = () => {
    webpush.updatePreferences(
      Array.from(preferences.entries()).map((entry) => {
        const [notification_type, minimum_interval_minutes] = entry
        return {
          notification_type,
          minimum_interval_minutes
        }
      })
    )
  }

  useEffect(() => {
    setPreferences(new Map())
  }, [webpush.subscription])

  return (
    <Card>
      <Stack hasDivider>
        <Stack>
          <Columns>
            <Column>
              <Stack space="tiny">
                <Text intent="dark" weight="bold">
                  Push notifications
                </Text>
                {notificationsEnabled && (
                  <Text size="small">
                    To disable notifications, go into your browser notifications settings.
                  </Text>
                )}
              </Stack>
            </Column>
            <Column width="content">
              <Toggle
                id="push"
                disabled={notificationsEnabled || permission === "denied"}
                onChange={handleToggle}
                checked={notificationsEnabled}
              />
            </Column>
          </Columns>
          {permission === "denied" && (
            <Alert intent="warning">
              To enable notifications, go into your browser notifications settings.
            </Alert>
          )}
        </Stack>
        <Stack>
          <Columns>
            <Column width="content">
              <Stack>
                <Dropdown
                  id={PushNotificationType.OrderReminder}
                  name={PushNotificationType.OrderReminder}
                  label="Order Reminder"
                  options={PREFERENCE_OPTIONS.order_reminder}
                  value={getPreferenceValue(PushNotificationType.OrderReminder)}
                  onChange={handleDropdownChange}
                  disabled={!notificationsEnabled}
                />
                <Dropdown
                  id={PushNotificationType.ShipmentReminder}
                  name={PushNotificationType.ShipmentReminder}
                  label="Shipment confirmation reminder"
                  options={PREFERENCE_OPTIONS.shipment_reminder}
                  value={getPreferenceValue(PushNotificationType.ShipmentReminder)}
                  onChange={handleDropdownChange}
                  disabled={!notificationsEnabled}
                />
              </Stack>
            </Column>
          </Columns>
          <Button
            intent="primary"
            disabled={!notificationsEnabled || preferences.size === 0 || webpush.isLoading}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Card>
  )
}

export const NotificationsDisabled = (): JSX.Element => {
  return <Alert>Notifications are not supported on this platform.</Alert>
}
