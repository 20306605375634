import React, { useRef } from "react"
import styled from "styled-components"
import { rgba, stripUnit } from "polished"
import { Dialog } from "@headlessui/react"
import { motion, AnimatePresence } from "framer-motion"

import { black, white, borderColor } from "../../styles/colors"
import * as spacing from "../../styles/spacing"
import { lineHeights } from "../../styles/typography"
import { cover, square, boxShadow } from "../../styles/helpers"
import { animationTime, animationCurve } from "../../styles/variables"

import Icon from "../Icon"
import Inline from "../Inline"
import Heading from "../Heading"

const DURATION = stripUnit(animationTime)

const drawerVariants = {
  initial: {
    opacity: 0,
    x: "100%",
    transition: { duration: DURATION }
  },
  enter: { opacity: 1, x: 0, transition: { duration: DURATION } }
}

const backdropVariants = {
  initial: {
    opacity: 0,
    transition: { duration: DURATION }
  },
  enter: { opacity: 1, transition: { duration: DURATION } }
}

export type DrawerProps = {
  heading: React.ReactNode
  headingAddon?: React.ReactNode
  children: React.ReactNode
  headerActions?: React.ReactNode
  isOpen: boolean
  onClose: () => void
}

const Backdrop = styled(Dialog.Overlay)`
  background-color: ${rgba(black, 0.75)};
  ${cover("fixed")};
`

const Outer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  max-width: 660px;
  width: 100%;
  padding-left: 72px; /* Compensate for close button */
`

const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Panel = styled.div`
  overflow-y: scroll;
  height: 100%;
  background-color: ${white};
  padding: ${spacing.large};
  ${boxShadow.large};
`

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 100%;
  margin-top: ${spacing.medium};
  margin-right: ${spacing.medium};
  background-color: ${rgba(white, 0.2)};
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color ${animationTime} ${animationCurve};
  outline: 0;
  ${square("40px")};

  &:hover {
    background-color: ${rgba(white, 0.25)};
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${borderColor};
  padding-bottom: ${spacing.scale(spacing.medium, 1.25)};
  margin-bottom: ${spacing.large};
`

const Title = styled(Heading)`
  line-height: ${lineHeights.base}; /* Make sure title is taller than buttons */
  margin-bottom: 0;
`

const Drawer = (props: DrawerProps): JSX.Element => {
  const { heading, headerActions, headingAddon, isOpen, onClose, children } = props
  const closeRef = useRef<HTMLButtonElement>(null)

  const handleClose = () => {
    onClose()
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog open={isOpen} onClose={handleClose} static initialFocus={closeRef}>
          {/* Backdrop */}
          <motion.div
            key="backdrop"
            variants={backdropVariants}
            initial="initial"
            animate="enter"
            exit="initial"
            transition={{ ease: animationCurve }}
          >
            <Backdrop />
          </motion.div>

          {/* Drawer */}
          <Outer>
            <motion.div
              key="drawer"
              variants={drawerVariants}
              initial="initial"
              animate="enter"
              exit="initial"
              transition={{ ease: animationCurve }}
              style={{ height: "100%", width: "100%" }}
            >
              <Inner>
                <Close onClick={onClose} ref={closeRef}>
                  <Icon icon="close" size="small" />
                </Close>
                <Panel>
                  <Header>
                    <Inline space="small">
                      <Title size={1} tag="h2">
                        {heading}
                      </Title>
                      {headingAddon && <span>{headingAddon}</span>}
                    </Inline>
                    {headerActions && <div>{headerActions}</div>}
                  </Header>
                  <div>{children}</div>
                </Panel>
              </Inner>
            </motion.div>
          </Outer>
        </Dialog>
      )}
    </AnimatePresence>
  )
}

export default Drawer
