import React, { ChangeEvent, useMemo } from "react"
import styled from "styled-components"
import moment from "moment"
import { range } from "lodash"

import { greyDark } from "../styles/colors"
import { fontWeights } from "../styles/typography"
import { greyLight, grey, white } from "../styles/colors"
import { square } from "../styles/helpers"
import { animationCurve, animationTime } from "../styles/variables"

import Dropdown, { DropdownOption } from "./Dropdown"
import Inline from "./Inline"
import Toggle from "./Toggle"
import { OpeningHour } from "../generated/graphql"

import { UseOpeningHours } from "../hooks/useOpeningHours"
import { OpeningHoursStateEntry } from "../hooks/useOpeningHours/types"
import Input from "./Input"
import Icon from "./Icon"

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Field = styled.div`
  width: 200px;
`

const Day = styled.div`
  font-weight: ${fontWeights.bold};
  color: ${greyDark};
  width: 180px;
`

const Remove = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${greyLight};
  border-radius: 50%;
  color: ${white};
  transition: background-color ${animationTime} ${animationCurve};
  ${square("20px")};

  &:hover {
    background-color: ${grey};
  }
`

const INTERVAL_MINUTES = 15
const TIME_FORMAT = "HH:mm:ss"
const TIME_FORMAT_DISPLAY = "HH:mm"

export type OpeningHourControlChangeFunc = (
  changed: Partial<OpeningHour> & { day_of_week: number }
) => void

export type OpeningHourProps = {
  openingHour: OpeningHoursStateEntry
  dispatch: UseOpeningHours["dispatch"]
}

const timeOptions = (
  min: string,
  max: string,
  interval_minutes: number
): DropdownOption<string>[] => {
  const startMoment = moment(min, TIME_FORMAT)
  const endMoment = moment(max, TIME_FORMAT)
  const total_minutes = moment.duration(endMoment.diff(startMoment)).asMinutes()
  const optCount = Math.floor(total_minutes / interval_minutes) + 1
  const options = range(0, optCount).map((optIndex) => {
    const time = moment(startMoment).add(optIndex * interval_minutes, "minutes")
    return {
      label: time.format(TIME_FORMAT_DISPLAY),
      value: time.format(TIME_FORMAT)
    }
  })

  return options
}

const OpeningHourControl = (props: OpeningHourProps): JSX.Element | null => {
  const { openingHour } = props
  const { _id, closed, day_of_week, start_time, duration_minutes } = openingHour

  const isException = day_of_week < 0

  const updateEntry = (data: Partial<OpeningHoursStateEntry>) => {
    const payload = { _id, data }
    props.dispatch({
      type: "update",
      payload
    })
  }

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const name = ev.target.value
    updateEntry({ name })
  }

  const handleDateChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const dateMoment = moment(ev.target.value)
    const day_of_month = dateMoment.date()
    const month = dateMoment.month() + 1
    const year = dateMoment.year()
    updateEntry({ day_of_month, month, year })
  }

  const handleToggleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const closed = !ev.target.checked
    updateEntry({ closed })
  }

  const handleStartTimeChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const newStartTime = ev.target.value
    const diff = moment(start_time, TIME_FORMAT).diff(moment(newStartTime, TIME_FORMAT), "minutes")
    const newDuration = duration_minutes + diff
    updateEntry({ start_time: newStartTime, duration_minutes: newDuration })
  }

  const handleEndTimeChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const startTime = moment(start_time, TIME_FORMAT)
    const endTime = moment(ev.target.value, TIME_FORMAT)
    const diff = moment.duration(endTime.diff(startTime)).asMinutes()
    updateEntry({ duration_minutes: diff })
  }

  const startOptions = useMemo(() => timeOptions("00:00", "23:59", INTERVAL_MINUTES), [])

  const minEnd = useMemo(() => {
    return moment(start_time, TIME_FORMAT).add(INTERVAL_MINUTES).format(TIME_FORMAT)
  }, [start_time])

  const endOptions = useMemo(() => {
    return timeOptions(minEnd, "23:59", INTERVAL_MINUTES)
  }, [minEnd])

  const endValue = useMemo(() => {
    return moment(start_time, TIME_FORMAT).add(duration_minutes, "minutes").format(TIME_FORMAT)
  }, [start_time, duration_minutes])

  const exceptionDateValue = useMemo(() => {
    const { day_of_month, month, year } = openingHour
    if ([day_of_month, month, year].some((val) => val < 0)) return ""
    return [
      year.toString(),
      month.toString().padStart(2, "0"),
      day_of_month.toString().padStart(2, "0")
    ].join("-")
  }, [openingHour.day_of_month, openingHour.month, openingHour.year])

  return (
    <Container>
      <Inline>
        {!isException && (
          <Day>
            {moment()
              .day(openingHour.day_of_week || 0)
              .format("dddd")}
          </Day>
        )}
        {isException && (
          <Inline>
            <Field>
              <Input
                id="name"
                type="text"
                name="name"
                placeholder="Name"
                value={openingHour.name || ""}
                onChange={handleNameChange}
              />
            </Field>
            <Field>
              <Input
                id="date"
                type="date"
                name="date"
                placeholder="Date"
                value={exceptionDateValue}
                onChange={handleDateChange}
              />
            </Field>
          </Inline>
        )}
        <Field>
          <Inline>
            <Toggle
              id={`opening-hours-toggle-${day_of_week}`}
              onChange={handleToggleChange}
              checked={!closed}
            />
            <span>{closed ? "Closed" : "Open"}</span>
          </Inline>
        </Field>
      </Inline>
      <Inline>
        <Dropdown
          id="startTime"
          options={startOptions}
          value={start_time}
          onChange={handleStartTimeChange}
          disabled={closed}
        />
        <span>to</span>
        <Dropdown
          id="endTime"
          options={endOptions}
          value={endValue}
          onChange={handleEndTimeChange}
          disabled={closed}
        />
        {isException && (
          <Remove
            onClick={() => props.dispatch({ type: "delete", payload: { _id: openingHour._id } })}
          >
            <Icon icon="close" size="tiny" />
          </Remove>
        )}
      </Inline>
    </Container>
  )
}

export default OpeningHourControl
