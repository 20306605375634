import React from "react"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import { mix } from "polished"

import { primaryColor, white, black, borderColor, greyDark, red } from "../styles/colors"
import * as spacing from "../styles/spacing"
import { borderRadius, inputHeights, animationTime, animationCurve } from "../styles/variables"
import { boxShadow } from "../styles/helpers"
import { fontSizes, fontWeights } from "../styles/typography"
import { rotate } from "../styles/animations"

import Icon, { IconType } from "../components/Icon"

export type ButtonIntent = "primary" | "secondary" | "negative"
export type ButtonSize = "standard" | "small" | "tiny"

export type ContainerProps = {
  intent: ButtonIntent
  size: ButtonSize
  full?: boolean
}

export type InnerProps = {
  hide?: boolean
}

export type ButtonProps = {
  children: React.ReactNode
  intent?: ButtonIntent
  size?: ButtonSize
  to?: string
  href?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  full?: boolean
  icon?: IconType
  isLoading?: boolean
  disabled?: boolean
}

type ButtonSizeValues = {
  [K in ButtonSize]: {
    height: string
    padding: string
  }
}

const buttonSizeValues: ButtonSizeValues = {
  standard: { height: inputHeights.standard, padding: spacing.medium }, // 40px
  small: { height: inputHeights.small, padding: spacing.scale(spacing.small, 1.5) }, // 32px
  tiny: { height: inputHeights.tiny, padding: spacing.small } // 24px
}

const Container = styled.button<ContainerProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: ${borderRadius};
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.semibold};
  transition: all ${animationTime} ${animationCurve};
  height: ${(props) => buttonSizeValues[props.size].height};
  padding: 0 ${(props) => buttonSizeValues[props.size].padding};
  outline: 0;
  ${boxShadow.small};
  white-space: nowrap;

  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `}

  &:hover,
  &:active {
    ${boxShadow.medium};
  }

  ${(props) => {
    switch (props.intent) {
      case "primary":
        return css`
          background-color: ${primaryColor};
          color: ${white};

          &:hover,
          &:active {
            background-color: ${mix(0.95, primaryColor, black)};
          }
        `
      case "secondary":
        return css`
          background-color: ${white};
          color: ${greyDark};
          border: 1px solid ${borderColor};

          &:hover,
          &:active {
            border-color: ${mix(0.95, borderColor, black)};
          }
        `

      case "negative":
        return css`
          background-color: ${red};
          color: ${white};

          &:hover,
          &:active {
            background-color: ${mix(0.95, red, black)};
          }
        `
    }
  }}

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
`

const Inner = styled.div<InnerProps>`
  display: inline-flex;
  align-items: center;
  transition: visibility ${animationTime} ${animationCurve},
    opacity ${animationTime} ${animationCurve};

  ${(props) =>
    props.hide &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`

const IconWrap = styled.div`
  display: flex;
  margin-right: ${spacing.small};
`

const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`

const Spinner = styled.div`
  display: flex;
  animation: ${rotate} 1s linear infinite;
`

const getElement = (props: ButtonProps): React.ElementType => {
  if (props.to) return Link
  if (props.href) return "a"
  return "button"
}

const Button = (props: ButtonProps): JSX.Element => {
  const {
    intent = "secondary",
    size = "standard",
    children,
    onClick,
    full,
    icon,
    isLoading,
    disabled
  } = props

  return (
    <Container
      {...props}
      intent={intent}
      size={size}
      as={getElement(props)}
      onClick={onClick}
      full={full}
      disabled={disabled || isLoading}
    >
      {isLoading && (
        <Loading>
          <Spinner>
            <Icon icon="loading" size="small" />
          </Spinner>
        </Loading>
      )}
      <Inner hide={isLoading}>
        {icon && (
          <IconWrap>
            <Icon icon={icon} size="small" />
          </IconWrap>
        )}
        {children}
      </Inner>
    </Container>
  )
}

export default Button
