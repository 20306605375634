import { useMemo } from "react"
import { Retailer } from "../generated/graphql"

export const useFormattedRetailerAddress = (retailer?: Retailer | null): string => {
  const formattedAddress = useMemo(() => {
    if (!retailer) return ""
    const { address_line_1, province, postal_code } = retailer
    return `${address_line_1}, ${province} ${postal_code}`
  }, [retailer])

  return formattedAddress
}
