import React, { forwardRef } from "react"
import styled from "styled-components"
import { stripUnit } from "polished"

import { borderColor, white, primaryColor } from "../styles/colors"
import { animationCurve, animationTime } from "../styles/variables"
import { boxShadow, cover } from "../styles/helpers"
import Label from "./Label"

export const toggleDimensions = {
  width: "40px",
  height: "24px",
  handleSize: "18px",
  padding: "3px"
}

const Container = styled.div`
  display: flex;
  position: relative;
  z-index: 0;
`

const Input = styled.input`
  ${cover("absolute")};
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`

const Indicator = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: ${toggleDimensions.width};
  height: ${toggleDimensions.height};
  background-color: ${borderColor};
  border-radius: ${`${(stripUnit(toggleDimensions.height) as number) / 2}px`};
  cursor: pointer;
  transition: background-color ${animationTime} ${animationCurve};

  ${Input}[disabled] + & {
    cursor: default;
    pointer-events: none;
    opacity: 0.35;
    user-select: none;
  }

  ${Input}:checked + & {
    background-color: ${primaryColor};
  }

  &:before {
    content: "";
    position: absolute;
    top: ${toggleDimensions.padding};
    left: ${toggleDimensions.padding};
    width: ${toggleDimensions.handleSize};
    height: ${toggleDimensions.handleSize};
    border-radius: 50%;
    background-color: ${white};
    transition: transform ${animationTime} ${animationCurve};
    ${boxShadow.small};

    ${Input}:checked + & {
      transform: translateX(16px);
    }
  }
`

type ToggleProps = {
  id: string
  checked?: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  label?: string
  disabled?: boolean
}

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>((props, forwardedRef) => {
  const { label, ...otherProps } = props
  return (
    <>
      {!!label && <Label htmlFor={otherProps.id}>{label}</Label>}
      <Container>
        <Input ref={forwardedRef} type="checkbox" {...otherProps} />
        <Indicator />
      </Container>
    </>
  )
})

Toggle.displayName = "Toggle"

export default Toggle
