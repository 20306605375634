import React, { useContext } from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import { ApolloContextProvider } from "../context/ApolloContext"
import { AppContextProvider } from "../context/AppContext"
import { AuthContext } from "../context/AuthContext"
import { useDashboardPaths } from "../hooks/useDashboardPaths"
import DashboardLayout from "../layouts/DashboardLayout"
import Help from "./Help"

import Home from "./Home"
import Inventory from "./Inventory"
import Orders from "./Orders"
import Settings from "./Settings"
import Shipments from "./Shipments"

const Dashboard = (): JSX.Element => {
  const match = useRouteMatch()
  const paths = useDashboardPaths(match.path, { withParams: true })

  const { isAuthenticated } = useContext(AuthContext)

  if (!isAuthenticated) {
    return <Redirect to="/login" />
  }

  return (
    <ApolloContextProvider>
      <AppContextProvider>
        <DashboardLayout>
          <Switch>
            <Route path={paths.orders} component={Orders} />
            <Route path={paths.shipments} component={Shipments} />
            <Route path={paths.inventory} component={Inventory} />
            <Route path={paths.settings} component={Settings} />
            <Route path={paths.help} exact component={Help} />
            <Route path={paths.home} exact component={Home} />
            <Route>
              <Redirect to={paths.home} />
            </Route>
          </Switch>
        </DashboardLayout>
      </AppContextProvider>
    </ApolloContextProvider>
  )
}

export default Dashboard
