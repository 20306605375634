import React, { SelectHTMLAttributes } from "react"
import styled, { css } from "styled-components"

import { white, grey, greyLight, primaryColor, offWhite } from "../styles/colors"
import { inputHeights, borderRadius, animationTime, animationCurve } from "../styles/variables"
import { fontSizes } from "../styles/typography"
import * as spacing from "../styles/spacing"

import Icon from "../components/Icon"
import Label from "../components/Label"

export type DropdownSize = "standard" | "small"

export type SelectProps = {
  customSize: DropdownSize
}

export type IconWrapProps = {
  customSize: DropdownSize
  disabled?: boolean
}

export type OptionValue = string | number

export type DropdownOption<T extends OptionValue> = {
  value: T
  label: string
}

export type DropdownProps<T extends OptionValue> = SelectHTMLAttributes<HTMLSelectElement> & {
  options: DropdownOption<T>[]
  id: string
  customSize?: DropdownSize
  label?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
}

type DropdownSizeValues = {
  [K in DropdownSize]: {
    height: string
    padding: string
    iconOffset: string
    fontSize: string
  }
}

const dropdownSizeValues: DropdownSizeValues = {
  standard: {
    height: inputHeights.standard,
    padding: spacing.medium,
    iconOffset: "40px",
    fontSize: fontSizes.standard
  },
  small: {
    height: inputHeights.small,
    padding: spacing.scale(spacing.small, 1.5),
    iconOffset: "36px",
    fontSize: fontSizes.small
  }
}

const Container = styled.div`
  display: block;
`

const Wrap = styled.div`
  position: relative;
`

const Select = styled.select<SelectProps>`
  height: ${(props) => dropdownSizeValues[props.customSize].height};
  padding: 0 ${(props) => dropdownSizeValues[props.customSize].padding};
  padding-right: ${(props) => dropdownSizeValues[props.customSize].iconOffset};
  background-color: ${offWhite};
  font-size: ${(props) => dropdownSizeValues[props.customSize].fontSize};
  border-radius: ${borderRadius};
  color: ${grey};
  width: 100%;
  border: 0;
  outline: 0;
  resize: none;
  box-shadow: none;
  transition: all ${animationTime} ${animationCurve};

  &::placeholder {
    color: ${greyLight};
  }

  &:focus {
    background-color: ${white};
    box-shadow: 0 0 0 1px ${primaryColor}, inset 0 0 0 1px ${primaryColor};
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
`

const IconWrap = styled.div<IconWrapProps>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${(props) => dropdownSizeValues[props.customSize].padding};
  pointer-events: none;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}
`

const Dropdown = (props: DropdownProps<OptionValue>): JSX.Element => {
  const {
    label,
    placeholder,
    disabled,
    options,
    id,
    required,
    customSize = "standard",
    ...rest
  } = props

  return (
    <Container>
      {label && (
        <Label htmlFor={id} muted={disabled}>
          {label}
        </Label>
      )}
      <Wrap>
        <Select
          {...rest}
          disabled={disabled}
          required={required}
          customSize={customSize}
          defaultValue={placeholder}
          id={id}
        >
          {placeholder && (
            <option value={placeholder} hidden disabled>
              {placeholder}
            </option>
          )}
          {options.map((opt, i) => {
            return (
              <option key={i} value={opt.value}>
                {opt.label}
              </option>
            )
          })}
        </Select>

        <IconWrap customSize={customSize} disabled={disabled}>
          <Icon icon="caret-down" size="small" />
        </IconWrap>
      </Wrap>
    </Container>
  )
}

export default Dropdown
