import React from "react"
import styled from "styled-components"
import SVG from "react-inlinesvg"
import { stripUnit } from "polished"

import * as Spacing from "../styles/spacing"

export type IconSize = "tiny" | "small" | "standard"
export type IconType =
  | "caret-down"
  | "caret-up"
  | "cart"
  | "checkmark"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "close"
  | "house"
  | "inventory"
  | "loading"
  | "qr"
  | "search"
  | "settings"
  | "shipment"
  | "trash"
  | "question"

const getSizeValue = (sizeClass?: IconSize) => {
  switch (sizeClass) {
    case "tiny":
      return Spacing.scale(Spacing.small, 1.5)
    case "small":
      return Spacing.medium
    default:
      return Spacing.large
  }
}

export type IconProps = {
  icon: IconType
  size?: IconSize
}

const Container = styled(SVG)`
  display: inline-block;
  vertical-align: top;
  fill: currentColor;
`

const Icon = (props: IconProps): JSX.Element => (
  <Container
    width={stripUnit(getSizeValue(props.size))}
    height={stripUnit(getSizeValue(props.size))}
    src={process.env.PUBLIC_URL + `/icons/${props.icon}.svg`}
  />
)

export default Icon
