import { pick } from "lodash"
import React, { useCallback, useMemo, useReducer } from "react"
import {
  OpeningHour,
  useGetOpeningHoursQuery,
  useUpdateOpeningHoursMutation
} from "../../generated/graphql"
import { OPENING_HOURS_REDUCER, ReducerHandler, ReducerAction } from "./reducer"
import { OpeningHoursStateEntry } from "./types"
import { WEEK_SEQUENCE } from "./utils"

const UPDATE_FIELDS = [
  "name",
  "day_of_week",
  "day_of_month",
  "month",
  "year",
  "start_time",
  "duration_minutes",
  "closed"
] as const

export interface UseOpeningHours {
  allEntries: OpeningHoursStateEntry[]
  weeklyEntries: OpeningHoursStateEntry[]
  exceptionEntries: OpeningHoursStateEntry[]
  dispatch: React.Dispatch<ReducerAction>
  saveWeeklyHours: () => Promise<void>
  saveExceptions: () => Promise<void>
  isLoading: boolean
  openingHoursCompleted: boolean
}

export interface IOpeningHourEntry {
  name?: string | null
  day_of_week: number
  day_of_month: number
  month: number
  year: number
  start_time: string
  duration_minutes: number
  closed: boolean
}

const filterEntries = <T extends IOpeningHourEntry>(
  state: T[],
  type: "weekly" | "exceptions"
): T[] => {
  switch (type) {
    case "weekly":
      return state.filter((e) => e.day_of_week >= 0)
    case "exceptions":
      return state.filter((e) => e.day_of_week < 0)
  }
}

export const useOpeningHours = (): UseOpeningHours => {
  const reducer = useReducer<ReducerHandler<ReducerAction>>(OPENING_HOURS_REDUCER, [])
  const [state, dispatch] = reducer

  const queryResult = useGetOpeningHoursQuery({
    onCompleted: (data) => onFetch(data.opening_hours)
  })

  const [execute, mutationResult] = useUpdateOpeningHoursMutation({
    onCompleted: (data) => onFetch(data.updateOpeningHours)
  })

  const onFetch = useCallback(
    (entries: OpeningHour[]) => {
      dispatch({ type: "init", payload: { entries } })
    },
    [dispatch]
  )

  const weeklyEntries = useMemo(() => {
    return WEEK_SEQUENCE.reduce<OpeningHoursStateEntry[]>((acc, d) => {
      const entry = state.find((e) => e.day_of_week === d)
      if (entry) acc.push(entry)
      return acc
    }, [])
  }, [state])

  const exceptionEntries = useMemo(() => {
    return filterEntries(state, "exceptions")
  }, [state])

  const updateHours = async (hours: IOpeningHourEntry[]): Promise<void> => {
    await execute({
      variables: {
        input: {
          opening_hours: hours.map((h) => pick(h, UPDATE_FIELDS))
        }
      }
    })
  }

  const saveWeeklyHours = useCallback(async () => {
    const updatedWeeklyHours = filterEntries(state, "weekly")
    const exceptions = filterEntries(queryResult.data?.opening_hours || [], "exceptions")
    await updateHours([...updatedWeeklyHours, ...exceptions])
  }, [state, queryResult.data, execute])

  const saveExceptions = useCallback(async () => {
    const weeklyHours = filterEntries(queryResult.data?.opening_hours || [], "weekly")
    const updatedExceptions = filterEntries(state, "exceptions").filter((e) => {
      const { day_of_month, month, year } = e
      return [day_of_month, month, year].every((val) => val >= 0)
    })
    await updateHours([...weeklyHours, ...updatedExceptions])
  }, [state, queryResult.data, execute])

  const isLoading = useMemo(() => {
    return queryResult.loading || mutationResult.loading
  }, [queryResult.loading, mutationResult.loading])

  const openingHoursCompleted = useMemo(() => {
    const saved = queryResult.data?.opening_hours || mutationResult.data?.updateOpeningHours || []
    return filterEntries(saved, "weekly").length === WEEK_SEQUENCE.length
  }, [queryResult.data, mutationResult.data])

  return {
    allEntries: state,
    weeklyEntries,
    exceptionEntries,
    dispatch,
    saveWeeklyHours,
    saveExceptions,
    isLoading,
    openingHoursCompleted
  }
}
