/* eslint-disable no-console */
import { useEffect, useRef, useState } from "react"
import { config } from "../config"

export type UseWebPush = {
  getPermissionStatus: () => NotificationPermission
  requestPermission: () => Promise<NotificationPermission>
  createSubscription: () => Promise<void>
  currentSubscription: PushSubscription | null
}

export const useWebPush = (): UseWebPush => {
  const registrationRef = useRef<ServiceWorkerRegistration | null>(null)
  const [currentSubscription, setCurrentSubscription] = useState<PushSubscription | null>(null)

  const initRegistration = async () => {
    if ("serviceWorker" in navigator) {
      try {
        const reg = await navigator.serviceWorker.ready
        registrationRef.current = reg
        loadCurrentSubscription()
      } catch (e) {
        console.log("sw error", e)
      }
    } else {
      console.log("service worker not in navigator")
    }
  }

  const getPermissionStatus = (): NotificationPermission => {
    if (!window.Notification.permission) return "denied"
    return window.Notification.permission
  }

  const requestPermission = async (): Promise<NotificationPermission> => {
    return window.Notification.requestPermission()
  }

  const getCurrentSubscription = async (): Promise<PushSubscription | null> => {
    const registration = registrationRef.current
    if (!registration || !registration.pushManager) return null
    return registration.pushManager?.getSubscription()
  }

  const loadCurrentSubscription = async () => {
    const sub = await getCurrentSubscription()
    setCurrentSubscription(sub)
  }

  const createSubscription = async (): Promise<void> => {
    const registration = registrationRef.current
    if (!registration || !registration.pushManager)
      throw new Error("no service worker registration")
    const subscription = await registration.pushManager?.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(config.VAPID_PUBLIC_KEY)
    })

    setCurrentSubscription(subscription)
  }

  useEffect(() => {
    initRegistration()
  }, [])

  return {
    getPermissionStatus,
    createSubscription,
    requestPermission,
    currentSubscription
  }
}

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
