import React, { useContext, useMemo, useState } from "react"
import ReactTooltip from "react-tooltip"
import styled from "styled-components"

import { InventoryContext } from "../context/InventoryContext"
import { InventoryLine } from "../generated/graphql"

import * as spacing from "../styles/spacing"
import { greyLight } from "../styles/colors"

import PageHeader from "../components/PageHeader"
import Stack from "../components/Stack"
import DataTable, { DataTableColumnConfig } from "../components/DataTable"
import Input from "../components/Input"
import { Column, Columns } from "../components/Column"
import Text from "../components/Text"

const Help = styled.span`
  cursor: help;
  border-bottom: 1px dashed ${greyLight};
  padding-bottom: ${spacing.tiny};
`

const QuantityColumn = (line: InventoryLine): JSX.Element => {
  const tooltip_id = `tooltip_quantity_detail_${line.product.sku}`
  const { reserved, unrestricted, blocked } = line
  const total = reserved + unrestricted + blocked

  if (reserved > 0 || blocked > 0) {
    return (
      <>
        <Help data-tip data-for={tooltip_id}>
          {total}&nbsp;(?)
        </Help>
        <ReactTooltip id={tooltip_id}>
          <Columns>
            <Column width="content" space="none">
              <Stack space="none">
                <Text size="small" alignment="right">
                  Available:&nbsp;
                </Text>
                <Text size="small" alignment="right">
                  Reserved:&nbsp;
                </Text>
                <Text size="small" alignment="right">
                  Blocked:&nbsp;
                </Text>
                <Text size="small" alignment="right">
                  Total:&nbsp;
                </Text>
              </Stack>
            </Column>
            <Column width="content" space="none">
              <Stack space="none">
                <Text size="small" alignment="right">
                  {unrestricted}
                </Text>
                <Text size="small" alignment="right">
                  {reserved}
                </Text>
                <Text size="small" alignment="right">
                  {blocked}
                </Text>
                <Text size="small" alignment="right">
                  {total}
                </Text>
              </Stack>
            </Column>
          </Columns>
        </ReactTooltip>
      </>
    )
  } else {
    return <span>{total}</span>
  }
}

// const DATE_FORMAT = "MM/DD/YYYY [at] hh:mm"

const COLUMN_CONFIG: DataTableColumnConfig<InventoryLine>[] = [
  {
    title: "UPC",
    render: (item) => item.pack_upc
  },
  {
    title: "Product",
    render: (item) => item.product.name
  },
  {
    title: "Quantity",
    render: QuantityColumn
  }
]

const Inventory = (): JSX.Element => {
  const { lines } = useContext(InventoryContext)
  const [search, setSearch] = useState("")

  const filteredLines = useMemo<InventoryLine[]>(() => {
    if (!search.length) return lines
    const searchLower = search.toLowerCase()

    const filtered = lines.filter((line) => {
      const sku = line.product.sku.toLowerCase()
      const name = line.product.name.toLowerCase()
      const upc = line.pack_upc.toLowerCase()
      const includes =
        sku.includes(searchLower) || name.includes(searchLower) || upc.includes(searchLower)
      return includes
    })

    return filtered
  }, [lines, search])

  return (
    <Stack>
      <PageHeader heading="Inventory" />
      <DataTable
        onRowSelected={() => undefined}
        items={filteredLines}
        isHoverable={false}
        columns={COLUMN_CONFIG}
        header={
          <Input
            id="search"
            name="search"
            type="search"
            placeholder="Type to search inventory..."
            customSize="small"
            icon="search"
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
          />
        }
      />
    </Stack>
  )
}

export default Inventory
