import React, { useContext, useMemo } from "react"
import { ZodIssue } from "zod"

import { ShipmentDetailsContext } from "../../context/ShipmentDetailsContext"

import Alert from "../Alert"
import Stack from "../Stack"
import AnomalyFieldArray from "./AnomalyFieldArray"

export type AnomalyFormProps = {
  anomaly_id: string
  formIssue?: ZodIssue
}

export const AnomalyForm = (props: AnomalyFormProps): JSX.Element | null => {
  const { shipment, anomalies } = useContext(ShipmentDetailsContext)

  const anomaly = useMemo(() => {
    return anomalies.find((a) => a.id === props.anomaly_id)
  }, [anomalies, props.anomaly_id])

  const selectedLine = useMemo(() => {
    return shipment?.line_items.find((l) => l.product.sku === anomaly?.sku)
  }, [shipment?.line_items, anomaly])

  const alreadyAffected = useMemo(() => {
    return anomalies
      .filter((a) => a.sku === anomaly?.sku && a.id !== anomaly?.id)
      .reduce((acc, val) => (acc += val.affected_quantity_packs), 0)
  }, [anomalies, anomaly])

  const remainingShipmentLines = useMemo(() => {
    return (
      shipment?.line_items.filter((line) => {
        const otherAnomalies = anomalies.filter(
          (a) => a.sku === line.product.sku && a.id !== anomaly?.id
        )
        const combinedOtherQuantity = otherAnomalies.reduce((acc, val) => {
          acc += val.affected_quantity_packs
          return acc
        }, 0)

        return combinedOtherQuantity < line.quantity_packs
      }) || []
    )
  }, [shipment?.line_items, anomalies, anomaly])

  if (!anomaly) {
    return null
  }

  return (
    <>
      <Stack>
        <AnomalyFieldArray
          anomaly={anomaly}
          selectableShipmentLines={remainingShipmentLines}
          maxQuantity={(selectedLine?.quantity_packs || 0) - alreadyAffected}
        />
        {props.formIssue && <Alert intent="negative">{props.formIssue?.message}</Alert>}
      </Stack>
    </>
  )
}
