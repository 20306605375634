import React, { useEffect, useRef, useState } from "react"
import Axios, { AxiosInstance, AxiosError } from "axios"
import { BaseProviderProps } from "./types"
import { config } from "../config"

const BASE_URL = `${config.API_URL}/api`

const LOCAL_STORAGE_SESSION_KEY = "session"

export type LoginInput = {
  email: string
  password: string
}

export type LogoutOpts = {
  userInitiated: boolean
}

export type Session = {
  user_id: string
  session_token: string
  retailer_id: string
}

export interface IAuthContext {
  isAuthenticated: boolean
  session?: Session | null
  login: (params: LoginInput) => Promise<void>
  logout: (opts?: LogoutOpts) => Promise<void>
  forgotPassword: (email: string) => Promise<void>
  resetPassword: (params: { password: string; token: string }) => Promise<void>
}

const DEFAULT_VALUE: IAuthContext = {
  isAuthenticated: false,
  session: undefined,
  login: () => Promise.reject(),
  logout: () => Promise.reject(),
  forgotPassword: () => Promise.reject(),
  resetPassword: () => Promise.reject()
}

export const AuthContext = React.createContext<IAuthContext>(DEFAULT_VALUE)

export const AuthContextProvider = (props: BaseProviderProps): JSX.Element => {
  const axios = useRef<AxiosInstance>(
    Axios.create({
      baseURL: `${BASE_URL}/auth`
    })
  )

  const loadFromLocalStorage = (): Session | null => {
    const jsonString = localStorage.getItem(LOCAL_STORAGE_SESSION_KEY)
    if (!jsonString) return null
    try {
      const session = JSON.parse(jsonString)
      return session
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Failed to parse session from local storage", e)
      return null
    }
  }

  const [session, setSession] = useState<Session | undefined | null>(loadFromLocalStorage())

  const login = async (params: LoginInput) => {
    try {
      const response = await axios.current.post("/login", params)
      const { session } = response.data
      setSession(session)
      saveToLocalStorage(session)
    } catch (e) {
      const message = (e as AxiosError).response?.data?.error?.message
      throw new Error(message)
    }
  }

  const logout = async (opts: LogoutOpts = { userInitiated: true }) => {
    if (opts.userInitiated) {
      axios.current.post("/logout")
    }
    setSession(null)
    clearLocalStorage()
  }

  const forgotPassword = async (email: string) => {
    try {
      await axios.current.post("/forgot", { email })
    } catch (e) {
      const message = (e as AxiosError).response?.data?.error?.message
      throw new Error(message)
    }
  }

  const resetPassword = async (params: { password: string; token: string }) => {
    try {
      await axios.current.post("/reset", params)
    } catch (e) {
      const message = (e as AxiosError).response?.data?.error?.message
      throw new Error(message)
    }
  }

  const saveToLocalStorage = (session: Session) => {
    const jsonString = JSON.stringify(session)
    localStorage.setItem(LOCAL_STORAGE_SESSION_KEY, jsonString)
  }

  const clearLocalStorage = () => {
    localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY)
  }

  useEffect(() => {
    if (session === undefined) {
      loadFromLocalStorage()
    } else if (session) {
      axios.current.defaults.headers["authorization"] = `Bearer ${session.session_token}`
    }
  }, [session])

  const value: IAuthContext = {
    isAuthenticated: !!session,
    session,
    login,
    logout,
    forgotPassword,
    resetPassword
  }

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
}
