import React, { Dispatch, useContext, useMemo, useState } from "react"
import { BaseProviderProps } from "./types"
import {
  AcceptShippingRequestMutationHookResult,
  MarkShippingRequestCollectedMutationHookResult,
  MarkShippingRequestReadyMutationHookResult,
  Order,
  OrdersQueryBucket,
  PageInfo,
  RejectShippingRequestMutationHookResult,
  UpdateOrderStatusMutationHookResult,
  useAcceptShippingRequestMutation,
  useGetOrdersQuery,
  useMarkShippingRequestCollectedMutation,
  useMarkShippingRequestReadyMutation,
  useRejectShippingRequestMutation,
  useUpdateOrderStatusMutation
} from "../generated/graphql"
import { AppContext } from "./AppContext"

export interface OrdersContext {
  orders: Order[]
  isLoading: boolean
  refetch: () => void
  pageInfo: PageInfo | null
  setCursor: Dispatch<React.SetStateAction<string | null>>
  setBucket: Dispatch<React.SetStateAction<OrdersQueryBucket>>
  search: string | null
  setSearch: Dispatch<React.SetStateAction<string | null>>
  updateOrderStatusMutation: UpdateOrderStatusMutationHookResult
  acceptShippingMutation: AcceptShippingRequestMutationHookResult
  markShippingReadyMutation: MarkShippingRequestReadyMutationHookResult
  markShippingCollectedMutation: MarkShippingRequestCollectedMutationHookResult
  rejectShippingRequestMutation: RejectShippingRequestMutationHookResult
}

const DEFAULT_VALUE: OrdersContext = {
  orders: [],
  isLoading: true,
  refetch: () => null,
  setCursor: () => null,
  setBucket: () => null,
  setSearch: () => null,
  search: null,
  pageInfo: null,
  updateOrderStatusMutation: {} as OrdersContext["updateOrderStatusMutation"],
  acceptShippingMutation: {} as OrdersContext["acceptShippingMutation"],
  markShippingReadyMutation: {} as OrdersContext["markShippingReadyMutation"],
  markShippingCollectedMutation: {} as OrdersContext["markShippingCollectedMutation"],
  rejectShippingRequestMutation: {} as OrdersContext["rejectShippingRequestMutation"]
}

const DAY_IN_MS = 24 * 60 * 60 * 1000

export const OrdersContext = React.createContext<OrdersContext>(DEFAULT_VALUE)

export const OrdersContextProvider = (props: BaseProviderProps): JSX.Element => {
  const { selectedPeriodDays } = useContext(AppContext)

  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null)
  const [cursor, setCursor] = useState<string | null>(null)
  // const [statuses, setStatuses] = useState<OrderStatusType[] | null>(null)
  const [bucket, setBucket] = useState<OrdersQueryBucket>(OrdersQueryBucket.Pending)
  const [search, setSearch] = useState<string | null>("")
  // const [orders, setOrders] = useState<Order[]>([])

  const pollInterval = useMemo(() => {
    if (bucket === OrdersQueryBucket.Pending) return 60000
    return 120000
  }, [bucket])

  const since = useMemo(() => {
    return new Date(Date.now() - selectedPeriodDays * DAY_IN_MS)
  }, [selectedPeriodDays])

  const { data, refetch, loading } = useGetOrdersQuery({
    variables: {
      input: {
        since,
        bucket,
        cursor,
        search
      }
    },
    pollInterval: pollInterval,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      setPageInfo(data.orders.page_info || null)
    }
    // skip: statuses === null
  })

  // useEffect(() => {
  //   if (!data?.orders.orders.length && loading) return
  //   setOrders(data?.orders.orders || [])
  // }, [data, loading])

  const updateOrderStatusMutation = useUpdateOrderStatusMutation({})
  const acceptShippingMutation = useAcceptShippingRequestMutation({})
  const markShippingReadyMutation = useMarkShippingRequestReadyMutation({})
  const markShippingCollectedMutation = useMarkShippingRequestCollectedMutation({})
  const rejectShippingRequestMutation = useRejectShippingRequestMutation()

  const value = {
    orders: data?.orders.orders || [],
    isLoading: loading,
    refetch,
    pageInfo,
    setCursor,
    setBucket,
    setSearch,
    search,
    updateOrderStatusMutation,
    acceptShippingMutation,
    markShippingReadyMutation,
    markShippingCollectedMutation,
    rejectShippingRequestMutation
  }

  return <OrdersContext.Provider value={value}>{props.children}</OrdersContext.Provider>
}
