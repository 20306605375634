import React from "react"
import { Order, OrderStatusType } from "../../generated/graphql"
import { useLastStatus } from "../../hooks/useLastStatus"
import Badge, { BadgeIntent } from "../Badge"

const ORDER_STATUS_TITLES: {
  [Status in OrderStatusType]: string
} = {
  Reserved: "Unconfirmed",
  Confirmed: "Pending",
  Cancelled: "Cancelled",
  Accepted: "Accepted",
  Collected: "Collected",
  Picked: "Ready for Pickup",
  Exception: "Rejected",
  NotAvailable: "Not Available"
}

const ORDER_STATUS_INTENTS: {
  [Status in OrderStatusType]: BadgeIntent
} = {
  Reserved: "neutral",
  Confirmed: "warning",
  Cancelled: "neutral",
  Accepted: "positive",
  Collected: "positive",
  Picked: "neutral",
  Exception: "negative",
  NotAvailable: "warning"
}

export const OrderStatusBadge = (order: Order): JSX.Element => {
  const status = useLastStatus({
    statuses: order.statuses,
    defaultType: OrderStatusType.NotAvailable,
    overrides: {
      Cancelled: OrderStatusType.Exception
    }
  })

  const title = ORDER_STATUS_TITLES[status.type]
  const intent = ORDER_STATUS_INTENTS[status.type]
  return <Badge intent={intent}>{title}</Badge>
}
