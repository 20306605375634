import React from "react"
import styled from "styled-components"
import { rgba } from "polished"

import { white, green, red } from "../../styles/colors"
import * as spacing from "../../styles/spacing"
import { fontSizes, fontWeights } from "../../styles/typography"

import Inline from "../../components/Inline"
import Icon from "../../components/Icon"
import Heading from "../../components/Heading"

export type StatTone = "positive" | "negative"
export type StatDirection = "up" | "down"

export type CompareProps = {
  tone: StatTone
}

const Container = styled.div`
  padding: ${spacing.medium};
`

const Label = styled.span`
  display: block;
  font-size: ${fontSizes.small};
  color: ${rgba(white, 0.75)};
`

const NumberValue = styled(Heading)`
  color: ${white};
`

const Compare = styled.div<CompareProps>`
  font-size: ${fontSizes.tiny};
  font-weight: ${fontWeights.medium};
  color: ${(props) => (props.tone === "positive" ? green : red)};
`

export type StatProps = {
  label: string
  value: string
  direction: StatDirection
  change: string
  inverted?: boolean
}

const Stat = (props: StatProps): JSX.Element => {
  const { label, value, direction, change, inverted = false } = props

  const tone =
    direction === "up" ? (inverted ? "negative" : "positive") : inverted ? "positive" : "negative"

  return (
    <Container>
      <Label>{label}</Label>
      <Inline space="small">
        <NumberValue size={4}>{value}</NumberValue>
        <Compare tone={tone}>
          <Inline space="tiny">
            <Icon icon={`caret-${direction}`} size="small" />
            <span>{change}</span>
          </Inline>
        </Compare>
      </Inline>
    </Container>
  )
}

export default Stat
