import React, { useContext, useState } from "react"

import PageHeader from "../components/PageHeader"
import ShipmentDetails from "../components/Drawer/ShipmentDetails"
import Stack from "../components/Stack"
import Input from "../components/Input"
import DataTable, { DataTableColumnConfig } from "../components/DataTable"
import { ShipmentsContext } from "../context/ShipmentsContext"
import moment from "moment"
import ShipmentStatusBadge from "../components/Shipments/ShipmentStatusBadge"
import { Shipment } from "../generated/graphql"
import { ShipmentDetailsContextProvider } from "../context/ShipmentDetailsContext"
import Pagination from "../components/Pagination"

const COLUMNS_CONFIG: DataTableColumnConfig<Shipment>[] = [
  {
    title: "Shipment Number",
    render: (item) => item.delivery_no
  },
  {
    title: "Delivery Time",
    render: (item) =>
      item.delivery_date ? moment(item.delivery_date).format("MMM DD YYYY - hh:mm A") : null
  },
  {
    title: "Product Quantity",
    render: (item) => {
      const qty = item.line_items.reduce((acc, val) => acc + val.quantity_packs, 0)
      const anomalyQty = item.anomalies.reduce((acc, val) => acc + val.affected_quantity_packs, 0)

      if (anomalyQty) {
        const actualQty = qty - anomalyQty
        return `${actualQty} confirmed packs (${qty} total)`
      }

      return `${qty} total packs`
    }
  },
  {
    title: "Status",
    render: ShipmentStatusBadge
  }
]

const Shipments = (): JSX.Element => {
  const { shipments, pageInfo, setCursor, isLoading, search, setSearch } =
    useContext(ShipmentsContext)
  const [selectedShipmentNumber, setSelectedShipmentNumber] = useState<string | null>(null)

  return (
    <>
      <Stack>
        <PageHeader heading="Shipments" />
        <DataTable
          isLoading={!shipments.length && isLoading}
          items={shipments}
          isHoverable={true}
          onRowSelected={(item) => setSelectedShipmentNumber(item.delivery_no)}
          columns={COLUMNS_CONFIG}
          header={
            <Input
              id="search"
              name="search"
              type="search"
              placeholder="Type to search shipments..."
              customSize="small"
              icon="search"
              value={search || ""}
              onChange={(ev) => setSearch(ev.target.value)}
            />
          }
        />
        <Pagination
          pageInfo={pageInfo}
          onPrev={() => pageInfo?.prev_page && setCursor(pageInfo.prev_page)}
          onNext={() => pageInfo?.next_page && setCursor(pageInfo.next_page)}
        />
      </Stack>
      <ShipmentDetailsContextProvider delivery_no={selectedShipmentNumber}>
        <ShipmentDetails onClose={() => setSelectedShipmentNumber(null)} />
      </ShipmentDetailsContextProvider>
    </>
  )
}

export default Shipments
