export interface Config {
  API_URL: string
  VAPID_PUBLIC_KEY: string
}

const local: Config = {
  API_URL: "http://localhost:5000",
  VAPID_PUBLIC_KEY:
    "BMMci00eb_WaLJ94A18CBpUrtDCBUZV6CsRCOFX2P5BYDw_KFIvpAxty38-B6TU4S8Br3Pqw-Xl1FXiPpEInyrA"
}

const staging: Config = {
  API_URL: "https://bat-next-staging.v7apps.com",
  VAPID_PUBLIC_KEY:
    "BAw6E0d5ke3Nr7s4GzqeavZtIHhFy4EyUDJueybGlhFGIosRWJWnVQ8ViAPgp_XZ1EAMFh9rBxeahAcBTCv1uI0"
}

const prod: Config = {
  API_URL: "https://bat-next.wearefuturemaker.ca",
  VAPID_PUBLIC_KEY:
    "BNpMSKvcbifp1Pwea09kcP3FmMMpMqPs9g6O91-3S-BfOZJ8kaxTjRO1fIDW4DspfHYSCcx9FLV5WS9TSxLcl_Y"
}

export const config: Config =
  process.env.REACT_APP_STAGE === "local"
    ? local
    : process.env.REACT_APP_STAGE === "staging"
    ? staging
    : prod
