import { useMemo } from "react"

export type DashboardPath = "home" | "orders" | "shipments" | "inventory" | "settings" | "help"

export const useDashboardPaths = (
  root: string,
  opts?: { withParams?: boolean }
): Record<DashboardPath, string> => {
  const paths = useMemo(() => {
    return {
      home: `${root}/`,
      orders: opts?.withParams ? `${root}/orders/:order_id?` : `${root}/orders`,
      shipments: `${root}/shipments`,
      inventory: `${root}/inventory`,
      settings: `${root}/settings`,
      help: `${root}/help`
    }
  }, [root])

  return paths
}
