import React, { useMemo } from "react"
import styled from "styled-components"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts"

import { greyLight, greyDark, borderColor } from "../../styles/colors"
import { OrdersChartDataPoint } from "../../generated/graphql"

const Container = styled.div`
  height: 240px;
`

export type OrdersChartProps = {
  chartData: OrdersChartDataPoint[]
}

const OrderChart = (props: OrdersChartProps): JSX.Element => {
  const data = useMemo(() => {
    return props.chartData.map((d) => {
      return {
        name: d.date,
        orders: d.count
      }
    })
  }, [props.chartData])

  return (
    <Container>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={500} height={300} data={data}>
          <CartesianGrid stroke={borderColor} vertical={false} />
          <XAxis stroke={greyLight} dataKey="name" />
          <YAxis stroke={greyLight} />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="orders"
            stroke={greyDark}
            strokeWidth={3}
          />
          <Line
            type="monotone"
            dataKey="uv"
            stroke={greyLight}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default OrderChart
