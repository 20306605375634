import React, { Provider, useContext, useMemo } from "react"
import {
  Order,
  RejectOrderMutationHookResult,
  RejectShippingRequestMutationHookResult,
  useGetOrderByIdQuery,
  useRejectOrderMutation,
  useRejectShippingRequestMutation
} from "../generated/graphql"
import { OrdersContext } from "./OrdersContext"
import { BaseProviderProps } from "./types"

export interface OrderDetailsContext {
  order: Order | null
  rejectOrderMutation: RejectOrderMutationHookResult
  rejectShippingRequestMutation: RejectShippingRequestMutationHookResult
}

const DEFAULT_VALUE: OrderDetailsContext = {
  order: null,
  rejectOrderMutation: {} as RejectOrderMutationHookResult,
  rejectShippingRequestMutation: {} as RejectShippingRequestMutationHookResult
}

export const OrderDetailsContext = React.createContext(DEFAULT_VALUE)

export type OrderDetailsContextProviderProps = BaseProviderProps & {
  order_no_base64?: string | null
}

type ProviderType = React.ReactElement<
  OrderDetailsContextProviderProps,
  Provider<OrderDetailsContext>
>

export const OrderDetailsContextProvider = (
  props: OrderDetailsContextProviderProps
): ProviderType => {
  const { order_no_base64 } = props
  const { orders } = useContext(OrdersContext)

  const order_no = useMemo(() => {
    if (!order_no_base64) return null
    return Buffer.from(order_no_base64, "base64").toString("utf8")
  }, [order_no_base64])

  const cachedOrder = useMemo(() => {
    if (!order_no) return null
    return orders.find((o) => o.order_number === order_no) || null
  }, [order_no, orders])

  const { data } = useGetOrderByIdQuery({
    variables: { order_no: order_no || "" },
    skip: !order_no || !!cachedOrder
  })

  const order = useMemo(() => {
    return cachedOrder || data?.order || null
  }, [cachedOrder, data])

  const rejectOrderMutation = useRejectOrderMutation({})
  const rejectShippingRequestMutation = useRejectShippingRequestMutation({})

  const value = {
    order,
    rejectOrderMutation,
    rejectShippingRequestMutation
  }

  return <OrderDetailsContext.Provider value={value}>{props.children}</OrderDetailsContext.Provider>
}
