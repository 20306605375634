import { css } from "styled-components"

import { grey } from "./colors"

// Font family
export const fontFamily =
  "SF Pro Display, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"

// Font weights
export const fontWeights = {
  book: 400,
  medium: 500,
  semibold: 600,
  bold: 700
}

export const lineHeights = {
  base: 1.5,
  heading: 1.25,
  tight: 1.05
}

// Font sizes
export const fontSizes = {
  large: "18px",
  standard: "16px",
  small: "14px",
  tiny: "12px"
}

export default css`
  body {
    font-family: ${fontFamily};
    font-weight: ${fontWeights.book};
    line-height: ${lineHeights.base};
    font-size: ${fontSizes.standard};
    color: ${grey};
  }

  small {
    font-size: ${fontSizes.small};
  }

  strong {
    font-weight: ${fontWeights.bold};
  }

  p {
    margin-bottom: 1.25em;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      border-bottom: 1px solid;
    }
  }
`
