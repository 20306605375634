import React from "react"
import styled from "styled-components"

import { LayoutProps } from "../types/layout"

import { navWidth } from "../styles/variables"
import * as spacing from "../styles/spacing"

import Nav from "../components/Nav"
import { OrdersContextProvider } from "../context/OrdersContext"
import { InventoryContextProvider } from "../context/InventoryContext"
import { ShipmentsContextProvider } from "../context/ShipmentsContext"

const Main = styled.main`
  padding-left: ${navWidth};
`

const Inner = styled.div`
  padding: ${spacing.large} ${spacing.xxLarge};
  margin: 0 auto;
  max-width: 1200px;
`

const DashboardLayout = (props: LayoutProps): JSX.Element => (
  <InventoryContextProvider>
    <ShipmentsContextProvider>
      <OrdersContextProvider>
        <Nav />
        <Main>
          <Inner>{props.children}</Inner>
        </Main>
      </OrdersContextProvider>
    </ShipmentsContextProvider>
  </InventoryContextProvider>
)

export default DashboardLayout
