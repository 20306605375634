import React, { InputHTMLAttributes } from "react"
import styled, { css } from "styled-components"

import {
  white,
  grey,
  greyLight,
  primaryColor,
  offWhite
} from "../styles/colors"
import {
  inputHeights,
  borderRadius,
  animationTime,
  animationCurve
} from "../styles/variables"
import { fontSizes } from "../styles/typography"
import * as spacing from "../styles/spacing"

import Icon, { IconType } from "../components/Icon"
import Label from "../components/Label"
import Text from "../components/Text"

export type InputSize = "standard" | "small"
export type InputType =
  | "number"
  | "text"
  | "search"
  | "tel"
  | "url"
  | "email"
  | "password"
  | "date"

export type FieldProps = {
  hasPadding: boolean
  customSize: InputSize
  hasError?: boolean
}

export type IconWrapProps = {
  customSize: InputSize
}

export type InputProps = {
  customSize?: InputSize
  label?: string
  placeholder?: string
  id: string
  type: InputType
  name: string
  disabled?: boolean
  icon?: IconType
  required?: boolean
  characterLimit?: number
  error?: string | null
}

type InputSizeValues = {
  [K in InputSize]: {
    height: string
    padding: string
    iconOffset: string
    fontSize: string
  }
}

const inputSizeValues: InputSizeValues = {
  standard: {
    height: inputHeights.standard,
    padding: spacing.medium,
    iconOffset: "40px",
    fontSize: fontSizes.standard
  },
  small: {
    height: inputHeights.small,
    padding: spacing.scale(spacing.small, 1.5),
    iconOffset: "36px",
    fontSize: fontSizes.small
  }
}

const Container = styled.div`
  display: block;
`

const Wrap = styled.div`
  position: relative;
`

const Field = styled.input<FieldProps>`
  height: ${(props) => inputSizeValues[props.customSize].height};
  padding: 0 ${(props) => inputSizeValues[props.customSize].padding};
  background-color: ${offWhite};
  font-size: ${(props) => inputSizeValues[props.customSize].fontSize};
  border-radius: ${borderRadius};
  color: ${grey};
  width: 100%;
  border: 0;
  outline: 0;
  resize: none;
  box-shadow: none;
  transition: all ${animationTime} ${animationCurve};

  ${(props) =>
    props.hasPadding &&
    css`
      padding-left: ${inputSizeValues[props.customSize].iconOffset};
    `}

  &::placeholder {
    color: ${greyLight};
  }

  &:focus {
    background-color: ${white};
    box-shadow: 0 0 0 1px ${primaryColor}, inset 0 0 0 1px ${primaryColor};
  }

  ${(props) =>
    props.hasError &&
    css`
      box-shadow: 0 0 0 1px red, inset 0 0 0 1px red !important;
    `}

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
`

const IconWrap = styled.div<IconWrapProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${(props) => inputSizeValues[props.customSize].padding};
  opacity: 0.5;
  transition: opacity ${animationTime} ${animationCurve};

  ${Field}:focus + & {
    opacity: 1;
  }
`

const ErrorMessage = styled.div`
  text-align: left;
  margin-top: ${spacing.tiny};
`

export const Input = React.forwardRef<
  HTMLInputElement,
  InputProps & InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  const {
    label,
    placeholder,
    disabled,
    type,
    name,
    id,
    icon,
    required,
    characterLimit,
    customSize = "standard",
    error,
    ...rest
  } = props

  return (
    <Container>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Wrap>
        <Field
          placeholder={placeholder}
          name={name}
          id={id}
          type={type}
          disabled={disabled}
          required={required}
          maxLength={characterLimit}
          hasPadding={!!icon}
          customSize={customSize}
          hasError={!!error}
          ref={ref}
          {...rest}
        />
        {icon && (
          <IconWrap customSize={customSize}>
            <Icon icon={icon} size="small" />
          </IconWrap>
        )}
      </Wrap>
      {!!error && (
        <ErrorMessage>
          <Text size="tiny" intent="negative">
            {error}
          </Text>
        </ErrorMessage>
      )}
    </Container>
  )
})

Input.displayName = "Input"

export default Input
