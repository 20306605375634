import React, { useContext, useEffect, useMemo, useState } from "react"

import PageHeader from "../components/PageHeader"
import OrderDetails from "../components/Drawer/OrderDetails"
import Stack from "../components/Stack"
import { TabCustomItem, TabNavCustom } from "../components/TabNav"
import DataTable, { DataTableColumnConfig } from "../components/DataTable"
import Stats from "../components/Stats"
import Dropdown from "../components/Dropdown"
import { Columns, Column } from "../components/Column"
import { Order, OrdersQueryBucket, StatKind, useGetRetailerStatsQuery } from "../generated/graphql"
import FormattedStat from "../components/Stats/FormattedStat"
import { OrdersContext } from "../context/OrdersContext"
import moment from "moment"
import { OrderStatusBadge, OrderStatusAction, ShippingStatusBadge } from "../components/Orders"
import Input from "../components/Input"
import Pagination from "../components/Pagination"
import { OrderDetailsContextProvider } from "../context/OrderDetailsContext"
import { useHistory, useRouteMatch } from "react-router"
import { useDashboardPaths } from "../hooks/useDashboardPaths"
import { AppContext, SELECTED_PERIOD_OPTIONS } from "../context/AppContext"
import Text from "../components/Text"
import Badge from "../components/Badge"
import ShippingStatusAction from "../components/Orders/ShippingStatusAction"
import { OrderTimeRemaining } from "../components/Orders/OrderTimeRemaining"

const STAT_KINDS: StatKind[] = [
  StatKind.TotalOrders,
  StatKind.AvgResponse,
  StatKind.TimeToCollect,
  StatKind.FillRate
]

export type OrdersTab = "pending" | "collected" | "rejected"
const ORDERS_TABS: TabCustomItem<OrdersTab>[] = [
  {
    id: "pending",
    label: "Pending"
  },
  {
    id: "collected",
    label: "Collected"
  },
  {
    id: "rejected",
    label: "Rejected"
  }
]

const BUCKET_FOR_TAB: { [T in OrdersTab]: OrdersQueryBucket } = {
  pending: OrdersQueryBucket.Pending,
  collected: OrdersQueryBucket.Collected,
  rejected: OrdersQueryBucket.Rejected
}

const OrderStatusActionColumn = (order: Order): JSX.Element => {
  if (order.shipping_request) {
    return <ShippingStatusAction shipping_request={order.shipping_request} size="tiny" />
  } else {
    return <OrderStatusAction order={order} size="tiny" />
  }
}

const OrderNumberColumn = (order: Order): JSX.Element => {
  const orderNumber = useMemo(() => {
    const { order_number } = order
    if (order_number.length > 10) return "-"
    return order_number
  }, [order.order_number])

  return (
    <>
      <Text>{orderNumber}</Text>
      <Text size="tiny" intent="muted">
        {moment(order.order_date).fromNow()}
      </Text>
    </>
  )
}

const COLUMN_ORDER_NO: DataTableColumnConfig<Order> = {
  title: "Order Number",
  render: OrderNumberColumn
}

const COLUMN_CUSTOMER: DataTableColumnConfig<Order> = {
  title: "Customer",
  render: (order) => {
    if (!order.customer) return "Not available"
    const { first_name, last_name } = order.customer
    return [first_name, last_name].join(" ")
  }
}

const COLUMN_TIME_REMAINING: DataTableColumnConfig<Order> = {
  title: "Time Remaining",
  render: (order) => {
    return <OrderTimeRemaining order={order} />
  }
}

const COLUMN_STATUS: DataTableColumnConfig<Order> = {
  title: "Status",
  render: (order) => {
    if (!order.shipping_request) return <OrderStatusBadge {...order} />
    return <ShippingStatusBadge {...order.shipping_request} />
  }
}

const COLUMN_ACTION: DataTableColumnConfig<Order> = {
  title: "Actions",
  render: OrderStatusActionColumn
}

const COLUMN_TYPE: DataTableColumnConfig<Order> = {
  title: "Type / Courier",
  render: (order) => {
    return (
      <Badge>
        {order.shipping_request ? order.shipping_request.carrier_code.toLocaleUpperCase() : "CNC"}
      </Badge>
    )
  }
}

const Orders = (): JSX.Element => {
  const history = useHistory()
  const { params, path: routePath } = useRouteMatch<{ order_id?: string }>()
  const { orders: ordersPath } = useDashboardPaths(`/${routePath.split("/")[1]}`)
  const { selectedPeriodDays, setSelectedPeriodDays } = useContext(AppContext)
  const { orders, pageInfo, setCursor, setBucket, search, setSearch, isLoading } =
    useContext(OrdersContext)

  const { order_id } = params

  const [selectedTab, setSelectedTab] = useState<OrdersTab>("pending")

  const { data: statData } = useGetRetailerStatsQuery({
    variables: { input: { kinds: STAT_KINDS, period_days: selectedPeriodDays } }
  })

  useEffect(() => {
    const bucket = BUCKET_FOR_TAB[selectedTab]
    if (bucket) {
      setBucket(bucket)
    }
  }, [selectedTab])
  useEffect(() => () => setSearch(""), [])

  const columnConfig = useMemo(() => {
    const base = [COLUMN_ORDER_NO, COLUMN_TYPE, COLUMN_CUSTOMER, COLUMN_STATUS]
    if (selectedTab === "pending") base.push(COLUMN_TIME_REMAINING, COLUMN_ACTION)

    return base
  }, [selectedTab])

  return (
    <>
      <Stack>
        <PageHeader heading="Orders" />
        <Columns>
          <Column>
            <TabNavCustom
              selectedTab={selectedTab}
              onTabSelected={(tab) => setSelectedTab(tab)}
              tabs={ORDERS_TABS}
            />
          </Column>
          <Column width="content">
            <Dropdown
              id="duration"
              customSize="small"
              value={selectedPeriodDays}
              options={SELECTED_PERIOD_OPTIONS}
              onChange={(ev) => setSelectedPeriodDays(parseInt(ev.currentTarget.value))}
            />
          </Column>
        </Columns>
        {statData && (
          <Stats>
            {statData.retailer_stats.map((stat) => {
              return <FormattedStat key={stat.kind} stat={stat} />
            })}
          </Stats>
        )}
        <DataTable
          isLoading={!orders.length && isLoading}
          onRowSelected={(item) =>
            history.push(`${ordersPath}/${Buffer.from(item.order_number).toString("base64")}`)
          }
          items={orders}
          isHoverable={true}
          columns={columnConfig}
          header={
            <Input
              id="search"
              name="search"
              type="search"
              placeholder="Type to search orders..."
              customSize="small"
              icon="search"
              value={search || ""}
              onChange={(ev) => setSearch(ev.target.value.length ? ev.target.value : null)}
            />
          }
        />
        <Pagination
          pageInfo={pageInfo}
          onPrev={() => pageInfo?.prev_page && setCursor(pageInfo.prev_page)}
          onNext={() => pageInfo?.next_page && setCursor(pageInfo.next_page)}
        />
      </Stack>
      <OrderDetailsContextProvider order_no_base64={order_id}>
        <OrderDetails onClose={() => history.push(ordersPath)} />
      </OrderDetailsContextProvider>
    </>
  )
}

export default Orders
