import React from "react"
import { Shipment, ShipmentStatusType } from "../../generated/graphql"
import { useLastStatus } from "../../hooks/useLastStatus"
import Badge, { BadgeIntent } from "../Badge"

const STATUS_CONFIRMED_WITH_ISSUES = "Confirmed"

const STATUS_TITLES: {
  [Status in ShipmentStatusType]: string
} = {
  Assigned: "Assigned",
  Accepted: "Accepted",
  Pending: "Shipped",
  Confirmed: "Confirmed"
}

const STATUS_INTENTS: {
  [Status in ShipmentStatusType]: BadgeIntent
} = {
  Accepted: "positive",
  Pending: "warning",
  Assigned: "neutral",
  Confirmed: "positive"
}

export const ShipmentStatusBadge = (shipment: Shipment): JSX.Element => {
  const status = useLastStatus<ShipmentStatusType>({
    statuses: shipment.statuses,
    defaultType: ShipmentStatusType.Pending,
    overrides: {}
  })

  if (status.type === ShipmentStatusType.Confirmed && shipment.anomalies.length) {
    return <Badge intent="negative">{STATUS_CONFIRMED_WITH_ISSUES}</Badge>
  }

  const title = STATUS_TITLES[status.type]
  const intent = STATUS_INTENTS[status.type]
  return <Badge intent={intent}>{title}</Badge>
}

export default ShipmentStatusBadge
