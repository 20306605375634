// Greys

export const greyDark = "#111"
export const grey = "#555"
export const greyLight = "#999"
export const greyLightest = "#dedede"
export const offWhite = "#f7f7f7"

// Misc

export const white = "#fff"
export const black = "#000"
export const green = "#22C55E"
export const red = "#EF4444"
export const yellow = "#EAB308"

// Set colors

export const primaryColor = greyDark
export const borderColor = greyLightest
