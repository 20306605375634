import React from "react"

import { BaseProviderProps } from "./types"
import { InventoryLine, useGetInventoryQuery } from "../generated/graphql"

export interface InventoryContext {
  lines: InventoryLine[]
}

const DEFAULT_VALUE: InventoryContext = {
  lines: []
}

export const InventoryContext = React.createContext<InventoryContext>(DEFAULT_VALUE)

export const InventoryContextProvider = (props: BaseProviderProps): JSX.Element => {
  const { data } = useGetInventoryQuery({ variables: { input: {} } })

  const value = {
    lines: data?.inventory || []
  }

  return <InventoryContext.Provider value={value}>{props.children}</InventoryContext.Provider>
}
