import React from "react"
import styled from "styled-components"

import { greyDark } from "../styles/colors"
import * as spacing from "../styles/spacing"

import Text from "./Text"

export type LabelProps = {
  children: React.ReactNode
  htmlFor?: string
  muted?: boolean
}

const Container = styled(Text)`
  display: block;
  color: ${greyDark};
  margin-bottom: ${spacing.tiny};
  text-align: left;
`

const Label = ({ children, muted, htmlFor, ...rest }: LabelProps): JSX.Element => (
  <Container as="label" htmlFor={htmlFor} role="label" {...rest}>
    <Text size="tiny" intent={muted ? "muted" : undefined} weight="semibold">
      {children}
    </Text>
  </Container>
)

export default Label
