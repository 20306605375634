import React from "react"
import styled from "styled-components"

import { white, borderColor, offWhite, greyDark } from "../styles/colors"
import * as spacing from "../styles/spacing"
import { borderRadius } from "../styles/variables"
import { boxShadow } from "../styles/helpers"

import SmallCaps from "../components/SmallCaps"

export type CardSpacing = "medium" | "standard" | "large"

export type InnerProps = {
  space: CardSpacing
}

export type CardProps = {
  heading?: string
  children: React.ReactNode
  space?: CardSpacing
}

type CardSpacingValues = {
  [K in CardSpacing]: string
}

const cardSpacingValues: CardSpacingValues = {
  medium: spacing.medium,
  standard: spacing.large,
  large: spacing.xxLarge
}

const Container = styled.div`
  background-color: ${white};
  border-radius: ${borderRadius};
  border: 1px solid ${borderColor};
  overflow: hidden;
  ${boxShadow.small};
`

const Header = styled.header`
  background-color: ${offWhite};
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing.small} ${spacing.medium};
`

const Title = styled(SmallCaps)`
  color: ${greyDark};
  display: block;
`

const Inner = styled.div<InnerProps>`
  padding: ${(props) => cardSpacingValues[props.space]};
`

const Card = (props: CardProps): JSX.Element => {
  const { children, heading, space = "standard" } = props
  return (
    <Container>
      {heading && (
        <Header>
          <Title>{heading}</Title>
        </Header>
      )}
      {children && <Inner space={space}>{children}</Inner>}
    </Container>
  )
}

export default Card
