import React, { PropsWithChildren, useRef } from "react"
import styled from "styled-components"
import { rgba, stripUnit } from "polished"
import { Dialog } from "@headlessui/react"
import { motion, AnimatePresence } from "framer-motion"

import { black, white, grey } from "../../styles/colors"
import * as spacing from "../../styles/spacing"
import { cover, boxShadow } from "../../styles/helpers"
import { animationTime, animationCurve, borderRadius } from "../../styles/variables"

import Heading from "../Heading"
import Icon from "../Icon"

const DURATION = stripUnit(animationTime)

const modalVariants = {
  initial: {
    opacity: 0,
    scale: 0.95,
    transition: { duration: DURATION }
  },
  enter: { opacity: 1, scale: 1, transition: { duration: DURATION } }
}

const backdropVariants = {
  initial: {
    opacity: 0,
    transition: { duration: DURATION }
  },
  enter: { opacity: 1, transition: { duration: DURATION } }
}

export type ModalProps = {
  isOpen: boolean
  heading?: string
  onClose: () => void
  footer?: React.ReactNode
}

const Container = styled.div`
  ${cover("fixed")};
`

const Backdrop = styled(Dialog.Overlay)`
  background-color: ${rgba(black, 0.75)};
  ${cover("fixed")};
`

const Outer = styled.div`
  position: static;
  height: 100%;
  width: 100%;
`

const Table = styled.div`
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
`

const Cell = styled.div`
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  padding: ${spacing.large};
`

const Inner = styled.div`
  position: relative;
  padding: ${spacing.large};
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  background-color: ${white};
  border-radius: ${borderRadius};
  pointer-events: initial;
  ${boxShadow.large};
`

const Close = styled.button`
  display: flex;
  position: absolute;
  z-index: 10; /* Position above heading */
  top: ${spacing.small};
  right: ${spacing.small};
  color: ${grey};
  padding: ${spacing.small};
  opacity: 0.75;
  transition: opacity ${animationTime} ${animationCurve};
  outline: 0;

  &:hover {
    opacity: 1;
  }
`

const Header = styled.header`
  margin-bottom: ${spacing.medium};
`

const Title = styled(Heading)`
  margin-bottom: 0;
`

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacing.large};
`

const Modal = (props: PropsWithChildren<ModalProps>): JSX.Element => {
  const { heading, children, footer, isOpen, onClose } = props
  const closeRef = useRef<HTMLButtonElement>(null)

  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog open={isOpen} onClose={onClose} static initialFocus={closeRef}>
          <Container>
            {/* Backdrop */}
            <motion.div
              key="backdrop"
              variants={backdropVariants}
              initial="initial"
              animate="enter"
              exit="initial"
              transition={{ ease: animationCurve }}
            >
              <Backdrop />
            </motion.div>

            {/* Modal */}
            <Outer>
              <motion.div
                key="modal"
                variants={modalVariants}
                initial="initial"
                animate="enter"
                exit="initial"
                transition={{ ease: animationCurve }}
                style={{ height: "100%" }}
              >
                <Table>
                  <Cell>
                    <Inner>
                      <Close onClick={onClose} ref={closeRef}>
                        <Icon icon="close" size="small" />
                      </Close>
                      <Header>
                        <Title size={2}>{heading}</Title>
                      </Header>

                      <div>{children}</div>
                      {footer && <Footer>{footer}</Footer>}
                    </Inner>
                  </Cell>
                </Table>
              </motion.div>
            </Outer>
          </Container>
        </Dialog>
      )}
    </AnimatePresence>
  )
}

export default Modal
