import moment from "moment"
import React, { useMemo } from "react"
import { Order, OrderStatus, OrderStatusType } from "../../generated/graphql"
import { useLastStatus } from "../../hooks/useLastStatus"
import Timeline, { TimelineStep } from "../Timeline"

export type OrderTimelineProps = {
  order: Order
}

type StatusByTypeMap = {
  [K in OrderStatusType]?: OrderStatus
}

const STEPS_TITLES: {
  [K in OrderStatusType]?: string
} = {
  [OrderStatusType.Confirmed]: "Order placed",
  [OrderStatusType.Accepted]: "Accepted",
  [OrderStatusType.Picked]: "Ready For Pickup",
  [OrderStatusType.Collected]: "Collected"
}

const useStatusesByTypeMap = (statuses: OrderStatus[]): StatusByTypeMap => {
  return useMemo(() => {
    return statuses.reduce<StatusByTypeMap>((acc, val) => {
      acc[val.type] = val
      return acc
    }, {})
  }, [statuses])
}

const STATUS_TYPES = [
  OrderStatusType.Confirmed,
  OrderStatusType.Accepted,
  OrderStatusType.Picked,
  OrderStatusType.Collected
]

const intervalBetweenStatuses = (
  current: OrderStatus | undefined,
  next: OrderStatus | undefined
): string | undefined => {
  if (!current || !next) return undefined
  return moment
    .duration(new Date(next.status_date).getTime() - new Date(current.status_date).getTime())
    .humanize({ s: 1 })
}

const timeLabelForStatus = (status?: OrderStatus): string => {
  return status?.status_date
    ? new Date(status?.status_date).toLocaleTimeString(undefined, { timeStyle: "short" })
    : "-"
}

export const OrderTimeline = (props: OrderTimelineProps): JSX.Element => {
  const statusesByType = useStatusesByTypeMap(props.order.statuses)

  const lastStatus = useLastStatus<OrderStatusType>({
    statuses: props.order.statuses,
    defaultType: OrderStatusType.NotAvailable,
    overrides: {}
  })

  const steps: TimelineStep[] = useMemo(() => {
    return STATUS_TYPES.map((statusType, index): TimelineStep => {
      const currentStatus = statusesByType[statusType]
      const nextType = STATUS_TYPES[index + 1]
      const previousType = index > 0 ? STATUS_TYPES[index - 1] : null
      const nextStatus = statusesByType[nextType]
      const previousStatus = previousType ? statusesByType[previousType] : null

      const duration = intervalBetweenStatuses(currentStatus, nextStatus)
      const timeLabel = timeLabelForStatus(currentStatus)

      return {
        timeLabel,
        duration,
        label: STEPS_TITLES[statusType] || "N/A",
        status: currentStatus
          ? "completed"
          : previousStatus
          ? lastStatus.type !== OrderStatusType.Exception
            ? "active"
            : "empty"
          : "empty"
      }
    })
  }, [statusesByType])

  return <Timeline steps={steps} />
}
