import React from "react"
import styled from "styled-components"

import * as spacing from "../styles/spacing"

export type ColumnWidth = "1/5" | "1/2" | "1/3" | "content"
export type ColumnAlignment = "top" | "center" | "bottom"

export type ColumnContainerProps = {
  width?: ColumnWidth
  space?: "none"
}

export type ColumnsContainerProps = {
  alignment: ColumnAlignment
}

type ColumnAlignmentValues = {
  [K in ColumnAlignment]: string
}

const columnAlignmentValues: ColumnAlignmentValues = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end"
}

type ColumnWidthValues = {
  [K in ColumnWidth]: string
}

const columnWidthValues: ColumnWidthValues = {
  "1/5": "20%",
  "1/2": "50%",
  "1/3": "33.3333%",
  content: "auto"
}

const ColumnsContainer = styled.div<ColumnsContainerProps>`
  display: flex;
  margin: 0 -${spacing.scale(spacing.large, 0.5)};
  align-items: ${(props) => columnAlignmentValues[props.alignment]};
`

const ColumnContainer = styled.div<ColumnContainerProps>`
  padding: 0
    ${(props) =>
      props.space === "none" ? 0 : spacing.scale(spacing.large, 0.5)};
  width: ${(props) => (props.width ? columnWidthValues[props.width] : "100%")};
  flex-shrink: ${(props) => (props.width === "content" ? "0" : "initial")};
  min-width: 0;
`

export type ColumnProps = {
  children: React.ReactNode
  width?: ColumnWidth
  space?: "none"
}

export const Column = (props: ColumnProps): JSX.Element => {
  const { children, width, space } = props
  return (
    <ColumnContainer width={width} space={space}>
      {children}
    </ColumnContainer>
  )
}

export type ColumnsProps = {
  children: React.ReactNode
  alignment?: ColumnAlignment
}

export const Columns = (props: ColumnsProps): JSX.Element => {
  const { children, alignment = "top" } = props
  return <ColumnsContainer alignment={alignment}>{children}</ColumnsContainer>
}
