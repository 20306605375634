import React, { useContext, useState } from "react"
import { Link, Redirect } from "react-router-dom"
import { useForm } from "react-hook-form"

import Input from "../components/Input"
import Card from "../components/Card"
import LoginLayout from "../layouts/LoginLayout"
import Button from "../components/Button"
import Stack from "../components/Stack"
import Heading from "../components/Heading"
import Alert from "../components/Alert"
import { AuthContext } from "../context/AuthContext"

import { REGEX_EMAIL } from "../utils/regex"

type FormData = {
  email: string
  password: string
}

const Login = (): JSX.Element => {
  const { isAuthenticated, login } = useContext(AuthContext)
  const [loginError, setLoginError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { errors: formErrors }
  } = useForm<FormData>()

  const onSubmit = handleSubmit(async (data) => {
    trigger()

    setIsLoading(true)
    try {
      await login(data)
    } catch (e) {
      setLoginError(true)
      clearErrors()
    } finally {
      setIsLoading(false)
    }
  })

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />
  }

  return (
    <LoginLayout>
      <form onSubmit={onSubmit}>
        <Card space="large">
          <Stack>
            <Heading size={1}>Log in</Heading>
            <Input
              type="email"
              id="email"
              label="Email address"
              placeholder="john.doe@mail.com"
              error={formErrors.email?.message}
              {...register("email", {
                required: true,
                pattern: {
                  value: REGEX_EMAIL,
                  message: "Email field must be a valid email address"
                }
              })}
            />
            <Input
              type="password"
              id="password"
              label="Password"
              error={formErrors.password?.message}
              placeholder="••••••••••"
              {...register("password", {
                required: "Password is required",
                minLength: { value: 8, message: "Password must be at least 8 characters long" },
                maxLength: { value: 64, message: "Password must less than 64 characters long" }
              })}
            />
            {!!loginError && <Alert intent="negative">Incorrect email or password.</Alert>}
            <Button intent="primary" full onClick={onSubmit} isLoading={isLoading}>
              Log in
            </Button>
            <Link to="/forgot">Forgot password?</Link>
          </Stack>
        </Card>
      </form>
    </LoginLayout>
  )
}

export default Login
