import React, { useCallback, useContext, useRef } from "react"
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { BaseProviderProps } from "./types"
import { AuthContext } from "./AuthContext"
import { config } from "../config"

const BASE_URL = `${config.API_URL}/graphql`

export const ApolloContextProvider = (props: BaseProviderProps): JSX.Element => {
  const { session, logout } = useContext(AuthContext)

  const httpLink = createHttpLink({
    uri: BASE_URL
  })

  const contextFunction = useCallback(
    (_, { headers }) => {
      const token = session?.session_token

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : undefined
        }
      }
    },
    [session]
  )

  const authLink = setContext(contextFunction)

  const errorLink = onError((errorResponse) => {
    const isAuthError = errorResponse.graphQLErrors?.some((e) => {
      return e.extensions?.code === "UNAUTHENTICATED"
    })

    // eslint-disable-next-line no-console
    console.error("GraphQL error", { errorResponse })

    if (isAuthError) {
      logout({ userInitiated: false })
    }
  })

  const apolloClient = useRef(
    new ApolloClient({
      link: authLink.concat(errorLink.concat(httpLink)),
      cache: new InMemoryCache()
    })
  )

  return <ApolloProvider client={apolloClient.current}>{props.children}</ApolloProvider>
}
