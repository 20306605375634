import { stripUnit } from "polished"

export const tiny = "4px"
export const small = "8px"
export const medium = "16px"
export const large = "24px"
export const xLarge = "32px"
export const xxLarge = "40px"
export const xxxLarge = "56px"

export const scale = (spacing: string, factor: number): string => {
  return `${(stripUnit(spacing) as number) * factor}px`
}
