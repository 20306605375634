import { useEffect, useState } from "react"

export const useClock = (props: { updateEveryMs: number }): Date => {
  const { updateEveryMs } = props
  const [time, setTime] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date())
    }, updateEveryMs)

    return () => clearInterval(interval)
  }, [updateEveryMs])

  return time
}
