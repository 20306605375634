import React from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"

import { white, offWhite, red, greyDark } from "../../styles/colors"
import * as spacing from "../../styles/spacing"
import { navWidth, animationTime, animationCurve } from "../../styles/variables"
import { square } from "../../styles/helpers"
import { fontSizes, fontWeights } from "../../styles/typography"

import Icon, { IconType } from "../../components/Icon"

export type NavItemProps = {
  to: string
  label: string
  icon: IconType
  count?: number | null
}

const Container = styled(NavLink)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${white};
  width: ${navWidth};
  padding: ${spacing.scale(spacing.small, 1.5)} 0;
  opacity: 0.5;
  transition: opacity ${animationTime} ${animationCurve};

  &:hover,
  &:focus,
  &${".active"} {
    opacity: 1;
  }

  &${".active"}:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 6px 5px 0;
    border-color: transparent ${offWhite} transparent transparent;
    transform: translateY(-50%);
  }
`

const IconWrap = styled.div`
  position: relative;
  margin-bottom: ${spacing.small};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  ${square("24px")};
`

const Label = styled.span`
  display: block;
  font-size: ${fontSizes.tiny};
  font-weight: ${fontWeights.medium};
`

const Count = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${red};
  color: ${white};
  font-size: ${fontSizes.tiny};
  font-weight: ${fontWeights.semibold};
  border-radius: 8px;
  top: -6px;
  right: -11px;
  box-shadow: 0 0 0 2px ${greyDark};
  height: 16px;
  min-width: 16px;
  padding: 0 4px;
`

const NavItem = (props: NavItemProps): JSX.Element => {
  const { to, label, icon, count } = props

  return (
    <Container to={to} exact>
      <IconWrap>
        {count && <Count>{count}</Count>}
        <Icon icon={icon} />
      </IconWrap>
      <Label>{label}</Label>
    </Container>
  )
}

export default NavItem
