import React from "react"
import styled from "styled-components"
import { mix, rgba } from "polished"

import { grey, red, green, black, yellow } from "../styles/colors"
import * as spacing from "../styles/spacing"
import { fontSizes } from "../styles/typography"

export type BadgeIntent = "positive" | "negative" | "neutral" | "warning"

export type ContainerProps = {
  intent: BadgeIntent
}

export type BadgeProps = {
  children: React.ReactNode
  intent?: BadgeIntent
}

type BadgeIntentColors = {
  [K in BadgeIntent]: {
    color: string
  }
}

const badgeIntentColors: BadgeIntentColors = {
  positive: { color: green },
  negative: { color: red },
  neutral: { color: grey },
  warning: { color: yellow }
}

const Container = styled.div<ContainerProps>`
  height: ${spacing.large};
  flex-shrink: 0;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  padding: 0 ${spacing.scale(spacing.small, 1.25)};
  border-radius: ${spacing.large};
  color: ${(props) => mix(0.75, badgeIntentColors[props.intent].color, black)};
  background-color: ${(props) => rgba(badgeIntentColors[props.intent].color, 0.2)};
  font-size: ${fontSizes.small};
`

const Badge = (props: BadgeProps): JSX.Element => {
  const { intent = "neutral", children } = props

  return <Container intent={intent}>{children}</Container>
}

export default Badge
