import React from "react"
import styled from "styled-components"

import { green, greyLight, greyDark, red } from "../styles/colors"
import { fontSizes, fontWeights } from "../styles/typography"

export type TextSize = "large" | "standard" | "small" | "tiny"
export type TextWeight = "book" | "medium" | "semibold" | "bold"
export type TextIntent = "neutral" | "positive" | "negative" | "muted" | "dark"
export type TextAlignment = "left" | "right" | "center"

type TextSizes = {
  [K in TextSize]: string
}

type TextWeights = {
  [K in TextWeight]: number
}

type TextIntents = {
  [K in TextIntent]: string
}

const textSizes: TextSizes = {
  large: fontSizes.large,
  standard: fontSizes.standard,
  small: fontSizes.small,
  tiny: fontSizes.tiny
}

const textWeights: TextWeights = {
  book: fontWeights.book,
  medium: fontWeights.medium,
  semibold: fontWeights.semibold,
  bold: fontWeights.bold
}

const textIntents: TextIntents = {
  neutral: "currentColor",
  positive: green,
  negative: red,
  muted: greyLight,
  dark: greyDark
}

export type ContainerProps = {
  size: TextSize
  weight: TextWeight
  intent: TextIntent
  alignment: TextAlignment
}

const Container = styled.span<ContainerProps>`
  display: block;
  font-size: ${(props) => textSizes[props.size]};
  font-weight: ${(props) => textWeights[props.weight]};
  color: ${(props) => textIntents[props.intent]};
  text-align: ${(props) => props.alignment};
`

export type TextProps = {
  children: React.ReactNode
  size?: TextSize
  weight?: TextWeight
  intent?: TextIntent
  alignment?: TextAlignment
}

const Text = (props: TextProps): JSX.Element => {
  const {
    size = "standard",
    weight = "book",
    intent = "neutral",
    alignment = "left",
    children
  } = props

  return (
    <Container
      size={size}
      weight={weight}
      intent={intent}
      alignment={alignment}
    >
      {children}
    </Container>
  )
}

export default Text
