import { useMemo } from "react"

export interface IStatus<T> {
  type: T
  status_date: string | null
}

export type UseLastStatusProps<StatusType extends string> = {
  statuses?: IStatus<StatusType>[]
  applicableStatuses?: StatusType[]
  overrides: Partial<Record<StatusType, StatusType>>
  defaultType: StatusType
}

export const useLastStatus = <StatusType extends string>(
  props: UseLastStatusProps<StatusType>
): IStatus<StatusType> => {
  const { statuses, applicableStatuses, defaultType, overrides } = props

  const filtered = useMemo(() => {
    if (!applicableStatuses) return statuses || []
    const applicableSet = new Set<StatusType>(applicableStatuses)
    return (statuses || []).filter((s) => applicableSet.has(s.type))
  }, [applicableStatuses, statuses])

  const sortedStatuses = useMemo(() => {
    return filtered
      .filter((s) => !!s.status_date)
      .sort((a, b) => {
        if (!a.status_date) return 0
        if (!b.status_date) return 0
        const aDate = new Date(a.status_date)
        const bDate = new Date(b.status_date)
        return aDate.getTime() - bDate.getTime()
      })
  }, [filtered])

  const lastStatus = useMemo(() => {
    if (sortedStatuses.length > 0) {
      return sortedStatuses[sortedStatuses.length - 1]
    }

    return { type: defaultType, status_date: null }
  }, [sortedStatuses])

  const overrideType = overrides[lastStatus.type]
  if (overrideType) {
    const overridenStatus = filtered?.find((s) => s.type === overrideType)
    if (overridenStatus) {
      return overridenStatus
    }
  }

  return lastStatus
}
