import React from "react"
import styled from "styled-components"
import { mix, rgba } from "polished"

import { grey, red, green, black, yellow } from "../styles/colors"
import * as spacing from "../styles/spacing"
import { borderRadius } from "../styles/variables"

export type AlertIntent = "positive" | "negative" | "neutral" | "warning"

export type ContainerProps = {
  intent: AlertIntent
}

export type AlertProps = {
  children: React.ReactNode
  intent?: AlertIntent
}

type AlertIntentColors = {
  [K in AlertIntent]: {
    color: string
  }
}

const alertIntentColors: AlertIntentColors = {
  positive: { color: green },
  negative: { color: red },
  neutral: { color: grey },
  warning: { color: yellow }
}

const Container = styled.div<ContainerProps>`
  display: flex;
  padding: ${spacing.scale(spacing.medium, 0.75)} ${spacing.medium};
  border-radius: ${borderRadius};
  color: ${(props) => mix(0.75, alertIntentColors[props.intent].color, black)};
  border-left: 4px solid ${(props) => alertIntentColors[props.intent].color};
  background-color: ${(props) => rgba(alertIntentColors[props.intent].color, 0.2)};
  text-align: left;
`

const Alert = (props: AlertProps): JSX.Element => {
  const { intent = "neutral", children } = props

  return <Container intent={intent}>{children}</Container>
}

export default Alert
