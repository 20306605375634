import React from "react"
import styled from "styled-components"
import { rgba } from "polished"

import { greyDark } from "../styles/colors"
import * as spacing from "../styles/spacing"
import { animationTime, animationCurve } from "../styles/variables"
import { square } from "../styles/helpers"

import Icon from "./Icon"
import Inline from "./Inline"
import { PageInfo } from "../generated/graphql"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all ${animationTime} ${animationCurve};
  ${square(spacing.xLarge)};

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    color: ${greyDark};
    background-color: ${rgba(greyDark, 0.1)};
  }
`

// const Text = styled.span`
//   font-size: ${fontSizes.small};
// `

export type PaginationProps = {
  pageInfo: PageInfo | null
  onPrev?: () => void
  onNext?: () => void
}

const Pagination = (props: PaginationProps): JSX.Element => {
  const { pageInfo, onPrev, onNext } = props
  return (
    <Container>
      <Inline>
        {/* <Text>TODO: total count</Text> */}
        <Inline space="small">
          <Button disabled={!pageInfo?.prev_page} onClick={onPrev}>
            <Icon icon="chevron-left" size="small" />
          </Button>
          <Button disabled={!pageInfo?.next_page} onClick={onNext}>
            <Icon icon="chevron-right" size="small" />
          </Button>
        </Inline>
      </Inline>
    </Container>
  )
}

export default Pagination
