// Animation
export const animationTime = "0.3s"
export const animationCurve = "cubic-bezier(0.785, 0.135, 0.150, 0.860)"

// Border Radius
export const borderRadius = "5px"

// Inputs
export const inputHeights = {
  standard: "40px",
  small: "32px",
  tiny: "24px"
}

// Nav
export const navWidth = "96px"
