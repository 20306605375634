import getHours from "date-fns/getHours"

export const getDayPeriod = (date: Date): string => {
  const hour = getHours(date)

  if (hour < 12) {
    return "morning"
  } else if (hour >= 12 && hour < 18) {
    return "afternoon"
  } else {
    return "evening"
  }
}
