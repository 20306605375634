import React from "react"
import styled, { css } from "styled-components"

import { greyDark, borderColor, white } from "../../styles/colors"
import { square } from "../../styles/helpers"
import * as spacing from "../../styles/spacing"
import { fontSizes, fontWeights } from "../../styles/typography"
import { rotate } from "../../styles/animations"

import Stack from "../Stack"
import Icon from "../Icon"

export type StepStatus = "active" | "completed" | "empty"

export type FigureProps = {
  status?: StepStatus
}

export type StepProps = {
  label: string
  time: string
  status?: StepStatus
  duration?: string
}

const Container = styled.div`
  position: relative;
  text-align: center;
  flex: 1;
`

const Label = styled.span`
  display: block;
  font-size: ${fontSizes.small};
`

const Time = styled.span`
  color: ${greyDark};
  display: block;
  font-weight: ${fontWeights.bold};
`

const Figure = styled.div<FigureProps>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${borderColor};
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 0 ${spacing.small} ${white};
  ${square("40px")};

  ${(props) =>
    props.status === "completed" &&
    css`
      color: ${white};
      background-color: ${greyDark};
    `}
`

const Line = styled.div`
  position: absolute;
  top: 20px; /* Half figure height */
  left: 100%;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  background-color: ${borderColor};

  ${Container}:last-child & {
    display: none;
  }
`

const Duration = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: ${greyDark};
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.tiny};
  background-color: ${white};
  padding: 0 ${spacing.scale(spacing.tiny, 1.5)};
`

const Spinner = styled.div`
  display: flex;
  animation: ${rotate} 1s linear infinite;
`

const Step = (props: StepProps): JSX.Element => {
  const { label, time, duration, status = "empty" } = props

  return (
    <Container>
      <Stack space="small">
        <Figure status={status}>
          {status === "completed" && <Icon icon="checkmark" size="small" />}
          {status === "active" && (
            <Spinner>
              <Icon icon="loading" size="small" />
            </Spinner>
          )}
        </Figure>
        <div>
          <Label>{label}</Label>
          <Time>{time}</Time>
        </div>
      </Stack>
      <Line>{duration !== undefined && <Duration>{duration}</Duration>}</Line>
    </Container>
  )
}

export default Step
