import React, { useContext } from "react"

import Card from "../../components/Card"
import Stack from "../../components/Stack"
import Button from "../../components/Button"
import { Column, Columns } from "../../components/Column"
import OpeningHourControl from "../OpeningHourControl"

import Alert from "../Alert"
import Text from "../Text"

import { AppContext } from "../../context/AppContext"
import { useFormattedRetailerAddress } from "../../hooks/useFormattedRetailerAddress"
import { useOpeningHours } from "../../hooks/useOpeningHours"
import { useState } from "react"
import Inline from "../Inline"

export const Hours = (): JSX.Element => {
  const { currentUser } = useContext(AppContext)
  const formattedAddress = useFormattedRetailerAddress(currentUser?.retailer)
  const openingHours = useOpeningHours()

  const [hoursUpdated, setHoursUpdated] = useState(false)
  const [exceptionsUpdated, setExceptionsUpdated] = useState(false)

  const handleUpdateHours = async () => {
    await openingHours.saveWeeklyHours()
    setHoursUpdated(true)
  }

  const handleUpdateExceptions = async () => {
    await openingHours.saveExceptions()
    setExceptionsUpdated(true)
  }

  return (
    <Stack>
      {!openingHours.isLoading && !openingHours.openingHoursCompleted && (
        <Alert intent="warning">You have not set any opening hours for your store.</Alert>
      )}
      <Card>
        <Stack hasDivider>
          <Stack space="medium">
            <Text>
              Keep your hours of operations updated to ensure customers are aware of when they can
              pick-up their orders from your store.
            </Text>
            <div>
              <Text weight="bold" intent="dark">
                {currentUser?.retailer?.name || ""}
              </Text>
              <Text size="small">{formattedAddress}</Text>
            </div>
          </Stack>
          <Stack>
            <Stack space="medium">
              {openingHours.weeklyEntries.map((oh) => {
                return (
                  <OpeningHourControl
                    key={oh._id}
                    openingHour={oh}
                    dispatch={openingHours.dispatch}
                  />
                )
              })}
            </Stack>
            <Inline>
              <Button
                intent="primary"
                onClick={handleUpdateHours}
                disabled={openingHours.isLoading}
              >
                Update hours
              </Button>
              {hoursUpdated && <Text intent="muted">Hours updated successfully.</Text>}
            </Inline>
          </Stack>
        </Stack>
      </Card>
      <Card>
        <Stack hasDivider>
          <Columns alignment="center">
            <Column>
              <Stack space="tiny">
                <Text weight="bold" intent="dark">
                  Exceptions
                </Text>
                <Text size="small">Manage hours exceptions for holidays, etc.</Text>
              </Stack>
            </Column>
            <Column width="content">
              <Button
                intent="secondary"
                size="small"
                onClick={() => openingHours.dispatch({ type: "add" })}
              >
                + Add
              </Button>
            </Column>
          </Columns>
          <Stack>
            {openingHours.exceptionEntries.length === 0 && (
              <Alert intent="neutral">
                You have not set any opening hours exceptions for your store.
              </Alert>
            )}
            <Stack space="medium">
              {openingHours.exceptionEntries.map((oh) => {
                return (
                  <OpeningHourControl
                    key={oh._id}
                    openingHour={oh}
                    dispatch={openingHours.dispatch}
                  />
                )
              })}
            </Stack>
            <Inline>
              <Button
                intent="primary"
                onClick={handleUpdateExceptions}
                disabled={openingHours.isLoading}
              >
                Update exceptions
              </Button>
              {exceptionsUpdated && <Text intent="muted">Exceptions updated successfully.</Text>}
            </Inline>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  )
}
