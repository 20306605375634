import React from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"

import { GlobalStyle } from "../styles/global"

import Dashboard from "../pages/Dashboard"
import Login from "../pages/Login"
import { AuthContextProvider } from "../context/AuthContext"
import ForgotPassword from "../pages/ForgotPassword"
import ResetPassword from "../pages/ResetPassword"

export const App = (): JSX.Element => {
  return (
    <AuthContextProvider>
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/forgot" component={ForgotPassword} />
          <Route path="/reset" component={ResetPassword} />
          <Route path="/dashboard" component={Dashboard} />
          <Route>
            <Redirect to="/login" />
          </Route>
        </Switch>
      </BrowserRouter>
    </AuthContextProvider>
  )
}

export default App
