import React from "react"
import { Link } from "react-router-dom"
import Heading from "../components/Heading"
import PageHeader from "../components/PageHeader"
import Stack from "../components/Stack"

const Help = (): JSX.Element => {
  return (
    <Stack>
      <PageHeader heading="Help" />
      <Stack>
        <Heading size={1}>Get In Touch</Heading>
        <Heading size={2}>You&lsquo;ve got questions? We&lsquo;ve got answers.</Heading>
        <Stack>
          <Heading size={3}>Call Us</Heading>
          <Heading size={4}>Customer service priority line</Heading>
          <p>
            1-800-818-2771 ext. 6540
            <br />
            Monday to Friday 8am-8pm EST
            <br />
            Saturday and Sunday 10am-5pm EST
          </p>
        </Stack>
        <Stack>
          <Heading size={3}>Email us:</Heading>
          <Link to="mailto:cnc-support@bat.com">cnc-support@bat.com</Link>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Help
