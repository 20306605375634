import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { BaseProviderProps } from "./types"
import {
  PageInfo,
  Shipment,
  ShipmentAnomalyReason,
  useConfirmShipmentMutation,
  useGetPossibleAnomalyReasonsQuery,
  useGetShipmentsQuery
} from "../generated/graphql"

export interface ShipmentsContext {
  shipments: Shipment[]
  refetch: () => void
  isLoading: boolean
  pageInfo: PageInfo | null
  setCursor: Dispatch<SetStateAction<string | null>>
  search: string | null
  setSearch: Dispatch<React.SetStateAction<string | null>>
  anomalyReasons: ShipmentAnomalyReason[]
  confirmShipment: ReturnType<typeof useConfirmShipmentMutation>[0]
  confirmShipmentData: ReturnType<typeof useConfirmShipmentMutation>[1]
}

const DEFAULT_VALUE: ShipmentsContext = {
  shipments: [],
  refetch: () => null,
  isLoading: true,
  setCursor: () => null,
  setSearch: () => null,
  search: null,
  pageInfo: null,
  anomalyReasons: [],
  confirmShipment: () => Promise.resolve({}),
  confirmShipmentData: {} as never
}

export const ShipmentsContext = React.createContext<ShipmentsContext>(DEFAULT_VALUE)

export const ShipmentsContextProvider = (props: BaseProviderProps): JSX.Element => {
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null)
  const [cursor, setCursor] = useState<string | null>(null)
  const [search, setSearch] = useState<string | null>("")
  const [shipments, setShipments] = useState<Shipment[]>([])

  const { data, loading, refetch } = useGetShipmentsQuery({
    variables: {
      input: {
        cursor,
        search
      }
    },
    onCompleted: (data) => {
      setPageInfo(data.shipments.page_info)
    }
  })

  useEffect(() => {
    if (!data?.shipments.shipments.length && loading) return
    setShipments(data?.shipments.shipments || [])
  }, [data, loading])

  const reasonsQuery = useGetPossibleAnomalyReasonsQuery()

  const [confirmShipment, confirmShipmentData] = useConfirmShipmentMutation({})

  const value = {
    shipments,
    refetch,
    isLoading: loading,
    pageInfo,
    setCursor,
    setSearch,
    search,
    anomalyReasons: reasonsQuery.data?.shipment_anomaly_possible_reasons || [],
    confirmShipment,
    confirmShipmentData
  }

  return <ShipmentsContext.Provider value={value}>{props.children}</ShipmentsContext.Provider>
}
