import React, { useContext } from "react"

import PageHeader from "../components/PageHeader"
import Heading from "../components/Heading"
import Stack from "../components/Stack"
import Card from "../components/Card"
import Stats from "../components/Stats"
import OrderChart from "../components/home/OrderChart"
import FormattedStat from "../components/Stats/FormattedStat"

import { AppContext, SELECTED_PERIOD_OPTIONS } from "../context/AppContext"
import { useGreetings } from "../hooks/useGreetings"
import { StatKind, useGetOrdersChartQuery, useGetRetailerStatsQuery } from "../generated/graphql"
import Inline from "../components/Inline"
import Dropdown from "../components/Dropdown"

const STAT_KINDS: StatKind[] = [
  StatKind.TotalOrders,
  StatKind.AvgResponse,
  StatKind.TimeToCollect,
  StatKind.FillRate
]

const Home = (): JSX.Element => {
  const { currentUser, selectedPeriodDays, setSelectedPeriodDays } = useContext(AppContext)

  const { userGreeting, storeGreeting } = useGreetings({ currentUser })

  const { data: statData } = useGetRetailerStatsQuery({
    variables: { input: { kinds: STAT_KINDS, period_days: selectedPeriodDays } }
  })

  const { data: chartData } = useGetOrdersChartQuery({
    variables: { input: { period_days: selectedPeriodDays } }
  })

  return (
    <Stack>
      <PageHeader
        lead={userGreeting}
        heading={storeGreeting}
        actions={
          <Inline>
            <Dropdown
              id="duration"
              customSize="small"
              value={selectedPeriodDays}
              options={SELECTED_PERIOD_OPTIONS}
              onChange={(ev) => setSelectedPeriodDays(parseInt(ev.currentTarget.value))}
            />
          </Inline>
        }
      />
      {statData && (
        <Stats>
          {statData?.retailer_stats.map((stat) => {
            return <FormattedStat key={stat.kind} stat={stat} />
          })}
        </Stats>
      )}

      <Card>
        <Stack>
          <Heading size={5}>Number of orders</Heading>
          <OrderChart chartData={chartData?.orders_chart || []} />
        </Stack>
      </Card>
    </Stack>
  )
}

export default Home
