import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"

import Input from "../components/Input"
import Card from "../components/Card"
import LoginLayout from "../layouts/LoginLayout"
import Button from "../components/Button"
import Stack from "../components/Stack"
import Heading from "../components/Heading"
import { AuthContext } from "../context/AuthContext"

import { REGEX_EMAIL } from "../utils/regex"
import Alert from "../components/Alert"

type FormData = {
  email: string
}

const ForgotPassword = (): JSX.Element => {
  const { forgotPassword } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [requested, setRequested] = useState(false)
  const [forgotError, setForgotError] = useState(false)

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { errors: formErrors }
  } = useForm<FormData>()

  const onSubmit = handleSubmit(async (data) => {
    trigger()
    setForgotError(false)
    setIsLoading(true)
    try {
      await forgotPassword(data.email)
      setRequested(true)
    } catch (e) {
      setForgotError(true)
      clearErrors()
    } finally {
      setIsLoading(false)
    }
  })

  return (
    <LoginLayout>
      <form onSubmit={onSubmit}>
        <Card space="large">
          <Stack>
            <Heading size={1}>Forgot Password</Heading>
            <Input
              disabled={requested}
              type="email"
              id="email"
              label="Email address"
              placeholder="john.doe@mail.com"
              error={formErrors.email?.message}
              {...register("email", {
                required: true,
                pattern: {
                  value: REGEX_EMAIL,
                  message: "Email field must be a valid email address"
                }
              })}
            />
            {forgotError && (
              <Alert intent="negative">
                Failed to send a reset password link. The account may not exist. Please check the
                email address and/or try again later.
              </Alert>
            )}
            <Button
              intent="primary"
              disabled={requested}
              full
              onClick={onSubmit}
              isLoading={isLoading}
            >
              Get reset password link
            </Button>
            {!!requested && (
              <Alert intent="positive">
                If this email exists in our system, we sent you a password reset link which is valid
                for 5 minutes. If you do not get an email, please check your spam folder.
              </Alert>
            )}
            <Link to="/login">Go back to login</Link>
          </Stack>
        </Card>
      </form>
    </LoginLayout>
  )
}

export default ForgotPassword
