import React, { useCallback, useContext } from "react"
import styled from "styled-components"

import * as spacing from "../../styles/spacing"
import { inputHeights } from "../../styles/variables"
import { square } from "../../styles/helpers"

import Dropdown from "../Dropdown"
import Input from "../Input"
import Icon from "../Icon"
import Stack from "../Stack"
import { ShipmentLineItem } from "../../generated/graphql"
import { ShipmentsContext } from "../../context/ShipmentsContext"
import { AnomalyForm, ShipmentDetailsContext } from "../../context/ShipmentDetailsContext"
import { ZodIssue } from "zod"

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`

const Main = styled.main`
  flex: 1;
  margin-right: ${spacing.small};
`

const Row = styled.div`
  display: flex;

  > * {
    width: 100%;
  }
`

const Quantity = styled.div`
  margin-left: ${spacing.medium};
  width: 110px;
  flex-shrink: 0;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 22px; /* Align icon with field */
  ${square(inputHeights.small)}
`

type SelectChangeHandler = React.ChangeEventHandler<HTMLSelectElement>
type InputChangeHandler = React.ChangeEventHandler<HTMLInputElement>

export type AnomalyFieldArrayProps = {
  selectableShipmentLines: ShipmentLineItem[]
  maxQuantity: number
  anomaly: AnomalyForm
  formIssue?: ZodIssue
}

const AnomalyFieldArray = (props: AnomalyFieldArrayProps): JSX.Element => {
  const { anomaly, selectableShipmentLines } = props
  const { anomalyReasons } = useContext(ShipmentsContext)
  const { dispatchAnomalies } = useContext(ShipmentDetailsContext)

  const onLineSelected = useCallback<SelectChangeHandler>(
    (ev) => {
      const line = selectableShipmentLines.find((l) => l.product.sku === ev.target.value)
      if (line) {
        dispatchAnomalies({ type: "set_sku", id: anomaly.id, sku: line.product.sku })
      }
    },
    [anomaly, selectableShipmentLines, dispatchAnomalies]
  )

  const onReasonSelected = useCallback<SelectChangeHandler>(
    (ev) => {
      const reason = anomalyReasons.find((r) => r.reason_code === ev.target.value)
      if (reason) {
        dispatchAnomalies({
          type: "set_reason_code",
          id: anomaly.id,
          reason_code: reason.reason_code
        })
      }
    },
    [anomaly, anomalyReasons, dispatchAnomalies]
  )

  const onQuantityChanged = useCallback<InputChangeHandler>(
    (ev) => {
      dispatchAnomalies({
        type: "set_affected_qty",
        id: anomaly.id,
        affected_quantity_packs: parseInt(ev.target.value)
      })
    },
    [anomaly, dispatchAnomalies]
  )

  const onReasonDetailsChanged = useCallback<InputChangeHandler>(
    (ev) => {
      dispatchAnomalies({
        type: "set_reason_details",
        id: anomaly.id,
        reason_details: ev.target.value
      })
    },
    [anomaly, dispatchAnomalies]
  )

  const onRemove = useCallback(() => {
    dispatchAnomalies({ type: "remove", id: anomaly.id })
  }, [anomaly])

  return (
    <Container>
      <Main>
        <Stack space="medium">
          <Dropdown
            id="product"
            label="Product"
            placeholder="Select a product"
            customSize="small"
            value={anomaly.sku}
            onChange={onLineSelected}
            options={selectableShipmentLines.map((l) => {
              return {
                label: l.product.name,
                value: l.product.sku
              }
            })}
          />
          <Row>
            <Dropdown
              id="reason"
              label="Reason"
              placeholder="Select a reason"
              customSize="small"
              onChange={onReasonSelected}
              disabled={!anomaly.sku}
              value={anomaly.reason_code}
              options={anomalyReasons.map((r) => {
                return {
                  label: r.title,
                  value: r.reason_code
                }
              })}
            />
            <Quantity>
              <Input
                // TODO: (if needed), reenable this when dropdown error support is added
                // error={
                //   props.formIssue?.path[1] === "affected_quantity" ? props.formIssue.message : ""
                // }
                label="Affected packs"
                id="quantity_packs"
                type="number"
                name="quantity_packs"
                customSize="small"
                disabled={!anomaly.sku}
                onChange={onQuantityChanged}
                value={anomaly.affected_quantity_packs}
                max={props.maxQuantity}
                min={0}
              />
            </Quantity>
          </Row>
          {anomaly?.reason_code === "OTHER" && (
            <Row>
              <Input
                label="Reason Details"
                id="details"
                type="text"
                name="reason_details"
                value={anomaly.reason_details || ""}
                onChange={onReasonDetailsChanged}
              />
            </Row>
          )}
        </Stack>
      </Main>
      <Button onClick={onRemove}>
        <Icon icon="trash" size="small" />
      </Button>
    </Container>
  )
}

export default AnomalyFieldArray
