import React, { useContext, useMemo, useState } from "react"

import Drawer from "../Drawer"
import Button from "../Button"
import Inline from "../Inline"
import Stack from "../Stack"
import Card from "../Card"
import Product from "../Product"
import Avatar from "../Avatar"
import { OrderStatusType, ShippingRequestStatusType } from "../../generated/graphql"
import {
  OrderStatusAction,
  OrderStatusBadge,
  ShippingStatusAction,
  ShippingStatusBadge
} from "../Orders"
import { OrderTimeline } from "../Orders/OrderTimeline"
import RejectOrderModal from "../Modal/RejectOrderModal"
import { OrderDetailsContext } from "../../context/OrderDetailsContext"
import { useLastStatus } from "../../hooks/useLastStatus"

export type OrderDetailsProps = {
  onClose: () => void
}

const OrderDetails = (props: OrderDetailsProps): JSX.Element => {
  const { onClose } = props
  const { order } = useContext(OrderDetailsContext)
  const [rejectModalOpen, setRejectModalOpen] = useState(false)

  const lastShippingStatus = useLastStatus({
    statuses: order?.shipping_request?.statuses,
    defaultType: ShippingRequestStatusType.Created,
    overrides: {}
  })

  const lastOrderStatus = useLastStatus({
    statuses: order?.statuses,
    defaultType: OrderStatusType.NotAvailable,
    overrides: {
      Cancelled: OrderStatusType.Exception
    }
  })

  const canReject = useMemo(() => {
    const { shipping_request } = order || {}
    const candidate = shipping_request?.candidate
    if (candidate && !candidate.rejected_at) {
      return [ShippingRequestStatusType.Dispatched].includes(lastShippingStatus.type)
    }
    return [OrderStatusType.Accepted, OrderStatusType.Confirmed].includes(lastOrderStatus.type)
  }, [lastOrderStatus, lastShippingStatus])

  const customerName = useMemo(() => {
    if (!order?.customer) return "Not available"
    const { first_name, last_name } = order.customer
    return [first_name, last_name].join(" ")
  }, [order?.customer])

  const orderNumber = useMemo(() => {
    const { order_number } = order || {}
    if (!order_number) return "N/A"
    if (order_number.length > 10) return "-"
    return order_number
  }, [order?.order_number])

  return (
    <Drawer
      isOpen={!!order}
      onClose={onClose}
      heading={orderNumber}
      headingAddon={
        order &&
        (order.shipping_request ? (
          <ShippingStatusBadge {...order.shipping_request} />
        ) : (
          <OrderStatusBadge {...order} />
        ))
      }
      headerActions={
        <Inline>
          {canReject && (
            <Button size="small" intent="negative" onClick={() => setRejectModalOpen(true)}>
              Reject
            </Button>
          )}
          {order &&
            (order.shipping_request ? (
              <ShippingStatusAction size="small" shipping_request={order.shipping_request} />
            ) : (
              <OrderStatusAction size="small" order={order} />
            ))}
        </Inline>
      }
    >
      <Stack>
        {order && <OrderTimeline order={order} />}
        <Card heading={`Products (${order?.line_items.length})`} space="medium">
          <Stack space="medium" hasDivider>
            <Stack space="medium">
              {order?.line_items.map((line) => {
                return (
                  <Product
                    key={line.product.sku}
                    name={line.product.name}
                    sku={line.product.sku}
                    quantity={line.quantity}
                  />
                )
              })}
            </Stack>
          </Stack>
        </Card>
        <Card heading="Customer" space="medium">
          <Inline>
            <Avatar name={customerName} size="small" />
            {customerName}
          </Inline>
        </Card>
      </Stack>

      {/* Need to test modals for proper behaviour */}
      <RejectOrderModal isOpen={rejectModalOpen} onClose={() => setRejectModalOpen(false)} />
    </Drawer>
  )
}

export default OrderDetails
