import React from "react"
import styled, { css } from "styled-components"

import { borderColor } from "../styles/colors"
import * as spacing from "../styles/spacing"
import { lineHeights, fontWeights } from "../styles/typography"

import Heading from "./Heading"

type ContainerProps = {
  bottomAligned?: boolean
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${spacing.scale(spacing.medium, 1.25)};
  border-bottom: 1px solid ${borderColor};

  ${(props) =>
    props.bottomAligned &&
    css`
      align-items: flex-end;
    `}
`

const Title = styled(Heading)`
  margin-bottom: 0;
  line-height: ${lineHeights.base}; /* Make sure title is taller than buttons */
`

const Lead = styled.span`
  font-weight: ${fontWeights.book};
  display: block;
  margin-bottom: -${spacing.tiny}; /* Move closer to title */
`

export type PageHeaderProps = {
  heading: string
  lead?: string
  actions?: React.ReactElement[] | React.ReactElement
}

const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const { heading, lead, actions } = props

  return (
    <Container bottomAligned={!!lead}>
      <Title size={1}>
        {lead && <Lead>{lead}</Lead>}
        <span>{heading}</span>
      </Title>
      {actions && <div>{actions}</div>}
    </Container>
  )
}

export default PageHeader
