import React, { useCallback, useContext, useState } from "react"
import moment from "moment"
import { z, ZodError } from "zod"

import Drawer from "../Drawer"
import Button from "../Button"
import Inline from "../Inline"
import Stack from "../Stack"
import Card from "../Card"
import Product from "../Product"
import ShipmentStatusBadge from "../Shipments/ShipmentStatusBadge"
import { AnomalyForm } from "../Shipments/AnomalyForm"

import { ShipmentAnomalyReasonCode, ShipmentStatusType } from "../../generated/graphql"
import { ShipmentDetailsContext } from "../../context/ShipmentDetailsContext"
import { ShipmentsContext } from "../../context/ShipmentsContext"
import ConfirmShipmentModal from "../Modal/ConfirmShipmentModal"
import { useLastStatus } from "../../hooks/useLastStatus"
import ReportShipmentIssueModal from "../Modal/ReportShipmentIssueModal"

const AnomaliesSchema = z.array(
  z.object({
    id: z.string(),
    sku: z.string({
      required_error: "Please select a product.",
      invalid_type_error: "Please select a valid product."
    }),
    reason_code: z.nativeEnum(ShipmentAnomalyReasonCode, {
      errorMap: () => ({ message: "Please select a valid reason." })
    }),
    affected_quantity_packs: z
      .number({
        required_error: "Please select a valid quantity."
      })
      .min(1, "Please select a quantity greater than 0."),
    reason_details: z.string().optional()
  })
)

export type ShipmentDetailsProps = {
  onClose: () => void
}

const ShipmentDetails = (props: ShipmentDetailsProps): JSX.Element => {
  const { onClose } = props
  const shipmentsContext = useContext(ShipmentsContext)

  const { shipment, anomalies, dispatchAnomalies, canAddAnomaly } =
    useContext(ShipmentDetailsContext)

  const [formError, setFormError] = useState<ZodError<z.infer<typeof AnomaliesSchema>>>()
  const [showsIssues, setShowsIssues] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isReportModalOpen, setIsReportModalOpen] = useState(false)

  const lastStatus = useLastStatus({
    statuses: shipment?.statuses,
    defaultType: ShipmentStatusType.Pending,
    overrides: {}
  })

  const handleConfirm = () => {
    if (!shipment) return
    const result = AnomaliesSchema.safeParse(anomalies)
    if (!result.success) {
      return setFormError(result.error)
    }
    setIsConfirmModalOpen(true)
  }

  const onConfirmShipment = useCallback(async () => {
    if (!shipment) return
    const result = AnomaliesSchema.safeParse(anomalies)
    if (!result.success) {
      return setFormError(result.error)
    }

    await shipmentsContext.confirmShipment({
      variables: {
        input: {
          anomalies: result.data,
          delivery_no: shipment.delivery_no
        }
      }
    })

    shipmentsContext.refetch()
    onClose()
  }, [shipmentsContext.confirmShipment, shipment, anomalies])

  return (
    <Drawer
      isOpen={!!shipment}
      onClose={onClose}
      heading={shipment?.delivery_no || "N/A"}
      headingAddon={shipment && <ShipmentStatusBadge {...shipment} />}
      headerActions={
        <Inline>
          <span>
            {shipment?.delivery_date
              ? moment(shipment.delivery_date).format("MMM DD YYYY - hh:mm A")
              : null}
          </span>
          <Button size="small" intent="negative" onClick={() => setIsReportModalOpen(true)}>
            Report
          </Button>
        </Inline>
      }
    >
      <Stack>
        <Card heading={`Products (${shipment?.line_items.length})`} space="medium">
          <Stack space="medium">
            {shipment?.line_items.map((l) => {
              const { name, sku } = l.product
              return (
                <Product
                  key={l.id}
                  name={name}
                  sku={sku.toString()}
                  quantity={l.quantity_packs}
                  quantity_suffix={"packs"}
                />
              )
            })}
          </Stack>
        </Card>
        {showsIssues && (
          <Card heading="Issues" space="medium">
            <Stack space="medium" hasDivider>
              {anomalies.map((a, idx) => {
                const issue = formError?.issues.find((iss) => iss.path[0] === idx)
                return <AnomalyForm key={a.id} anomaly_id={a.id} formIssue={issue} />
              })}
              <Button
                size="small"
                disabled={!canAddAnomaly}
                onClick={() => dispatchAnomalies({ type: "create" })}
              >
                + New issue
              </Button>
            </Stack>
          </Card>
        )}
        {lastStatus.type !== ShipmentStatusType.Confirmed && (
          <Inline>
            <Button
              intent="primary"
              onClick={handleConfirm}
              isLoading={shipmentsContext.confirmShipmentData.loading}
            >
              Confirm shipment
            </Button>
            <Button
              intent="negative"
              disabled={showsIssues}
              onClick={() => setShowsIssues(true)}
              isLoading={shipmentsContext.confirmShipmentData.loading}
            >
              Report issues
            </Button>
          </Inline>
        )}
        {lastStatus.type === ShipmentStatusType.Confirmed && (
          <Card heading={`Issues (${shipment?.anomalies.length})`} space="medium">
            {!!shipment?.anomalies.length && (
              <Stack space="medium" hasDivider>
                {shipment?.anomalies.map((anomaly) => {
                  return (
                    <>
                      <Product
                        key={anomaly.id}
                        name={anomaly.product.name}
                        sku={anomaly.product.sku.toString()}
                        quantity={anomaly.affected_quantity_packs}
                        quantity_suffix={"packs"}
                        quantity_message={anomaly.reason_code}
                      />
                    </>
                  )
                })}
              </Stack>
            )}
          </Card>
        )}
      </Stack>
      <ConfirmShipmentModal
        totalIssues={anomalies.length}
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onCancel={() => {
          setIsConfirmModalOpen(false)
          setShowsIssues(true)
        }}
        onConfirm={() => {
          onConfirmShipment()
        }}
      />
      <ReportShipmentIssueModal
        shipment={shipment}
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
      />
    </Drawer>
  )
}

export default ShipmentDetails
