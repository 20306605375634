import React from "react"
import styled from "styled-components"

import * as spacing from "../styles/spacing"

export type InlineSpacing = "tiny" | "small" | "standard" | "large"

export type ContainerProps = {
  space: InlineSpacing
}

export type ItemProps = {
  space: InlineSpacing
}

export type InlineProps = {
  children: React.ReactNode
  space?: InlineSpacing
}

type InlineMarginValues = {
  [K in InlineSpacing]: string
}

const inlineMarginValues: InlineMarginValues = {
  tiny: spacing.tiny,
  small: spacing.small,
  standard: spacing.scale(spacing.small, 1.5),
  large: spacing.large
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  margin-left: -${(props) => inlineMarginValues[props.space]};
  margin-top: -${(props) => inlineMarginValues[props.space]};
`

const Item = styled.div<ItemProps>`
  padding-left: ${(props) => inlineMarginValues[props.space]};
  padding-top: ${(props) => inlineMarginValues[props.space]};
`

const Inline = (props: InlineProps): JSX.Element => {
  const { children, space = "standard" } = props

  return (
    <Container space={space}>
      {React.Children.map(children, (child, i) => (
        <Item space={space} key={i}>
          {child}
        </Item>
      ))}
    </Container>
  )
}

export default Inline
