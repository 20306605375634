import React, { useCallback, useState } from "react"
import Modal, { ModalProps } from "."
import { Shipment, useReportShipmentIssueMutation } from "../../generated/graphql"
import Button from "../Button"
import Inline from "../Inline"
import Input from "../Input"

export type ReportShipmentIssueModalProps = ModalProps & {
  shipment?: Shipment | null
}

const ReportShipmentIssueModal = (props: ReportShipmentIssueModalProps): JSX.Element => {
  const { isOpen, onClose, shipment } = props

  const [message, setMessage] = useState<string>("")
  const [formError, setFormError] = useState<string | null>(null)

  const [reportIssue, reportIssueResult] = useReportShipmentIssueMutation({})
  const handleReportIssue = useCallback(async () => {
    const delivery_no = shipment?.delivery_no
    if (!delivery_no) return

    if (!setMessage.length) {
      setFormError("You must provide a message")
      return
    } else {
      setFormError(null)
    }

    await reportIssue({ variables: { input: { delivery_no, message } } })
    onClose()
  }, [])

  return (
    <Modal
      heading={`Report Shipment '${shipment?.delivery_no}'`}
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <Inline>
          <Button intent="negative" onClick={handleReportIssue}>
            Submit Report
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </Inline>
      }
    >
      <p>
        Report an issue with this shipment. A message will be sent to the Click & Collect Agent.
      </p>
      <Input
        id="message"
        name="message"
        placeholder="e.g. Shipment never arrived"
        label="Message"
        type="text"
        value={message}
        onChange={(ev) => setMessage(ev.target.value)}
        disabled={reportIssueResult?.loading}
        error={formError}
      />
    </Modal>
  )
}

export default ReportShipmentIssueModal
