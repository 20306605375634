import React, { useCallback, useContext, useState } from "react"

import Modal, { ModalProps } from "./"
import { OrderDetailsContext } from "../../context/OrderDetailsContext"
import Button from "../Button"
import Inline from "../Inline"
import Input from "../Input"
import { OrdersContext } from "../../context/OrdersContext"
import { Order, ShippingRequest } from "../../generated/graphql"

export type RejectOrderModalProps = ModalProps

const useReject = (reason: string) => {
  const { rejectOrderMutation, rejectShippingRequestMutation } = useContext(OrderDetailsContext)

  const rejectOrder = useCallback(
    async (order: Order) => {
      return rejectOrderMutation[0]({
        variables: {
          input: {
            message: reason,
            order_no: order.order_number
          }
        }
      })
    },
    [reason, rejectOrderMutation[0]]
  )

  const rejectShippingRequest = useCallback(
    async (req: ShippingRequest) => {
      return rejectShippingRequestMutation[0]({
        variables: {
          input: {
            shipping_request_id: req.id,
            message: reason
          }
        }
      })
    },
    [reason, rejectShippingRequestMutation[0]]
  )

  return {
    rejectOrder,
    rejectShippingRequest,
    isLoading: rejectOrderMutation[1].loading || rejectShippingRequestMutation[1].loading
  }
}

const RejectOrderModal = (props: RejectOrderModalProps): JSX.Element => {
  const { isOpen, onClose } = props
  const { refetch } = useContext(OrdersContext)
  const { order } = useContext(OrderDetailsContext)

  const [reason, setReason] = useState<string>("")
  const [formError, setFormError] = useState<string | null>(null)

  const { rejectOrder, rejectShippingRequest, isLoading } = useReject(reason)

  const handleSubmit = async () => {
    const order_no = order?.order_number
    if (!order_no) return

    if (!reason.length) {
      setFormError("You must provide a reason")
      return
    } else {
      setFormError(null)
    }

    if (order.shipping_request) {
      await rejectShippingRequest(order.shipping_request)
    } else {
      await rejectOrder(order)
    }

    refetch()
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      heading={`Reject Order '${order?.order_number}'`}
      footer={
        <Inline>
          <Button disabled={isLoading} onClick={handleCancel}>
            Cancel
          </Button>
          <Button intent="negative" isLoading={isLoading} onClick={handleSubmit}>
            Submit
          </Button>
        </Inline>
      }
    >
      <p>The order will be rejected. You must provide a reason for rejecting this order.</p>
      <Input
        id="reason"
        name="reason"
        placeholder="e.g. Customer never showed"
        label="Reason for rejecting the order"
        type="text"
        value={reason}
        onChange={(ev) => setReason(ev.target.value)}
        disabled={isLoading}
        error={formError}
      />
    </Modal>
  )
}

export default RejectOrderModal
