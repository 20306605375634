import React from "react"
import styled from "styled-components"

import { white, borderColor, greyLight } from "../styles/colors"
import { borderRadius } from "../styles/variables"
import { square, cover } from "../styles/helpers"
import { fontSizes } from "../styles/typography"

import Stack from "./Stack"
import Inline from "./Inline"

export type ProductProps = {
  name: string
  sku: string
  quantity?: number
  quantity_suffix?: string
  quantity_message?: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${fontSizes.small};
`

const Sku = styled.span`
  font-size: ${fontSizes.tiny};
  color: ${greyLight};
`

const PhotoWrap = styled.div`
  position: relative;
  border-radius: ${borderRadius};
  background-color: ${white};
  ${square("32px")};

  &:after {
    content: "";
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};
    ${cover("absolute")};
  }
`

const Photo = styled.img`
  ${cover("absolute")};
  object-fit: cover;
`

const Product = (props: ProductProps): JSX.Element => {
  const { name, sku, quantity, quantity_suffix, quantity_message } = props
  return (
    <Container>
      <Inline>
        <PhotoWrap>
          <Photo src="https://cdn.shopify.com/s/files/1/3037/7072/products/20176_20139_VUSE_Pods_OnlineExclusivity_PackRender_FreshApple_FRONT.png?v=1628787538" />
        </PhotoWrap>
        <Stack space="none">
          <div>{name}</div>
          <Sku>{sku}</Sku>
        </Stack>
      </Inline>
      <Stack space="tiny">
        {quantity && (
          <div>
            × {quantity} {quantity_suffix}
          </div>
        )}
        {quantity_message && <div>{quantity_message}</div>}
      </Stack>
    </Container>
  )
}

export default Product
