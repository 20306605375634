import moment, { unitOfTime } from "moment"
import { useContext } from "react"
import { AppContext } from "../context/AppContext"

export type UseOrderTimeRemainingProps = {
  referenceDate: Date
  resolutionUnit: unitOfTime.Diff
}

export type UseOrderTimeRemaining = {
  timeDifference: number
  isPast: boolean
}

export const useTimeRemaining = (props: UseOrderTimeRemainingProps): UseOrderTimeRemaining => {
  const { referenceDate, resolutionUnit } = props
  const { clock } = useContext(AppContext)

  const timeDifference = moment(referenceDate).diff(moment(clock), resolutionUnit)
  const isPast = timeDifference <= 0

  return {
    timeDifference,
    isPast
  }
}
