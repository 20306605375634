import React, { Fragment } from "react"
import styled from "styled-components"

import * as spacing from "../styles/spacing"
import { borderColor } from "../styles/colors"

export type StackSpacing = "tiny" | "small" | "medium" | "standard" | "large" | "none"

export type ContainerProps = {
  space: StackSpacing
}

export type StackProps = {
  children: React.ReactNode
  space?: StackSpacing
  hasDivider?: boolean
}

type StackMarginValues = {
  [K in StackSpacing]: string
}

const stackMarginValues: StackMarginValues = {
  tiny: spacing.tiny,
  small: spacing.small,
  medium: spacing.medium,
  standard: spacing.large,
  large: spacing.xLarge,
  none: "0px"
}

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-gap: ${(props) => stackMarginValues[props.space]};
`

const Divider = styled.div`
  border-top: 1px solid ${borderColor};
`

const Stack = (props: StackProps): JSX.Element => {
  const { children, space = "standard", hasDivider } = props

  return (
    <Container space={space}>
      {React.Children.map(children, (child, i) => {
        if (!child) {
          return null
        }
        return (
          <Fragment key={i}>
            <div>{child}</div>
            {hasDivider && i !== React.Children.count(children) - 1 && <Divider />}
          </Fragment>
        )
      })}
    </Container>
  )
}

export default Stack
