import React, { useContext, useEffect } from "react"
import { useForm } from "react-hook-form"

import { AppContext } from "../../context/AppContext"
import { useFormattedRetailerAddress } from "../../hooks/useFormattedRetailerAddress"
import { REGEX_EMAIL } from "../../utils/regex"

import Button from "../Button"
import Card from "../Card"
import { Column, Columns } from "../Column"
import Input from "../Input"
import ProfileCard from "../ProfileCard"
import Stack from "../Stack"
import Text from "../Text"

export const General = (): JSX.Element => {
  const { currentUser } = useContext(AppContext)
  const formattedAddress = useFormattedRetailerAddress(currentUser?.retailer)

  const { register, reset } = useForm({
    defaultValues: {
      email: currentUser?.email,
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name
    }
  })

  useEffect(() => {
    if (currentUser)
      reset({
        email: currentUser.email,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name
      })
  }, [currentUser])

  return (
    <Stack>
      <ProfileCard
        store={currentUser?.retailer?.name || ""}
        erp={currentUser?.retailer?.storage_location_id || ""}
        address={formattedAddress}
      />
      <Card>
        <Stack>
          {/* <Alert intent="warning">There is currently a change request pending</Alert> */}
          <Columns>
            <Column width="1/2">
              <Input
                disabled={true}
                label="First Name"
                placeholder="John"
                id="first_name"
                type="text"
                {...register("first_name")}
              />
            </Column>
            <Column width="1/2">
              <Input
                disabled={true}
                label="Last Name"
                placeholder="Doe"
                id="last_name"
                type="text"
                {...register("last_name")}
              />
            </Column>
          </Columns>
          <Columns>
            <Column width="1/2">
              <Input
                disabled={true}
                label="Email address"
                placeholder="john@example.com"
                id="email"
                type="email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: REGEX_EMAIL,
                    message: "Email field must be a valid email address."
                  }
                })}
              />
            </Column>
            <Column width="1/2">
              <Input
                disabled={true}
                label="Phone Number"
                placeholder="123-456-7890"
                id="phone"
                type="tel"
                name="phone_number"
                // {...register("phone_number")}
              />
            </Column>
          </Columns>
          <Stack space="medium">
            <Button intent="primary" disabled>
              Submit change request
            </Button>
            <Text size="small" intent="muted">
              Your change request will be reviewed within 48 hours.
            </Text>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  )
}
