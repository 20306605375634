import React, {
  Dispatch,
  Provider,
  Reducer,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from "react"
import { v4 } from "uuid"
import update from "immutability-helper"

import { Shipment, ShipmentAnomalyReasonCode } from "../generated/graphql"
import { BaseProviderProps } from "./types"
import { ShipmentsContext } from "./ShipmentsContext"

export type AnomalyForm = {
  id: string
  affected_quantity_packs: number
  sku?: string
  reason_code?: ShipmentAnomalyReasonCode
  reason_details?: string
}

type ShipmentAnomalies = AnomalyForm[]

export type AnomaliesReducerAction =
  | { type: "create" }
  | { type: "remove"; id: string }
  | { type: "set_sku"; id: string; sku: string }
  | { type: "set_reason_code"; id: string; reason_code: ShipmentAnomalyReasonCode }
  | { type: "set_affected_qty"; id: string; affected_quantity_packs: number }
  | { type: "set_reason_details"; id: string; reason_details: string }
  | { type: "clear" }

const ANOMALIES_REDUCER: Reducer<ShipmentAnomalies, AnomaliesReducerAction> = (state, action) => {
  const { type } = action
  if (type === "create") {
    return [...state, { id: v4(), affected_quantity_packs: 0 }]
  }
  if (type === "clear") {
    return []
  }

  const index = state.findIndex((anomaly) => anomaly.id === action.id)
  if (index < 0) return state

  switch (type) {
    case "remove": {
      return state.filter((a) => a.id !== action.id)
    }
    case "set_sku": {
      const { sku } = action
      return update(state, { [index]: { $merge: { sku } } })
    }
    case "set_reason_code": {
      const { reason_code } = action
      return update(state, { [index]: { $merge: { reason_code } } })
    }
    case "set_affected_qty": {
      const { affected_quantity_packs } = action
      return update(state, { [index]: { $merge: { affected_quantity_packs } } })
    }
    case "set_reason_details": {
      const { reason_details } = action
      return update(state, { [index]: { $merge: { reason_details } } })
    }
    default:
      return state
  }
}

export interface ShipmentDetailsContext {
  shipment: Shipment | null
  anomalies: ShipmentAnomalies
  dispatchAnomalies: Dispatch<AnomaliesReducerAction>
  canAddAnomaly: boolean
}

const DEFAULT_VALUE: ShipmentDetailsContext = {
  shipment: null,
  anomalies: [],
  dispatchAnomalies: () => [],
  canAddAnomaly: false
}

export const ShipmentDetailsContext = React.createContext(DEFAULT_VALUE)

export type ShipmentDetailsContextProviderProps = BaseProviderProps & {
  delivery_no?: string | null
}

type ProviderType = React.ReactElement<
  ShipmentDetailsContextProviderProps,
  Provider<ShipmentDetailsContext>
>

export const ShipmentDetailsContextProvider = (
  props: ShipmentDetailsContextProviderProps
): ProviderType => {
  const { delivery_no } = props
  const { shipments } = useContext(ShipmentsContext)

  const [anomalies, dispatchAnomalies] = useReducer(ANOMALIES_REDUCER, [])

  const selectedShipment = useMemo(() => {
    if (!delivery_no) return null
    return shipments.find((shipm) => shipm.delivery_no === delivery_no) || null
  }, [shipments, delivery_no])

  const canAddAnomaly = useMemo(() => {
    return !anomalies.length || !!anomalies[anomalies.length - 1].sku
  }, [anomalies])

  useEffect(() => {
    if (!selectedShipment) {
      dispatchAnomalies({ type: "clear" })
    }
  }, [selectedShipment, dispatchAnomalies])

  const value = {
    shipment: selectedShipment,
    anomalies,
    dispatchAnomalies,
    canAddAnomaly
  }

  return (
    <ShipmentDetailsContext.Provider value={value}>
      {props.children}
    </ShipmentDetailsContext.Provider>
  )
}
