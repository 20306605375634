import { createGlobalStyle } from "styled-components"

import Normalize from "./normalize"
import Typography from "./typography"
import { offWhite } from "./colors"

export const GlobalStyle = createGlobalStyle`
  ${Normalize};
  ${Typography};

  body {
    background-color: ${offWhite};
  }
`
