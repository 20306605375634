import React from "react"
import styled, { css } from "styled-components"

import { greyDark } from "../styles/colors"
import { fontSizes, fontWeights, lineHeights } from "../styles/typography"

type HeadingSize = 1 | 2 | 3 | 4 | 5

type HeadingProps = React.ComponentPropsWithoutRef<"div"> & {
  size?: HeadingSize
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "div"
  children: React.ReactNode
}

const getFontSize = ({ size }: { size: HeadingSize }) => {
  if (size === 1) return "24px"
  if (size === 2) return "20px"
  if (size === 3) return "18px"
  if (size === 4) return "16px"
  if (size === 5) return fontSizes.tiny
  return "20px"
}

const Container = styled.div<{ size: HeadingSize }>`
  display: block;
  font-weight: ${fontWeights.bold};
  font-size: ${getFontSize};
  line-height: ${lineHeights.heading};
  color: ${greyDark};
  margin-bottom: 0.25em;

  ${(props) =>
    props.size === 5 &&
    css`
      letter-spacing: 0.03em;
      text-transform: uppercase;
    `}

  &:last-child {
    margin-bottom: 0;
  }
`

const Heading = ({ size = 2, children, tag, ...rest }: HeadingProps): JSX.Element => (
  <Container as={tag || `h${size}`} size={size} {...rest}>
    {children}
  </Container>
)

export default Heading
