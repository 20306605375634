import React, { useCallback, useContext, useMemo } from "react"
import { OrdersContext } from "../../context/OrdersContext"
import {
  ShippingRequest,
  ShippingRequestStatusChangeInput,
  ShippingRequestStatusType
} from "../../generated/graphql"
import { useLastStatus } from "../../hooks/useLastStatus"
import Button, { ButtonSize } from "../Button"

export enum ShippingAction {
  Accept_Order = "accept_order",
  Mark_As_Ready = "mark_as_ready",
  Mark_As_Collected = "mark_as_collected"
}

const ACTION_TITLES: {
  [Action in ShippingAction]: string
} = {
  [ShippingAction.Accept_Order]: "Accept order",
  [ShippingAction.Mark_As_Ready]: "Ready For Pickup",
  [ShippingAction.Mark_As_Collected]: "Mark as Collected"
}

const ACTIONABLE_STATUSES = [
  ShippingRequestStatusType.Dispatched,
  ShippingRequestStatusType.Accepted,
  ShippingRequestStatusType.ReadyForPickup
]

const ACTIONS_BY_STATUS: {
  [Status in ShippingRequestStatusType]?: ShippingAction
} = {
  [ShippingRequestStatusType.Dispatched]: ShippingAction.Accept_Order,
  [ShippingRequestStatusType.Accepted]: ShippingAction.Mark_As_Ready,
  [ShippingRequestStatusType.ReadyForPickup]: ShippingAction.Mark_As_Collected
}
const useShippingAction = (shippingRequest: ShippingRequest) => {
  const {
    acceptShippingMutation,
    markShippingReadyMutation,
    markShippingCollectedMutation,
    refetch
  } = useContext(OrdersContext)

  const [acceptShipping, acceptShippingData] = acceptShippingMutation
  const [markShippingReady, markShippingReadyData] = markShippingReadyMutation
  const [markShippingCollected, markShippingCollectedData] = markShippingCollectedMutation

  const status = useLastStatus({
    statuses: shippingRequest?.statuses,
    applicableStatuses: ACTIONABLE_STATUSES,
    defaultType: ShippingRequestStatusType.Dispatched,
    overrides: {}
  })

  const action = useMemo(() => {
    if (shippingRequest.candidate?.rejected_at) return null
    return ACTIONS_BY_STATUS[status.type]
  }, [status, shippingRequest.candidate])

  const onClick = useCallback(async () => {
    if (!action) return
    const input: ShippingRequestStatusChangeInput = {
      shipping_request_id: shippingRequest.id
    }
    switch (action) {
      case ShippingAction.Accept_Order:
        await acceptShipping({ variables: { input } })
        break
      case ShippingAction.Mark_As_Ready:
        await markShippingReady({ variables: { input } })
        break
      case ShippingAction.Mark_As_Collected:
        await markShippingCollected({ variables: { input } })
        break
      default:
        return
    }

    refetch()
  }, [action, refetch, acceptShipping, markShippingReady, shippingRequest])

  const loading = useMemo(() => {
    return (
      acceptShippingData.loading ||
      markShippingReadyData.loading ||
      markShippingCollectedData.loading
    )
  }, [acceptShippingData.loading, markShippingReadyData.loading, markShippingCollectedData.loading])

  if (!action) return null
  const title = ACTION_TITLES[action]
  return {
    action,
    title,
    onClick,
    loading
  }
}

export type ShippingStatusActionProps = {
  shipping_request: ShippingRequest
  size: ButtonSize
}

export const ShippingStatusAction = (props: ShippingStatusActionProps): JSX.Element | null => {
  const action = useShippingAction(props.shipping_request)

  if (!action) return null

  return (
    <Button
      size={props.size}
      intent="primary"
      isLoading={action.loading}
      onClick={(e) => {
        e.stopPropagation()
        action.onClick()
      }}
    >
      {action.title}
    </Button>
  )
}

export default ShippingStatusAction
