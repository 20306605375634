import React, { useContext, useMemo, useState } from "react"
import { Link, Redirect } from "react-router-dom"
import { useForm } from "react-hook-form"

import Input from "../components/Input"
import Card from "../components/Card"
import LoginLayout from "../layouts/LoginLayout"
import Button from "../components/Button"
import Stack from "../components/Stack"
import Heading from "../components/Heading"
import { AuthContext } from "../context/AuthContext"
import Alert from "../components/Alert"

type FormData = {
  password: string
  passwordConfirmation: string
}

const ResetPassword = (): JSX.Element => {
  const { resetPassword } = useContext(AuthContext)
  const [resetError, setResetError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const token = useMemo(() => {
    const search = new URLSearchParams(window.location.search)
    return search.get("token")
  }, [window.location.search])

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    reset,
    watch,
    formState: { errors: formErrors }
  } = useForm<FormData>()

  const onSubmit = handleSubmit(async (data) => {
    trigger()
    setIsLoading(true)
    if (!token) return
    try {
      await resetPassword({ password: data.password, token })
      setSuccess(true)
    } catch (e) {
      setResetError(true)
      clearErrors()
    } finally {
      setIsLoading(false)
      reset()
    }
  })

  if (!token) {
    return <Redirect to="/forgot" />
  }

  return (
    <LoginLayout>
      <form onSubmit={onSubmit}>
        <Card space="large">
          <Stack>
            <Heading size={1}>Reset Password</Heading>
            {!success && !resetError && (
              <Stack>
                <Input
                  type="password"
                  id="password"
                  label="New Password"
                  error={formErrors.password?.message}
                  placeholder="••••••••••"
                  {...register("password", {
                    required: "Password is required",
                    minLength: { value: 8, message: "Password must be at least 8 characters long" },
                    maxLength: { value: 64, message: "Password must less than 64 characters long" }
                  })}
                />
                <Input
                  type="password"
                  id="passwordConfirmation"
                  label="Confirm New Password"
                  error={formErrors.passwordConfirmation?.message}
                  placeholder="••••••••••"
                  {...register("passwordConfirmation", {
                    required: "Password confirmation is required",
                    validate: (value) => value === watch("password") || "Passwords do not match"
                  })}
                />
              </Stack>
            )}
            {!!resetError && (
              <>
                <Alert intent="negative">
                  Failed to reset password, the link may have expired.
                </Alert>
              </>
            )}
            {!resetError && !success && (
              <Button intent="primary" full onClick={onSubmit} isLoading={isLoading}>
                Reset Password
              </Button>
            )}
            {success && (
              <Alert intent="positive">
                You have sucessfully reset your password. You may now login
              </Alert>
            )}
            <Link to="/login">Go to login</Link>
          </Stack>
        </Card>
      </form>
    </LoginLayout>
  )
}

export default ResetPassword
