import React, { useContext, useMemo } from "react"

import { TabNavRouter, TabRouteItem } from "../components/TabNav"
import PageHeader from "../components/PageHeader"
import Button from "../components/Button"
import Stack from "../components/Stack"
import Inline from "../components/Inline"
import { AuthContext } from "../context/AuthContext"
import { General, Notifications, NotificationsDisabled, Hours } from "../components/Settings"
import { useSettingsPaths } from "../hooks/useSettingsPaths"

export type SettingsTab = "general" | "notifications" | "hours" | "security"
export type SettingsTabItem = TabRouteItem<SettingsTab>

const useSettingsTabs = (): SettingsTabItem[] => {
  const settingsPaths = useSettingsPaths("/dashboard")

  const notificationsSupported = useMemo(() => {
    if (window.Notification) {
      return true
    }

    return false
  }, [])

  const tabs = useMemo((): SettingsTabItem[] => {
    return [
      {
        id: "general",
        label: "General",
        to: settingsPaths.general,
        component: General,
        isDefault: true
      },
      {
        id: "notifications",
        label: "Notifications",
        to: settingsPaths.notifications,
        component: notificationsSupported ? Notifications : NotificationsDisabled
      },
      {
        id: "hours",
        label: "Hours",
        to: settingsPaths.hours,
        component: Hours
      }
      // {
      //   id: "security",
      //   label: "Security",
      //   to: settingsPaths.security,
      //   component: Security
      // }
    ]
  }, [settingsPaths])

  return tabs
}

const Settings = (): JSX.Element => {
  const { logout } = useContext(AuthContext)

  const tabs = useSettingsTabs()

  return (
    <Stack>
      <PageHeader
        heading="Settings"
        actions={
          <Inline>
            <Button size="small" onClick={() => logout({ userInitiated: true })}>
              Logout
            </Button>
          </Inline>
        }
      />
      <TabNavRouter tabs={tabs} handleSpacing />
    </Stack>
  )
}

export default Settings
