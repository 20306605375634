import { range } from "lodash"
import { v4 } from "uuid"
import { OpeningHoursStateEntry } from "./types"

const DEFAULT_START_TIME = "10:00:00"
const DEFAULT_DURATION = 9 * 60

const DEFAULT_WEEKLY_ENTRY = (day_of_week: number): OpeningHoursStateEntry => {
  return {
    _id: v4(),
    day_of_week,
    day_of_month: -1,
    month: -1,
    year: -1,
    start_time: DEFAULT_START_TIME,
    duration_minutes: DEFAULT_DURATION,
    closed: true
  }
}

export const DEFAULT_ENTRY_BASE = (): OpeningHoursStateEntry => {
  return {
    _id: v4(),
    day_of_week: -1,
    day_of_month: -1,
    month: -1,
    year: -1,
    start_time: DEFAULT_START_TIME,
    duration_minutes: DEFAULT_DURATION,
    closed: true
  }
}

export const WEEK_SEQUENCE = range(0, 7).map((i) => (i + 1) % 7)
export const DEFAULT_OPENING_HOURS: OpeningHoursStateEntry[] = WEEK_SEQUENCE.map((day_of_week) => {
  return DEFAULT_WEEKLY_ENTRY(day_of_week)
})
