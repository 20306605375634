import React, { useContext, useMemo } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { AppContext } from "../../context/AppContext"
import { useDashboardPaths } from "../../hooks/useDashboardPaths"

import { greyDark, white } from "../../styles/colors"
import * as spacing from "../../styles/spacing"
import { navWidth } from "../../styles/variables"

import NavItem from "./NavItem"
import Avatar from "../Avatar"
import Heading from "../Heading"

const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: ${navWidth};
  height: 100%;
  background-color: ${greyDark};
  padding: ${spacing.large} 0;
  display: flex;
  align-items: center;
  justify-content: flex-between;
  flex-direction: column;
`

const LogoWrap = styled(Link)`
  display: flex;
  margin-bottom: ${spacing.xxLarge};
`

const Logo = styled(Heading)`
  color: ${white};
  width: 100%;
  text-align: center;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
`

const User = styled(Link)`
  display: flex;
`

const Nav = (): JSX.Element => {
  const paths = useDashboardPaths("/dashboard")
  const { currentUser, orderCounts } = useContext(AppContext)
  const fullname = [currentUser?.first_name, currentUser?.last_name].filter((x) => !!x).join(" ")

  const orderBadge = useMemo(() => {
    if (!orderCounts) return null
    const totalCount = orderCounts.reduce((acc, val) => {
      acc += val.count
      return acc
    }, 0)

    if (!totalCount) return null
    return totalCount
  }, [orderCounts])

  return (
    <Container>
      <LogoWrap to={paths.home}>
        <Logo>C&C</Logo>
      </LogoWrap>
      <List>
        <NavItem to={paths.home} label="Home" icon="house" />
        <NavItem to={paths.orders} label="Orders" icon="cart" count={orderBadge} />
        <NavItem to={paths.shipments} label="Shipments" icon="shipment" />
        <NavItem to={paths.inventory} label="Inventory" icon="inventory" />
        <NavItem to={paths.settings} label="Settings" icon="settings" />
        <NavItem to={paths.help} label="Help" icon="question" />
      </List>
      <User to={paths.settings}>
        <Avatar name={fullname} customColor={white} />
      </User>
    </Container>
  )
}

export default Nav
