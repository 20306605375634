import React, { useCallback, useContext, useMemo } from "react"
import { OrdersContext } from "../../context/OrdersContext"
import { Order, OrderStatusType } from "../../generated/graphql"
import { useLastStatus } from "../../hooks/useLastStatus"
import Button, { ButtonSize } from "../Button"

export enum OrderAction {
  Accept_Order = "accept_order",
  Mark_As_Picked = "mark_as_picked",
  Mark_As_Collected = "mark_as_collected"
}

const ACTION_TITLES: {
  [Action in OrderAction]: string
} = {
  [OrderAction.Accept_Order]: "Accept order",
  [OrderAction.Mark_As_Picked]: "Ready For Pickup",
  [OrderAction.Mark_As_Collected]: "Mark as collected"
}

const ACTIONS_BY_STATUS: {
  [Status in OrderStatusType]?: OrderAction
} = {
  [OrderStatusType.Confirmed]: OrderAction.Accept_Order,
  [OrderStatusType.Accepted]: OrderAction.Mark_As_Picked,
  [OrderStatusType.Picked]: OrderAction.Mark_As_Collected
}

const NEXT_STATUS_FOR_ACTION: {
  [Action in OrderAction]?: OrderStatusType
} = {
  [OrderAction.Accept_Order]: OrderStatusType.Accepted,
  [OrderAction.Mark_As_Picked]: OrderStatusType.Picked,
  [OrderAction.Mark_As_Collected]: OrderStatusType.Collected
}

const useOrderAction = (order: Order) => {
  const { updateOrderStatusMutation, refetch } = useContext(OrdersContext)

  const [updateOrderStatus, updateOrderStatusData] = updateOrderStatusMutation

  const status = useLastStatus({
    statuses: order.statuses,
    defaultType: OrderStatusType.NotAvailable,
    overrides: {}
  })

  const action = useMemo(() => ACTIONS_BY_STATUS[status.type], [status])

  const onClick = useCallback(async () => {
    if (!action) return
    const nextStatus = NEXT_STATUS_FOR_ACTION[action]
    if (!nextStatus) return
    await updateOrderStatus({
      variables: {
        input: {
          order_no: order.order_number,
          status: nextStatus
        }
      }
    })

    refetch()
  }, [action, refetch, order, action, updateOrderStatus])

  if (!action) return null
  const title = ACTION_TITLES[action]
  return {
    action,
    title,
    onClick,
    loading: updateOrderStatusData.loading
  }
}

export type OrderStatusActionProps = {
  order: Order
  size: ButtonSize
}

export const OrderStatusAction = (props: OrderStatusActionProps): JSX.Element | null => {
  const action = useOrderAction(props.order)

  if (!action) return null

  return (
    <Button
      size={props.size}
      intent="primary"
      isLoading={action.loading}
      onClick={(e) => {
        e.stopPropagation()
        action.onClick()
      }}
    >
      {action.title}
    </Button>
  )
}

export default OrderStatusAction
