import React from "react"
import moment from "moment"
import Stat, { StatProps } from "./Stat"
import { Stat as StatData, StatKind, StatUnit } from "../../generated/graphql"

export type FormattedStatProps = {
  stat: StatData
}

const StatTitles: {
  [K in StatKind]: string
} = {
  AVG_RESPONSE: "Avg response time",
  TIME_TO_COLLECT: "Time to collect",
  TOTAL_ORDERS: "Total orders",
  FILL_RATE: "Fill Rate"
}

const formatStatValue = (stat: StatData): string => {
  if (!stat.value || stat.value < 0) return `N/A`

  switch (stat.unit) {
    case StatUnit.Currency:
      return `$${(stat.value || 0) / 100}`
    case StatUnit.Quantity:
      return `${stat.value}`
    case StatUnit.Seconds:
      return moment.duration((stat.value || 0) * 1000).humanize()
    case StatUnit.Percentage:
      return `${stat.value.toFixed(0)}%`
  }
}

const isInverted = (stat: StatData): boolean => {
  switch (stat.kind) {
    case StatKind.TimeToCollect:
    case StatKind.AvgResponse:
      return true
    default:
      return false
  }
}

const useFormattedStat = (data: StatData): StatProps => {
  return {
    label: StatTitles[data.kind],
    value: formatStatValue(data),
    inverted: isInverted(data),
    direction: (data.change || 0) >= 0 ? "up" : "down",
    change: `${Math.abs(Math.round((data.change || 0) * 100))}%`
  }
}

const FormattedStat = (props: FormattedStatProps): JSX.Element => {
  const { label, value, direction, change, inverted } = useFormattedStat(props.stat)

  return (
    <Stat label={label} value={value} direction={direction} inverted={inverted} change={change} />
  )
}

export default FormattedStat
