import React from "react"
import styled, { css } from "styled-components"
import { range } from "lodash"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { rgba } from "polished"

import * as spacing from "../styles/spacing"
import { borderColor, greyDark, greyLight, white, offWhite } from "../styles/colors"
import { boxShadow, square } from "../styles/helpers"
import { borderRadius, animationTime, animationCurve } from "../styles/variables"
import { fontSizes, fontWeights } from "../styles/typography"

import Stack from "./Stack"
import Icon from "./Icon"

export type RowProps = {
  isHoverable?: boolean
}

const Container = styled.div`
  background-color: ${white};
  border-radius: ${borderRadius};
  border: 1px solid ${borderColor};
  overflow: hidden;
  ${boxShadow.small};
`

const Header = styled.header`
  padding: ${spacing.medium};
  border-bottom: 1px solid ${borderColor};
`

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    text-align: left;
    border-bottom: 1px solid ${borderColor};
    padding: ${spacing.scale(spacing.small, 1.5)} ${spacing.medium};
  }

  th {
    color: ${greyDark};
    font-size: ${fontSizes.small};
    font-weight: ${fontWeights.semibold};
  }

  tr:last-child td {
    border-bottom: 0;
  }
`

const Row = styled.tr<RowProps>`
  transition: background-color ${animationTime} ${animationCurve};

  ${(props) =>
    props.isHoverable &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${offWhite};
      }
    `}
`

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(borderColor, 0.5)};
  border-radius: 50%;
  ${square("24px")};
`

const EmptyState = styled.div`
  text-align: center;
  padding: ${spacing.large};
  color: ${greyLight};
`

export type DataTableRenderColumn<ItemType extends DataTableItem> = (
  item: ItemType
) => React.ReactNode

export interface DataTableColumnConfig<ItemType extends DataTableItem> {
  title: string
  render: DataTableRenderColumn<ItemType>
}

export interface DataTableItem {
  id: string | number
}

export interface DataTableRowProps<ItemType extends DataTableItem> {
  item: ItemType
  columns: DataTableColumnConfig<ItemType>[]
  isHoverable?: boolean
  onRowSelected?: (item: ItemType) => void
}

function DataTableRow<ItemType extends DataTableItem>(
  props: DataTableRowProps<ItemType>
): JSX.Element {
  const { item, columns, onRowSelected, isHoverable } = props

  return (
    <Row
      isHoverable={isHoverable}
      onClick={() => {
        if (onRowSelected) onRowSelected(item)
      }}
    >
      {columns.map((config) => {
        const { title, render } = config
        return <td key={title.toString()}>{render(item)}</td>
      })}
      {props.isHoverable && (
        <td>
          <IconWrap>
            <Icon icon="chevron-right" size="small" />
          </IconWrap>
        </td>
      )}
    </Row>
  )
}

export const LoadingRows = (props: { count: number; isHoverable?: boolean }): JSX.Element => {
  const { count, isHoverable } = props
  return (
    <tbody>
      {range(5).map((index) => {
        return (
          <tr key={index}>
            {range(count + (isHoverable ? 1 : 0)).map((index1) => {
              return <td key={index1}>{index1 !== count && <Skeleton />}</td>
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

export interface DataTableProps<ItemType extends DataTableItem> {
  header?: React.ReactNode
  isLoading?: boolean
  items: ItemType[]
  columns: DataTableColumnConfig<ItemType>[]
  isHoverable?: boolean
  onRowSelected?: (item: ItemType) => void
}

function DataTable<ItemType extends DataTableItem>(props: DataTableProps<ItemType>): JSX.Element {
  const { header, columns, items, isHoverable, onRowSelected, isLoading } = props

  return (
    <Stack space="medium">
      <Container>
        {header && <Header>{header}</Header>}
        <div>
          <Table>
            {/* Render table head */}
            <thead>
              <tr>
                {/* Render column headers */}
                {columns.map((config) => {
                  const { title } = config
                  return <th key={title.toString()}>{title}</th>
                })}
                {/* Render placeholder header for discolure indicator */}
                {isHoverable && <th />}
              </tr>
            </thead>
            {/* Render body */}
            {!isLoading && (
              <tbody>
                {!items.length && (
                  <tr>
                    <td colSpan={isHoverable ? columns.length + 1 : columns.length}>
                      <EmptyState>No results</EmptyState>
                    </td>
                  </tr>
                )}
                {items.map((item) => (
                  <DataTableRow
                    key={item.id}
                    item={item}
                    columns={columns}
                    isHoverable={isHoverable}
                    onRowSelected={onRowSelected}
                  />
                ))}
              </tbody>
            )}
            {!!isLoading && <LoadingRows count={columns.length} isHoverable={isHoverable} />}
          </Table>
        </div>
      </Container>
    </Stack>
  )
}

export default DataTable
