import { useMemo } from "react"
import { GetCurrentUserQuery } from "../generated/graphql"
import { getDayPeriod } from "../utils/date"

export type UseGreetingsParams = {
  currentUser?: GetCurrentUserQuery["me"]
}

export type Greetings = {
  userGreeting: string
  storeGreeting: string
}

export const useGreetings = (params: UseGreetingsParams): Greetings => {
  const tod = getDayPeriod(new Date())

  const userGreeting = useMemo(() => {
    const greetingBase = `Good ${tod}`
    return [greetingBase, params.currentUser?.first_name].filter((x) => !!x).join(", ")
  }, [params.currentUser, tod])

  const storeGreeting = useMemo(() => {
    return `Here's what's happening with ${params.currentUser?.retailer?.name || "your store"}`
  }, [params.currentUser])

  return { userGreeting, storeGreeting }
}
