import React from "react"
import { ShippingRequest, ShippingRequestStatusType } from "../../generated/graphql"
import { useLastStatus } from "../../hooks/useLastStatus"
import Badge, { BadgeIntent } from "../Badge"

const SHIPPING_STATUS_TITLES: {
  [Status in ShippingRequestStatusType]: string
} = {
  Created: "Pending",
  ReadyForDispatch: "Pending",
  Dispatched: "Pending",
  Accepted: "Accepted",
  ReadyForPickup: "Ready for Pickup",
  SentToShipper: "Sent to shipper", // hide this status from end user
  Collected: "Collected",
  Delivered: "Delivered",
  Failed: "Failed",
  CancellationRequested: "Cancellation Requested",
  Cancelled: "Cancelled",
  ShipperAccepted: "Accepted by shipper",
  ShipperCancelled: "Courier Cancelled",
  ShipperInTransit: "In Transit",
  ShipperOnHold: "Courier Hold"
}

const SHIPPING_STATUS_INTENTS: {
  [Status in ShippingRequestStatusType]: BadgeIntent
} = {
  Created: "neutral",
  ReadyForDispatch: "neutral",
  Dispatched: "warning",
  Accepted: "warning",
  ReadyForPickup: "neutral",
  SentToShipper: "neutral",
  Collected: "neutral",
  Delivered: "positive",
  Failed: "negative",
  CancellationRequested: "warning",
  Cancelled: "negative",
  ShipperAccepted: "neutral",
  ShipperCancelled: "neutral",
  ShipperInTransit: "neutral",
  ShipperOnHold: "neutral"
}
export const ShippingStatusBadge = (shipping: ShippingRequest): JSX.Element => {
  const { candidate } = shipping

  const status = useLastStatus({
    statuses: shipping.statuses,
    defaultType: ShippingRequestStatusType.Dispatched,
    overrides: {}
  })

  const statusType = candidate?.rejected_at ? ShippingRequestStatusType.Cancelled : status.type

  const title = SHIPPING_STATUS_TITLES[statusType]
  const intent = SHIPPING_STATUS_INTENTS[statusType]
  return <Badge intent={intent}>{title}</Badge>
}
