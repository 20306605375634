import styled from "styled-components"

import { fontWeights, fontSizes } from "../styles/typography"

export default styled.span`
  text-transform: uppercase;
  font-size: ${fontSizes.tiny};
  font-weight: ${fontWeights.bold};
  letter-spacing: 0.03em;
`
