import React from "react"
import styled, { css } from "styled-components"
import { readableColor } from "polished"

import { greyDark, white } from "../styles/colors"
import { inputHeights } from "../styles/variables"
import { fontSizes, fontWeights } from "../styles/typography"

export type AvatarSize = "standard" | "small"

export type ContainerProps = {
  size: AvatarSize
  color?: string
}

export type AvatarProps = {
  name: string
  size?: AvatarSize
  customColor?: string
}

type AvatarSizeValues = {
  [K in AvatarSize]: {
    height: string
    fontSize: string
  }
}

const avatarSizeValues: AvatarSizeValues = {
  standard: { height: inputHeights.standard, fontSize: fontSizes.small }, // 40px
  small: { height: inputHeights.small, fontSize: fontSizes.tiny } // 32px
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: ${(props) => avatarSizeValues[props.size].fontSize};
  width: ${(props) => avatarSizeValues[props.size].height};
  height: ${(props) => avatarSizeValues[props.size].height};
  background-color: ${greyDark};
  color: ${white};
  border-radius: 50%;
  font-weight: ${fontWeights.semibold};

  ${(props) =>
    props.color &&
    css`
      color: ${readableColor(props.color)};
      background-color: ${props.color};
    `}
`

const Avatar = (props: AvatarProps): JSX.Element => {
  const { size = "standard", name, customColor } = props

  return (
    <Container size={size} color={customColor}>
      {name?.match(/\b\w/g)?.join("").slice(0, 2) || name?.substring(0, 1) || "?"}
    </Container>
  )
}

export default Avatar
