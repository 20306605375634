import React from "react"
import styled from "styled-components"
// import { Link } from "react-router-dom"
// import { rgba } from "polished"

import { LayoutProps } from "../types/layout"

import * as spacing from "../styles/spacing"
import { greyDark, white } from "../styles/colors"

import Heading from "../components/Heading"

const Container = styled.div`
  min-height: 100vh;
  text-align: center;
`

const Header = styled.header`
  background-color: ${greyDark};
  padding: 48px ${spacing.large} 112px;
`

const Logo = styled(Heading)`
  color: ${white};
`

const Inner = styled.div`
  max-width: 520px;
  margin: 0 auto;
  padding: 0 ${spacing.large};
  margin-top: -64px;
`

// const Description = styled.p`
//   color: ${rgba(white, 0.75)};
// `

const LoginLayout = (props: LayoutProps): JSX.Element => {
  return (
    <Container>
      <Header>
        <Logo size={1} tag="div">
          C&C
        </Logo>
        {/* <Description>
          Don’t have an account? <Link to="/signup">Sign up</Link>
        </Description> */}
      </Header>
      <Inner>{props.children}</Inner>
    </Container>
  )
}

export default LoginLayout
