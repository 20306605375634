import React from "react"
import styled from "styled-components"
import { rgba } from "polished"

import { greyDark, white } from "../../styles/colors"
import { borderRadius } from "../../styles/variables"
import { boxShadow } from "../../styles/helpers"

export type StatsProps = {
  children: React.ReactNode
}

const Container = styled.div`
  display: flex;
  background-color: ${greyDark};
  border-radius: ${borderRadius};
  ${boxShadow.small};
`

const Item = styled.div`
  flex-grow: 1;

  & + & {
    border-left: 1px solid ${rgba(white, 0.15)};
  }
`

const Stats = (props: StatsProps): JSX.Element => {
  const { children } = props

  return (
    <Container>
      {React.Children.map(children, (child, i) => (
        <Item key={i}>{child}</Item>
      ))}
    </Container>
  )
}

export default Stats
