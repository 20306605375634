import { useMemo } from "react"
import { useDashboardPaths } from "./useDashboardPaths"

export type SettingsPaths = "general" | "notifications" | "hours" | "security"

export const useSettingsPaths = (root: string): Record<SettingsPaths, string> => {
  const basePaths = useDashboardPaths(root)

  const paths = useMemo(() => {
    return {
      general: `${basePaths.settings}/general`,
      notifications: `${basePaths.settings}/notifications`,
      hours: `${basePaths.settings}/hours`,
      security: `${basePaths.settings}/security`
    }
  }, [basePaths])

  return paths
}
