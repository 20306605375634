import React from "react"
import Modal, { ModalProps } from "."
import Button from "../Button"
import Inline from "../Inline"

export type ConfirmShipmentModalProps = ModalProps & {
  totalIssues: number
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmShipmentModal = (props: ConfirmShipmentModalProps): JSX.Element => {
  const { isOpen, onClose, onCancel, onConfirm, totalIssues } = props

  return (
    <Modal
      heading="Confirm Shipment"
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <Inline>
          <Button intent="primary" onClick={onConfirm}>
            Yes, confirm
          </Button>
          <Button intent="negative" onClick={onCancel}>
            No, report issues
          </Button>
        </Inline>
      }
    >
      <p>
        Are you sure you want to confirm this shipment? Your inventory will be automatically updated
        based on this information.
        <br />
        <br />
        {totalIssues} issue(s) reported
      </p>
    </Modal>
  )
}

export default ConfirmShipmentModal
