import React from "react"
import styled from "styled-components"
import { rgba } from "polished"

import { borderRadius } from "../styles/variables"
import { greyDark, white } from "../styles/colors"
import { fontSizes } from "../styles/typography"
import { boxShadow } from "../styles/helpers"
import * as spacing from "../styles/spacing"

import Heading from "./Heading"
import { Link } from "react-router-dom"

export type ProfileCardProps = {
  store: string
  erp: string
  address: string
}

const Container = styled.div`
  position: relative;
  background-color: ${greyDark};
  border-radius: ${borderRadius};
  ${boxShadow.medium};
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  padding: ${spacing.large};
`

const Name = styled(Heading)`
  color: ${white};
  margin-bottom: ${spacing.small};
`

const Address = styled.p`
  color: ${rgba(white, 0.75)};
`

const Disclaimer = styled.p`
  margin-top: ${spacing.xLarge};
  font-size: ${fontSizes.small};
  color: ${rgba(white, 0.5)};
`

const ProfileCard = (props: ProfileCardProps): JSX.Element => {
  const { store, erp, address } = props

  return (
    <Container>
      <Content>
        <Name>
          {store} ({erp})
        </Name>
        <Address>{address}</Address>
        <Disclaimer>
          Want to change your store name or address? <Link to="/dashboard/help">Contact us</Link>
        </Disclaimer>
      </Content>
    </Container>
  )
}

export default ProfileCard
