import React from "react"
import styled, { css } from "styled-components"
import { Redirect, RouteComponentProps } from "react-router"
import { NavLink, Switch, Route } from "react-router-dom"

import { fontSizes, fontWeights } from "../styles/typography"
import { inputHeights, animationTime, animationCurve } from "../styles/variables"
import { grey, greyLight, greyDark, borderColor } from "../styles/colors"
import * as spacing from "../styles/spacing"
import Stack from "./Stack"

export type TabItemBase<TabTypes extends string> = {
  id: TabTypes
  label: string
}

export type TabRouteItem<TabTypes extends string> = TabItemBase<TabTypes> & {
  to: string
  component: React.ComponentType<RouteComponentProps<never>>
  isDefault?: boolean
}

export type TabCustomItem<TabTypes extends string> = TabItemBase<TabTypes>

export type TabNavRouterProps<TabTypes extends string> = {
  tabs: Array<TabRouteItem<TabTypes>>
  handleSpacing?: boolean
}

export type TabNavCustomProps<TabTypes extends string> = {
  tabs: Array<TabCustomItem<TabTypes>>
  handleSpacing?: boolean
  onTabSelected: (tabId: TabTypes) => void
  selectedTab: TabTypes
}

const Container = styled.div`
  display: flex;
`

const TabLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: ${inputHeights.small};
  font-size: ${fontSizes.small};
  padding: 0 ${spacing.medium};
  border-radius: ${spacing.large};
  color: ${greyLight};
  font-weight: ${fontWeights.semibold};
  transition: background-color ${animationTime} ${animationCurve},
    color ${animationTime} ${animationCurve};
  outline: 0;

  & + & {
    margin-left: ${spacing.small};
  }

  &:hover,
  &:focus {
    color: ${grey};
  }

  &${".active"} {
    background-color: ${borderColor};
    color: ${greyDark};

    &:hover {
      color: ${greyDark};
    }
  }
`

export type TabProps = {
  active: boolean
}

const Tab = styled.button<TabProps>`
  display: flex;
  align-items: center;
  height: ${inputHeights.small};
  font-size: ${fontSizes.small};
  padding: 0 ${spacing.medium};
  border-radius: ${spacing.large};
  color: ${greyLight};
  font-weight: ${fontWeights.semibold};
  transition: background-color ${animationTime} ${animationCurve},
    color ${animationTime} ${animationCurve};
  cursor: pointer;
  outline: 0;

  & + & {
    margin-left: ${spacing.small};
  }

  &:hover,
  &:focus {
    color: ${grey};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${borderColor};
      color: ${greyDark};

      &:hover,
      &:focus {
        color: ${greyDark};
      }
    `}
`

export function TabNavRouter<TabTypes extends string>(
  props: TabNavRouterProps<TabTypes>
): JSX.Element {
  const { tabs, handleSpacing } = props

  const defaultTab = tabs.filter((t) => t.isDefault)[0]

  return (
    <>
      <Stack space={handleSpacing ? "standard" : "none"}>
        <Container>
          {tabs.map((tab, i) => (
            <TabLink to={tab.to} key={i}>
              {tab.label}
            </TabLink>
          ))}
        </Container>
        <Switch>
          {tabs.map((tab) => {
            return (
              <Route key={tab.to} path={tab.to} exact={!tab.isDefault} component={tab.component} />
            )
          })}
          {defaultTab && (
            <Route>
              <Redirect to={defaultTab.to} />
            </Route>
          )}
        </Switch>
      </Stack>
    </>
  )
}

export function TabNavCustom<TabTypes extends string>(
  props: TabNavCustomProps<TabTypes>
): JSX.Element {
  const { tabs, handleSpacing, selectedTab } = props

  return (
    <Stack space={handleSpacing ? "standard" : "none"}>
      <Container>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            active={selectedTab === tab.id}
            onClick={() => props.onTabSelected(tab.id)}
          >
            {tab.label}
          </Tab>
        ))}
      </Container>
    </Stack>
  )
}
