import React, { PropsWithChildren, useCallback, useEffect, useMemo } from "react"
import { z } from "zod"
import {
  PushPreferenceInput,
  SubscribeToPushMutation,
  useSubscribeToPushMutation
} from "../generated/graphql"
import { UseWebPush, useWebPush } from "../hooks/useWebpush"

export interface IWebPushContext {
  getPermissionStatus: UseWebPush["getPermissionStatus"]
  requestPermission: UseWebPush["requestPermission"]
  createSubscription: UseWebPush["createSubscription"]
  updatePreferences: (preferences: PushPreferenceInput[]) => void
  subscription: SubscribeToPushMutation["subscribeToPush"]
  subscribed: boolean
  isLoading: boolean
}

const DEFAULT_VALUE: IWebPushContext = {
  getPermissionStatus: () => "default",
  requestPermission: () => {
    throw new Error()
  },
  createSubscription: () => {
    throw new Error()
  },
  updatePreferences: () => {
    throw new Error()
  },
  // currentSubscription: null,
  subscription: null,
  subscribed: false,
  isLoading: true
}

const ValidSubscriptionSchema = z.object({
  endpoint: z.string().url(),
  expirationTime: z.number().nullable().optional(),
  keys: z.object({
    auth: z.string(),
    p256dh: z.string()
  })
})

export const WebPushContext = React.createContext<IWebPushContext>(DEFAULT_VALUE)

export const WebPushContextProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { getPermissionStatus, requestPermission, currentSubscription, createSubscription } =
    useWebPush()

  const [subscribeToPushMutation, subscribeResult] = useSubscribeToPushMutation({})

  const sendSubscription = (
    subscription: PushSubscription,
    preferences: PushPreferenceInput[] = []
  ) => {
    const subscriptionInput = ValidSubscriptionSchema.parse(subscription.toJSON())
    subscribeToPushMutation({
      variables: { input: { subscription: subscriptionInput, preferences } }
    })
  }

  const subscribed = useMemo(() => {
    return !!subscribeResult.data && !subscribeResult.error
  }, [subscribeResult])

  const updatePreferences = useCallback(
    (preferences: PushPreferenceInput[]) => {
      if (!currentSubscription) return
      sendSubscription(currentSubscription, preferences)
    },
    [currentSubscription]
  )

  useEffect(() => {
    if (currentSubscription) {
      sendSubscription(currentSubscription)
    }
  }, [currentSubscription])

  const value = {
    getPermissionStatus,
    requestPermission,
    createSubscription,
    updatePreferences,
    subscription: subscribeResult.data?.subscribeToPush,
    subscribed,
    isLoading: subscribeResult.loading
  }

  return <WebPushContext.Provider value={value}>{props.children}</WebPushContext.Provider>
}
