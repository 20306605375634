import moment from "moment"
import React from "react"
import { Order } from "../../generated/graphql"
import { useTimeRemaining } from "../../hooks/useTimeRemaining"

export const OrderTimeRemaining = (props: { order: Order }): JSX.Element => {
  const { order } = props
  const expires_at = order.shipping_request?.candidate?.expires_at ?? order.expires_at
  const unit = "seconds"

  const { timeDifference, isPast } = useTimeRemaining({
    referenceDate: expires_at,
    resolutionUnit: unit
  })

  if (!expires_at) return <span>-</span>

  const durationString = moment.duration(timeDifference, unit).humanize()
  if (isPast) return <span>LATE by {durationString}</span>
  return <span>{durationString}</span>
}
