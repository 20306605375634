import React, { useContext, useState } from "react"
import { DropdownOption } from "../components/Dropdown"
import {
  CountOrdersQuery,
  GetCurrentUserQuery,
  OrdersQueryBucket,
  useCountOrdersQuery,
  useGetCurrentUserQuery
} from "../generated/graphql"
import { useClock } from "../hooks/useClock"
import { AuthContext } from "./AuthContext"
import { BaseProviderProps } from "./types"
import { WebPushContextProvider } from "./WebPushContext"

const BADGE_BUCKETS = [OrdersQueryBucket.Pending]

export type SINCE_UNIT = "days" | "hours"
export type SINCE_OPTION = number // = string & `${number}/${SINCE_UNIT}`

export const SELECTED_PERIOD_OPTIONS: DropdownOption<SINCE_OPTION>[] = [
  {
    label: "Last 7 days",
    value: 7
  },
  {
    label: "Last 30 days",
    value: 30
  },
  {
    label: "Last 365 days",
    value: 365
  }
]

export interface IAppContext {
  currentUser?: GetCurrentUserQuery["me"]
  orderCounts?: CountOrdersQuery["countOrders"]
  selectedPeriodDays: number
  setSelectedPeriodDays: (period: number) => void
  clock: Date
}

const DEFAULT_VALUE: IAppContext = {
  currentUser: undefined,
  orderCounts: undefined,
  selectedPeriodDays: 7,
  setSelectedPeriodDays: () => null,
  clock: new Date()
}

export const AppContext = React.createContext<IAppContext>(DEFAULT_VALUE)

export const AppContextProvider = (props: BaseProviderProps): JSX.Element => {
  const { isAuthenticated } = useContext(AuthContext)

  const [selectedPeriodDays, setSelectedPeriodDays] = useState<number>(7)
  const clock = useClock({ updateEveryMs: 1000 })

  const { data: currentUserData } = useGetCurrentUserQuery({
    skip: !isAuthenticated
  })

  const { data: orderCountData } = useCountOrdersQuery({
    skip: !isAuthenticated,
    variables: { input: { buckets: BADGE_BUCKETS } },
    // pollInterval: 30000,
    nextFetchPolicy: "network-only"
  })

  const value = {
    currentUser: currentUserData?.me,
    orderCounts: orderCountData?.countOrders,
    selectedPeriodDays,
    setSelectedPeriodDays,
    clock
  }

  return (
    <AppContext.Provider value={value}>
      <WebPushContextProvider>{props.children}</WebPushContextProvider>
    </AppContext.Provider>
  )
}
