import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type CountOrdersInput = {
  buckets: Array<OrdersQueryBucket>;
};

export type CountOrdersItem = {
  __typename?: 'CountOrdersItem';
  bucket: OrdersQueryBucket;
  count: Scalars['Int'];
};

export type Customer = {
  __typename?: 'Customer';
  customer_id: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
};

export type InventoryLine = {
  __typename?: 'InventoryLine';
  blocked: Scalars['Int'];
  id: Scalars['ID'];
  pack_upc: Scalars['String'];
  product: Product;
  reserved: Scalars['Int'];
  unrestricted: Scalars['Int'];
};

export type InventoryQueryInput = {
  cursor?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptShippingRequest?: Maybe<Order>;
  confirmShipment: ShipmentConfirmation;
  markShippingRequestCollected?: Maybe<Order>;
  markShippingRequestReady?: Maybe<Order>;
  rejectOrder: Order;
  rejectShippingRequest?: Maybe<Order>;
  reportShipmentIssue: ShipmentIssueReport;
  subscribeToPush?: Maybe<PushSubscription>;
  updateOpeningHours: Array<OpeningHour>;
  updateOrderStatus: Order;
};


export type MutationAcceptShippingRequestArgs = {
  input: ShippingRequestStatusChangeInput;
};


export type MutationConfirmShipmentArgs = {
  input: ShipmentConfirmationInput;
};


export type MutationMarkShippingRequestCollectedArgs = {
  input: ShippingRequestStatusChangeInput;
};


export type MutationMarkShippingRequestReadyArgs = {
  input: ShippingRequestStatusChangeInput;
};


export type MutationRejectOrderArgs = {
  input: RejectOrderInput;
};


export type MutationRejectShippingRequestArgs = {
  input: ShippingRequestStatusChangeInput;
};


export type MutationReportShipmentIssueArgs = {
  input: ReportShipmentIssueInput;
};


export type MutationSubscribeToPushArgs = {
  input: SubscribeToPushInput;
};


export type MutationUpdateOpeningHoursArgs = {
  input: UpdateOpeningHoursInput;
};


export type MutationUpdateOrderStatusArgs = {
  input: UpdateOrderStatusInput;
};

export type OpeningHour = {
  __typename?: 'OpeningHour';
  closed: Scalars['Boolean'];
  day_of_month: Scalars['Int'];
  day_of_week: Scalars['Int'];
  duration_minutes: Scalars['Int'];
  month: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  start_time: Scalars['String'];
  year: Scalars['Int'];
};

export type OpeningHourInput = {
  closed: Scalars['Boolean'];
  day_of_month: Scalars['Int'];
  day_of_week: Scalars['Int'];
  duration_minutes: Scalars['Int'];
  month: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  start_time: Scalars['String'];
  year: Scalars['Int'];
};

export type Order = {
  __typename?: 'Order';
  customer?: Maybe<Customer>;
  expires_at?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  line_items: Array<OrderLineItem>;
  order_date: Scalars['Date'];
  order_number: Scalars['ID'];
  shipping_request?: Maybe<ShippingRequest>;
  statuses: Array<OrderStatus>;
};

export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  product: Product;
  quantity: Scalars['Int'];
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  status_date: Scalars['Date'];
  type: OrderStatusType;
};

export enum OrderStatusType {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Collected = 'Collected',
  Confirmed = 'Confirmed',
  Exception = 'Exception',
  NotAvailable = 'NotAvailable',
  Picked = 'Picked',
  Reserved = 'Reserved'
}

export type OrdersChartDataPoint = {
  __typename?: 'OrdersChartDataPoint';
  count: Scalars['Int'];
  date: Scalars['String'];
};

export type OrdersChartInput = {
  period_days: Scalars['Int'];
};

export enum OrdersQueryBucket {
  All = 'All',
  Collected = 'Collected',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type OrdersQueryInput = {
  bucket: OrdersQueryBucket;
  cursor?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  since: Scalars['Date'];
};

export type OrdersQueryResult = {
  __typename?: 'OrdersQueryResult';
  orders: Array<Order>;
  page_info: PageInfo;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  next_page?: Maybe<Scalars['String']>;
  prev_page?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  name: Scalars['String'];
  sku: Scalars['String'];
};

export enum Province {
  Ab = 'AB',
  Bc = 'BC',
  Mb = 'MB',
  Nb = 'NB',
  Nl = 'NL',
  Ns = 'NS',
  Nt = 'NT',
  Nu = 'NU',
  On = 'ON',
  Pe = 'PE',
  Qc = 'QC',
  Sk = 'SK',
  Yt = 'YT'
}

export enum PushNotificationType {
  OrderReminder = 'order_reminder',
  ShipmentReminder = 'shipment_reminder'
}

export type PushPreference = {
  __typename?: 'PushPreference';
  minimum_interval_minutes: Scalars['Int'];
  notification_type: PushNotificationType;
};

export type PushPreferenceInput = {
  minimum_interval_minutes: Scalars['Int'];
  notification_type: PushNotificationType;
};

export type PushSubscription = {
  __typename?: 'PushSubscription';
  endpoint: Scalars['String'];
  expirationTime?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  preferences: Array<PushPreference>;
  user: User;
};

export type PushSubscriptionInput = {
  endpoint: Scalars['String'];
  expirationTime?: Maybe<Scalars['Int']>;
  keys: PushSubscriptionKeysInput;
};

export type PushSubscriptionKeysInput = {
  auth: Scalars['String'];
  p256dh: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  countOrders: Array<CountOrdersItem>;
  inventory: Array<InventoryLine>;
  me?: Maybe<User>;
  opening_hours: Array<OpeningHour>;
  order: Order;
  orders: OrdersQueryResult;
  orders_chart: Array<OrdersChartDataPoint>;
  retailer_stats: Array<Stat>;
  shipment_anomaly_possible_reasons: Array<ShipmentAnomalyReason>;
  shipments: ShipmentsQueryResult;
};


export type QueryCountOrdersArgs = {
  input: CountOrdersInput;
};


export type QueryInventoryArgs = {
  input: InventoryQueryInput;
};


export type QueryOrderArgs = {
  order_no: Scalars['String'];
};


export type QueryOrdersArgs = {
  input: OrdersQueryInput;
};


export type QueryOrders_ChartArgs = {
  input: OrdersChartInput;
};


export type QueryRetailer_StatsArgs = {
  input: RetailerStatsQueryInput;
};


export type QueryShipmentsArgs = {
  input: ShipmentsQueryInput;
};

export type RejectOrderInput = {
  message: Scalars['String'];
  order_no: Scalars['String'];
};

export type ReportShipmentIssueInput = {
  delivery_no: Scalars['String'];
  message: Scalars['String'];
};

export type Retailer = {
  __typename?: 'Retailer';
  address_line_1: Scalars['String'];
  address_line_2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  name: Scalars['String'];
  postal_code: Scalars['String'];
  province: Province;
  site: Scalars['String'];
  storage_location_id: Scalars['ID'];
};

export type RetailerStatsQueryInput = {
  kinds: Array<StatKind>;
  period_days: Scalars['Int'];
};

export type Shipment = {
  __typename?: 'Shipment';
  anomalies: Array<ShipmentAnomaly>;
  delivery_date?: Maybe<Scalars['Date']>;
  delivery_no: Scalars['String'];
  id: Scalars['ID'];
  line_items: Array<ShipmentLineItem>;
  shipment_date: Scalars['Date'];
  statuses: Array<ShipmentStatus>;
};

export type ShipmentAnomaly = {
  __typename?: 'ShipmentAnomaly';
  affected_quantity_packs: Scalars['Int'];
  id: Scalars['ID'];
  product: Product;
  reason_code: Scalars['String'];
  reason_details?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
};

export type ShipmentAnomalyInput = {
  affected_quantity_packs: Scalars['Int'];
  id: Scalars['ID'];
  reason_code: ShipmentAnomalyReasonCode;
  reason_details?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
};

export type ShipmentAnomalyReason = {
  __typename?: 'ShipmentAnomalyReason';
  freeTextAllowed?: Maybe<Scalars['Boolean']>;
  reason_code: ShipmentAnomalyReasonCode;
  title: Scalars['String'];
};

export enum ShipmentAnomalyReasonCode {
  BrokenItems = 'BROKEN_ITEMS',
  MissingItems = 'MISSING_ITEMS',
  Other = 'OTHER',
  WrongItems = 'WRONG_ITEMS'
}

export type ShipmentConfirmation = {
  __typename?: 'ShipmentConfirmation';
  shipment: Shipment;
};

export type ShipmentConfirmationInput = {
  anomalies: Array<ShipmentAnomalyInput>;
  delivery_no: Scalars['String'];
};

export type ShipmentIssueReport = {
  __typename?: 'ShipmentIssueReport';
  delivery_no: Scalars['String'];
  id: Scalars['ID'];
  message: Scalars['String'];
  reported_at: Scalars['Date'];
  reported_by: Scalars['String'];
};

export type ShipmentLineItem = {
  __typename?: 'ShipmentLineItem';
  id: Scalars['Int'];
  product: Product;
  quantity_packs: Scalars['Float'];
};

export type ShipmentStatus = {
  __typename?: 'ShipmentStatus';
  status_date: Scalars['Date'];
  type: ShipmentStatusType;
};

export enum ShipmentStatusType {
  Accepted = 'Accepted',
  Assigned = 'Assigned',
  Confirmed = 'Confirmed',
  Pending = 'Pending'
}

export type ShipmentsQueryInput = {
  cursor?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type ShipmentsQueryResult = {
  __typename?: 'ShipmentsQueryResult';
  page_info: PageInfo;
  shipments: Array<Shipment>;
};

export type ShippingRequest = {
  __typename?: 'ShippingRequest';
  address_line_1: Scalars['String'];
  address_line_2?: Maybe<Scalars['String']>;
  candidate?: Maybe<ShippingRequestCandidate>;
  carrier_code: Scalars['String'];
  city: Scalars['String'];
  consignment_type: Scalars['String'];
  id: Scalars['Int'];
  option_code: Scalars['String'];
  postal_code: Scalars['String'];
  province_code: Province;
  service_level: Scalars['String'];
  statuses: Array<ShippingRequestStatus>;
};

export type ShippingRequestCandidate = {
  __typename?: 'ShippingRequestCandidate';
  expires_at?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  rejected_at?: Maybe<Scalars['Date']>;
  storage_location_id: Scalars['String'];
};

export type ShippingRequestStatus = {
  __typename?: 'ShippingRequestStatus';
  status_date: Scalars['Date'];
  type: ShippingRequestStatusType;
};

export type ShippingRequestStatusChangeInput = {
  message?: Maybe<Scalars['String']>;
  shipping_request_id: Scalars['Int'];
};

export enum ShippingRequestStatusType {
  Accepted = 'Accepted',
  CancellationRequested = 'CancellationRequested',
  Cancelled = 'Cancelled',
  Collected = 'Collected',
  Created = 'Created',
  Delivered = 'Delivered',
  Dispatched = 'Dispatched',
  Failed = 'Failed',
  ReadyForDispatch = 'ReadyForDispatch',
  ReadyForPickup = 'ReadyForPickup',
  SentToShipper = 'SentToShipper',
  ShipperAccepted = 'ShipperAccepted',
  ShipperCancelled = 'ShipperCancelled',
  ShipperInTransit = 'ShipperInTransit',
  ShipperOnHold = 'ShipperOnHold'
}

export type Stat = {
  __typename?: 'Stat';
  change?: Maybe<Scalars['Float']>;
  kind: StatKind;
  unit: StatUnit;
  value?: Maybe<Scalars['Float']>;
};

export enum StatKind {
  AvgResponse = 'AVG_RESPONSE',
  FillRate = 'FILL_RATE',
  TimeToCollect = 'TIME_TO_COLLECT',
  TotalOrders = 'TOTAL_ORDERS'
}

export enum StatUnit {
  Currency = 'CURRENCY',
  Percentage = 'PERCENTAGE',
  Quantity = 'QUANTITY',
  Seconds = 'SECONDS'
}

export type SubscribeToPushInput = {
  preferences: Array<PushPreferenceInput>;
  subscription: PushSubscriptionInput;
};

export type UpdateOpeningHoursInput = {
  opening_hours: Array<OpeningHourInput>;
};

export type UpdateOrderStatusInput = {
  order_no: Scalars['String'];
  status: OrderStatusType;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['ID'];
  last_name: Scalars['String'];
  retailer?: Maybe<Retailer>;
};

export type AcceptShippingRequestMutationVariables = Exact<{
  input: ShippingRequestStatusChangeInput;
}>;


export type AcceptShippingRequestMutation = { __typename?: 'Mutation', acceptShippingRequest?: Maybe<{ __typename?: 'Order', id: number, order_number: string, order_date: any, statuses: Array<{ __typename?: 'OrderStatus', type: OrderStatusType, status_date: any }>, customer?: Maybe<{ __typename?: 'Customer', customer_id: string, email: string, first_name: string, last_name: string }>, line_items: Array<{ __typename?: 'OrderLineItem', quantity: number, product: { __typename?: 'Product', sku: string, name: string } }>, shipping_request?: Maybe<{ __typename?: 'ShippingRequest', id: number, carrier_code: string, address_line_1: string, address_line_2?: Maybe<string>, city: string, consignment_type: string, option_code: string, postal_code: string, service_level: string, province_code: Province, statuses: Array<{ __typename?: 'ShippingRequestStatus', type: ShippingRequestStatusType, status_date: any }>, candidate?: Maybe<{ __typename?: 'ShippingRequestCandidate', id: number, expires_at?: Maybe<any>, storage_location_id: string }> }> }> };

export type ConfirmShipmentMutationVariables = Exact<{
  input: ShipmentConfirmationInput;
}>;


export type ConfirmShipmentMutation = { __typename?: 'Mutation', confirmShipment: { __typename?: 'ShipmentConfirmation', shipment: { __typename?: 'Shipment', id: string, delivery_no: string, shipment_date: any, delivery_date?: Maybe<any>, statuses: Array<{ __typename?: 'ShipmentStatus', type: ShipmentStatusType, status_date: any }>, line_items: Array<{ __typename?: 'ShipmentLineItem', id: number, quantity_packs: number, product: { __typename?: 'Product', sku: string, name: string } }>, anomalies: Array<{ __typename?: 'ShipmentAnomaly', id: string, sku: string, affected_quantity_packs: number, reason_code: string, reason_details?: Maybe<string>, product: { __typename?: 'Product', sku: string, name: string } }> } } };

export type MarkShippingRequestCollectedMutationVariables = Exact<{
  input: ShippingRequestStatusChangeInput;
}>;


export type MarkShippingRequestCollectedMutation = { __typename?: 'Mutation', markShippingRequestCollected?: Maybe<{ __typename?: 'Order', id: number, order_number: string, order_date: any, statuses: Array<{ __typename?: 'OrderStatus', type: OrderStatusType, status_date: any }>, customer?: Maybe<{ __typename?: 'Customer', customer_id: string, email: string, first_name: string, last_name: string }>, line_items: Array<{ __typename?: 'OrderLineItem', quantity: number, product: { __typename?: 'Product', sku: string, name: string } }>, shipping_request?: Maybe<{ __typename?: 'ShippingRequest', id: number, carrier_code: string, address_line_1: string, address_line_2?: Maybe<string>, city: string, consignment_type: string, option_code: string, postal_code: string, service_level: string, province_code: Province, statuses: Array<{ __typename?: 'ShippingRequestStatus', type: ShippingRequestStatusType, status_date: any }>, candidate?: Maybe<{ __typename?: 'ShippingRequestCandidate', id: number, expires_at?: Maybe<any>, storage_location_id: string }> }> }> };

export type MarkShippingRequestReadyMutationVariables = Exact<{
  input: ShippingRequestStatusChangeInput;
}>;


export type MarkShippingRequestReadyMutation = { __typename?: 'Mutation', markShippingRequestReady?: Maybe<{ __typename?: 'Order', id: number, order_number: string, order_date: any, statuses: Array<{ __typename?: 'OrderStatus', type: OrderStatusType, status_date: any }>, customer?: Maybe<{ __typename?: 'Customer', first_name: string, last_name: string }>, line_items: Array<{ __typename?: 'OrderLineItem', quantity: number, product: { __typename?: 'Product', sku: string, name: string } }>, shipping_request?: Maybe<{ __typename?: 'ShippingRequest', id: number, carrier_code: string, address_line_1: string, address_line_2?: Maybe<string>, city: string, consignment_type: string, option_code: string, postal_code: string, service_level: string, province_code: Province, statuses: Array<{ __typename?: 'ShippingRequestStatus', type: ShippingRequestStatusType, status_date: any }>, candidate?: Maybe<{ __typename?: 'ShippingRequestCandidate', id: number, expires_at?: Maybe<any>, storage_location_id: string }> }> }> };

export type RejectOrderMutationVariables = Exact<{
  input: RejectOrderInput;
}>;


export type RejectOrderMutation = { __typename?: 'Mutation', rejectOrder: { __typename?: 'Order', id: number, order_number: string, order_date: any, statuses: Array<{ __typename?: 'OrderStatus', type: OrderStatusType, status_date: any }>, customer?: Maybe<{ __typename?: 'Customer', customer_id: string, email: string, first_name: string, last_name: string }>, line_items: Array<{ __typename?: 'OrderLineItem', quantity: number, product: { __typename?: 'Product', sku: string, name: string } }>, shipping_request?: Maybe<{ __typename?: 'ShippingRequest', id: number, carrier_code: string, address_line_1: string, address_line_2?: Maybe<string>, city: string, consignment_type: string, option_code: string, postal_code: string, service_level: string, province_code: Province, statuses: Array<{ __typename?: 'ShippingRequestStatus', type: ShippingRequestStatusType, status_date: any }>, candidate?: Maybe<{ __typename?: 'ShippingRequestCandidate', id: number, expires_at?: Maybe<any>, storage_location_id: string }> }> } };

export type RejectShippingRequestMutationVariables = Exact<{
  input: ShippingRequestStatusChangeInput;
}>;


export type RejectShippingRequestMutation = { __typename?: 'Mutation', rejectShippingRequest?: Maybe<{ __typename?: 'Order', id: number, order_number: string, order_date: any, statuses: Array<{ __typename?: 'OrderStatus', type: OrderStatusType, status_date: any }>, customer?: Maybe<{ __typename?: 'Customer', customer_id: string, email: string, first_name: string, last_name: string }>, line_items: Array<{ __typename?: 'OrderLineItem', quantity: number, product: { __typename?: 'Product', sku: string, name: string } }>, shipping_request?: Maybe<{ __typename?: 'ShippingRequest', id: number, carrier_code: string, address_line_1: string, address_line_2?: Maybe<string>, city: string, consignment_type: string, option_code: string, postal_code: string, service_level: string, province_code: Province, statuses: Array<{ __typename?: 'ShippingRequestStatus', type: ShippingRequestStatusType, status_date: any }>, candidate?: Maybe<{ __typename?: 'ShippingRequestCandidate', id: number, expires_at?: Maybe<any>, storage_location_id: string }> }> }> };

export type ReportShipmentIssueMutationVariables = Exact<{
  input: ReportShipmentIssueInput;
}>;


export type ReportShipmentIssueMutation = { __typename?: 'Mutation', reportShipmentIssue: { __typename?: 'ShipmentIssueReport', id: string } };

export type SubscribeToPushMutationVariables = Exact<{
  input: SubscribeToPushInput;
}>;


export type SubscribeToPushMutation = { __typename?: 'Mutation', subscribeToPush?: Maybe<{ __typename?: 'PushSubscription', id: string, expirationTime?: Maybe<number>, endpoint: string, preferences: Array<{ __typename?: 'PushPreference', notification_type: PushNotificationType, minimum_interval_minutes: number }> }> };

export type UpdateOpeningHoursMutationVariables = Exact<{
  input: UpdateOpeningHoursInput;
}>;


export type UpdateOpeningHoursMutation = { __typename?: 'Mutation', updateOpeningHours: Array<{ __typename?: 'OpeningHour', name?: Maybe<string>, day_of_month: number, month: number, year: number, day_of_week: number, start_time: string, duration_minutes: number, closed: boolean }> };

export type UpdateOrderStatusMutationVariables = Exact<{
  input: UpdateOrderStatusInput;
}>;


export type UpdateOrderStatusMutation = { __typename?: 'Mutation', updateOrderStatus: { __typename?: 'Order', id: number, order_number: string, order_date: any, statuses: Array<{ __typename?: 'OrderStatus', type: OrderStatusType, status_date: any }>, customer?: Maybe<{ __typename?: 'Customer', customer_id: string, email: string, first_name: string, last_name: string }>, line_items: Array<{ __typename?: 'OrderLineItem', quantity: number, product: { __typename?: 'Product', sku: string, name: string } }>, shipping_request?: Maybe<{ __typename?: 'ShippingRequest', id: number, carrier_code: string, address_line_1: string, address_line_2?: Maybe<string>, city: string, consignment_type: string, option_code: string, postal_code: string, service_level: string, province_code: Province, statuses: Array<{ __typename?: 'ShippingRequestStatus', type: ShippingRequestStatusType, status_date: any }>, candidate?: Maybe<{ __typename?: 'ShippingRequestCandidate', id: number, expires_at?: Maybe<any>, storage_location_id: string }> }> } };

export type CountOrdersQueryVariables = Exact<{
  input: CountOrdersInput;
}>;


export type CountOrdersQuery = { __typename?: 'Query', countOrders: Array<{ __typename?: 'CountOrdersItem', bucket: OrdersQueryBucket, count: number }> };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', me?: Maybe<{ __typename?: 'User', id: string, email: string, first_name: string, last_name: string, retailer?: Maybe<{ __typename?: 'Retailer', storage_location_id: string, site: string, name: string, postal_code: string, city: string, address_line_1: string, address_line_2?: Maybe<string>, province: Province }> }> };

export type GetOrdersQueryVariables = Exact<{
  input: OrdersQueryInput;
}>;


export type GetOrdersQuery = { __typename?: 'Query', orders: { __typename?: 'OrdersQueryResult', orders: Array<{ __typename?: 'Order', id: number, order_number: string, order_date: any, expires_at?: Maybe<any>, statuses: Array<{ __typename?: 'OrderStatus', type: OrderStatusType, status_date: any }>, customer?: Maybe<{ __typename?: 'Customer', customer_id: string, email: string, first_name: string, last_name: string }>, line_items: Array<{ __typename?: 'OrderLineItem', quantity: number, product: { __typename?: 'Product', sku: string, name: string } }>, shipping_request?: Maybe<{ __typename?: 'ShippingRequest', id: number, carrier_code: string, address_line_1: string, address_line_2?: Maybe<string>, city: string, consignment_type: string, option_code: string, postal_code: string, service_level: string, province_code: Province, statuses: Array<{ __typename?: 'ShippingRequestStatus', type: ShippingRequestStatusType, status_date: any }>, candidate?: Maybe<{ __typename?: 'ShippingRequestCandidate', id: number, expires_at?: Maybe<any>, storage_location_id: string, rejected_at?: Maybe<any> }> }> }>, page_info: { __typename?: 'PageInfo', prev_page?: Maybe<string>, next_page?: Maybe<string> } } };

export type GetInventoryQueryVariables = Exact<{
  input: InventoryQueryInput;
}>;


export type GetInventoryQuery = { __typename?: 'Query', inventory: Array<{ __typename?: 'InventoryLine', id: string, unrestricted: number, reserved: number, blocked: number, pack_upc: string, product: { __typename?: 'Product', sku: string, name: string } }> };

export type GetOpeningHoursQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOpeningHoursQuery = { __typename?: 'Query', opening_hours: Array<{ __typename?: 'OpeningHour', name?: Maybe<string>, day_of_month: number, month: number, year: number, day_of_week: number, start_time: string, duration_minutes: number, closed: boolean }> };

export type GetOrderByIdQueryVariables = Exact<{
  order_no: Scalars['String'];
}>;


export type GetOrderByIdQuery = { __typename?: 'Query', order: { __typename?: 'Order', id: number, order_number: string, order_date: any, expires_at?: Maybe<any>, statuses: Array<{ __typename?: 'OrderStatus', type: OrderStatusType, status_date: any }>, customer?: Maybe<{ __typename?: 'Customer', customer_id: string, email: string, first_name: string, last_name: string }>, line_items: Array<{ __typename?: 'OrderLineItem', quantity: number, product: { __typename?: 'Product', sku: string, name: string } }>, shipping_request?: Maybe<{ __typename?: 'ShippingRequest', id: number, carrier_code: string, address_line_1: string, address_line_2?: Maybe<string>, city: string, consignment_type: string, option_code: string, postal_code: string, service_level: string, province_code: Province, statuses: Array<{ __typename?: 'ShippingRequestStatus', type: ShippingRequestStatusType, status_date: any }>, candidate?: Maybe<{ __typename?: 'ShippingRequestCandidate', id: number, expires_at?: Maybe<any>, storage_location_id: string }> }> } };

export type GetOrdersChartQueryVariables = Exact<{
  input: OrdersChartInput;
}>;


export type GetOrdersChartQuery = { __typename?: 'Query', orders_chart: Array<{ __typename?: 'OrdersChartDataPoint', date: string, count: number }> };

export type GetPossibleAnomalyReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPossibleAnomalyReasonsQuery = { __typename?: 'Query', shipment_anomaly_possible_reasons: Array<{ __typename?: 'ShipmentAnomalyReason', reason_code: ShipmentAnomalyReasonCode, title: string }> };

export type GetRetailerStatsQueryVariables = Exact<{
  input: RetailerStatsQueryInput;
}>;


export type GetRetailerStatsQuery = { __typename?: 'Query', retailer_stats: Array<{ __typename?: 'Stat', kind: StatKind, unit: StatUnit, change?: Maybe<number>, value?: Maybe<number> }> };

export type GetShipmentsQueryVariables = Exact<{
  input: ShipmentsQueryInput;
}>;


export type GetShipmentsQuery = { __typename?: 'Query', shipments: { __typename?: 'ShipmentsQueryResult', shipments: Array<{ __typename?: 'Shipment', id: string, delivery_no: string, shipment_date: any, delivery_date?: Maybe<any>, statuses: Array<{ __typename?: 'ShipmentStatus', type: ShipmentStatusType, status_date: any }>, line_items: Array<{ __typename?: 'ShipmentLineItem', id: number, quantity_packs: number, product: { __typename?: 'Product', sku: string, name: string } }>, anomalies: Array<{ __typename?: 'ShipmentAnomaly', id: string, sku: string, affected_quantity_packs: number, reason_code: string, reason_details?: Maybe<string>, product: { __typename?: 'Product', name: string, sku: string } }> }>, page_info: { __typename?: 'PageInfo', prev_page?: Maybe<string>, next_page?: Maybe<string> } } };


export const AcceptShippingRequestDocument = gql`
    mutation AcceptShippingRequest($input: ShippingRequestStatusChangeInput!) {
  acceptShippingRequest(input: $input) {
    id
    order_number
    statuses {
      type
      status_date
    }
    order_date
    customer {
      customer_id
      email
      first_name
      last_name
    }
    line_items {
      product {
        sku
        name
      }
      quantity
    }
    shipping_request {
      id
      carrier_code
      address_line_1
      address_line_2
      city
      consignment_type
      option_code
      postal_code
      service_level
      province_code
      statuses {
        type
        status_date
      }
      candidate {
        id
        expires_at
        storage_location_id
      }
    }
  }
}
    `;
export type AcceptShippingRequestMutationFn = Apollo.MutationFunction<AcceptShippingRequestMutation, AcceptShippingRequestMutationVariables>;

/**
 * __useAcceptShippingRequestMutation__
 *
 * To run a mutation, you first call `useAcceptShippingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptShippingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptShippingRequestMutation, { data, loading, error }] = useAcceptShippingRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptShippingRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptShippingRequestMutation, AcceptShippingRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptShippingRequestMutation, AcceptShippingRequestMutationVariables>(AcceptShippingRequestDocument, options);
      }
export type AcceptShippingRequestMutationHookResult = ReturnType<typeof useAcceptShippingRequestMutation>;
export type AcceptShippingRequestMutationResult = Apollo.MutationResult<AcceptShippingRequestMutation>;
export type AcceptShippingRequestMutationOptions = Apollo.BaseMutationOptions<AcceptShippingRequestMutation, AcceptShippingRequestMutationVariables>;
export const ConfirmShipmentDocument = gql`
    mutation ConfirmShipment($input: ShipmentConfirmationInput!) {
  confirmShipment(input: $input) {
    shipment {
      id
      delivery_no
      shipment_date
      delivery_date
      statuses {
        type
        status_date
      }
      line_items {
        id
        product {
          sku
          name
        }
        quantity_packs
      }
      anomalies {
        id
        sku
        affected_quantity_packs
        reason_code
        reason_details
        product {
          sku
          name
        }
      }
    }
  }
}
    `;
export type ConfirmShipmentMutationFn = Apollo.MutationFunction<ConfirmShipmentMutation, ConfirmShipmentMutationVariables>;

/**
 * __useConfirmShipmentMutation__
 *
 * To run a mutation, you first call `useConfirmShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmShipmentMutation, { data, loading, error }] = useConfirmShipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmShipmentMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmShipmentMutation, ConfirmShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmShipmentMutation, ConfirmShipmentMutationVariables>(ConfirmShipmentDocument, options);
      }
export type ConfirmShipmentMutationHookResult = ReturnType<typeof useConfirmShipmentMutation>;
export type ConfirmShipmentMutationResult = Apollo.MutationResult<ConfirmShipmentMutation>;
export type ConfirmShipmentMutationOptions = Apollo.BaseMutationOptions<ConfirmShipmentMutation, ConfirmShipmentMutationVariables>;
export const MarkShippingRequestCollectedDocument = gql`
    mutation MarkShippingRequestCollected($input: ShippingRequestStatusChangeInput!) {
  markShippingRequestCollected(input: $input) {
    id
    order_number
    statuses {
      type
      status_date
    }
    order_date
    customer {
      customer_id
      email
      first_name
      last_name
    }
    line_items {
      product {
        sku
        name
      }
      quantity
    }
    shipping_request {
      id
      carrier_code
      address_line_1
      address_line_2
      city
      consignment_type
      option_code
      postal_code
      service_level
      province_code
      statuses {
        type
        status_date
      }
      candidate {
        id
        expires_at
        storage_location_id
      }
    }
  }
}
    `;
export type MarkShippingRequestCollectedMutationFn = Apollo.MutationFunction<MarkShippingRequestCollectedMutation, MarkShippingRequestCollectedMutationVariables>;

/**
 * __useMarkShippingRequestCollectedMutation__
 *
 * To run a mutation, you first call `useMarkShippingRequestCollectedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkShippingRequestCollectedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markShippingRequestCollectedMutation, { data, loading, error }] = useMarkShippingRequestCollectedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkShippingRequestCollectedMutation(baseOptions?: Apollo.MutationHookOptions<MarkShippingRequestCollectedMutation, MarkShippingRequestCollectedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkShippingRequestCollectedMutation, MarkShippingRequestCollectedMutationVariables>(MarkShippingRequestCollectedDocument, options);
      }
export type MarkShippingRequestCollectedMutationHookResult = ReturnType<typeof useMarkShippingRequestCollectedMutation>;
export type MarkShippingRequestCollectedMutationResult = Apollo.MutationResult<MarkShippingRequestCollectedMutation>;
export type MarkShippingRequestCollectedMutationOptions = Apollo.BaseMutationOptions<MarkShippingRequestCollectedMutation, MarkShippingRequestCollectedMutationVariables>;
export const MarkShippingRequestReadyDocument = gql`
    mutation MarkShippingRequestReady($input: ShippingRequestStatusChangeInput!) {
  markShippingRequestReady(input: $input) {
    id
    order_number
    statuses {
      type
      status_date
    }
    order_date
    customer {
      first_name
      last_name
    }
    line_items {
      product {
        sku
        name
      }
      quantity
    }
    shipping_request {
      id
      carrier_code
      address_line_1
      address_line_2
      city
      consignment_type
      option_code
      postal_code
      service_level
      province_code
      statuses {
        type
        status_date
      }
      candidate {
        id
        expires_at
        storage_location_id
      }
    }
  }
}
    `;
export type MarkShippingRequestReadyMutationFn = Apollo.MutationFunction<MarkShippingRequestReadyMutation, MarkShippingRequestReadyMutationVariables>;

/**
 * __useMarkShippingRequestReadyMutation__
 *
 * To run a mutation, you first call `useMarkShippingRequestReadyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkShippingRequestReadyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markShippingRequestReadyMutation, { data, loading, error }] = useMarkShippingRequestReadyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkShippingRequestReadyMutation(baseOptions?: Apollo.MutationHookOptions<MarkShippingRequestReadyMutation, MarkShippingRequestReadyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkShippingRequestReadyMutation, MarkShippingRequestReadyMutationVariables>(MarkShippingRequestReadyDocument, options);
      }
export type MarkShippingRequestReadyMutationHookResult = ReturnType<typeof useMarkShippingRequestReadyMutation>;
export type MarkShippingRequestReadyMutationResult = Apollo.MutationResult<MarkShippingRequestReadyMutation>;
export type MarkShippingRequestReadyMutationOptions = Apollo.BaseMutationOptions<MarkShippingRequestReadyMutation, MarkShippingRequestReadyMutationVariables>;
export const RejectOrderDocument = gql`
    mutation RejectOrder($input: RejectOrderInput!) {
  rejectOrder(input: $input) {
    id
    order_number
    statuses {
      type
      status_date
    }
    order_date
    customer {
      customer_id
      email
      first_name
      last_name
    }
    line_items {
      product {
        sku
        name
      }
      quantity
    }
    shipping_request {
      id
      carrier_code
      address_line_1
      address_line_2
      city
      consignment_type
      option_code
      postal_code
      service_level
      province_code
      statuses {
        type
        status_date
      }
      candidate {
        id
        expires_at
        storage_location_id
      }
    }
  }
}
    `;
export type RejectOrderMutationFn = Apollo.MutationFunction<RejectOrderMutation, RejectOrderMutationVariables>;

/**
 * __useRejectOrderMutation__
 *
 * To run a mutation, you first call `useRejectOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectOrderMutation, { data, loading, error }] = useRejectOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectOrderMutation(baseOptions?: Apollo.MutationHookOptions<RejectOrderMutation, RejectOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectOrderMutation, RejectOrderMutationVariables>(RejectOrderDocument, options);
      }
export type RejectOrderMutationHookResult = ReturnType<typeof useRejectOrderMutation>;
export type RejectOrderMutationResult = Apollo.MutationResult<RejectOrderMutation>;
export type RejectOrderMutationOptions = Apollo.BaseMutationOptions<RejectOrderMutation, RejectOrderMutationVariables>;
export const RejectShippingRequestDocument = gql`
    mutation RejectShippingRequest($input: ShippingRequestStatusChangeInput!) {
  rejectShippingRequest(input: $input) {
    id
    order_number
    statuses {
      type
      status_date
    }
    order_date
    customer {
      customer_id
      email
      first_name
      last_name
    }
    line_items {
      product {
        sku
        name
      }
      quantity
    }
    shipping_request {
      id
      carrier_code
      address_line_1
      address_line_2
      city
      consignment_type
      option_code
      postal_code
      service_level
      province_code
      statuses {
        type
        status_date
      }
      candidate {
        id
        expires_at
        storage_location_id
      }
    }
  }
}
    `;
export type RejectShippingRequestMutationFn = Apollo.MutationFunction<RejectShippingRequestMutation, RejectShippingRequestMutationVariables>;

/**
 * __useRejectShippingRequestMutation__
 *
 * To run a mutation, you first call `useRejectShippingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectShippingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectShippingRequestMutation, { data, loading, error }] = useRejectShippingRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectShippingRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectShippingRequestMutation, RejectShippingRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectShippingRequestMutation, RejectShippingRequestMutationVariables>(RejectShippingRequestDocument, options);
      }
export type RejectShippingRequestMutationHookResult = ReturnType<typeof useRejectShippingRequestMutation>;
export type RejectShippingRequestMutationResult = Apollo.MutationResult<RejectShippingRequestMutation>;
export type RejectShippingRequestMutationOptions = Apollo.BaseMutationOptions<RejectShippingRequestMutation, RejectShippingRequestMutationVariables>;
export const ReportShipmentIssueDocument = gql`
    mutation ReportShipmentIssue($input: ReportShipmentIssueInput!) {
  reportShipmentIssue(input: $input) {
    id
  }
}
    `;
export type ReportShipmentIssueMutationFn = Apollo.MutationFunction<ReportShipmentIssueMutation, ReportShipmentIssueMutationVariables>;

/**
 * __useReportShipmentIssueMutation__
 *
 * To run a mutation, you first call `useReportShipmentIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportShipmentIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportShipmentIssueMutation, { data, loading, error }] = useReportShipmentIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportShipmentIssueMutation(baseOptions?: Apollo.MutationHookOptions<ReportShipmentIssueMutation, ReportShipmentIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportShipmentIssueMutation, ReportShipmentIssueMutationVariables>(ReportShipmentIssueDocument, options);
      }
export type ReportShipmentIssueMutationHookResult = ReturnType<typeof useReportShipmentIssueMutation>;
export type ReportShipmentIssueMutationResult = Apollo.MutationResult<ReportShipmentIssueMutation>;
export type ReportShipmentIssueMutationOptions = Apollo.BaseMutationOptions<ReportShipmentIssueMutation, ReportShipmentIssueMutationVariables>;
export const SubscribeToPushDocument = gql`
    mutation SubscribeToPush($input: SubscribeToPushInput!) {
  subscribeToPush(input: $input) {
    id
    expirationTime
    endpoint
    preferences {
      notification_type
      minimum_interval_minutes
    }
  }
}
    `;
export type SubscribeToPushMutationFn = Apollo.MutationFunction<SubscribeToPushMutation, SubscribeToPushMutationVariables>;

/**
 * __useSubscribeToPushMutation__
 *
 * To run a mutation, you first call `useSubscribeToPushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToPushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToPushMutation, { data, loading, error }] = useSubscribeToPushMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeToPushMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToPushMutation, SubscribeToPushMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeToPushMutation, SubscribeToPushMutationVariables>(SubscribeToPushDocument, options);
      }
export type SubscribeToPushMutationHookResult = ReturnType<typeof useSubscribeToPushMutation>;
export type SubscribeToPushMutationResult = Apollo.MutationResult<SubscribeToPushMutation>;
export type SubscribeToPushMutationOptions = Apollo.BaseMutationOptions<SubscribeToPushMutation, SubscribeToPushMutationVariables>;
export const UpdateOpeningHoursDocument = gql`
    mutation UpdateOpeningHours($input: UpdateOpeningHoursInput!) {
  updateOpeningHours(input: $input) {
    name
    day_of_month
    month
    year
    day_of_week
    start_time
    duration_minutes
    closed
  }
}
    `;
export type UpdateOpeningHoursMutationFn = Apollo.MutationFunction<UpdateOpeningHoursMutation, UpdateOpeningHoursMutationVariables>;

/**
 * __useUpdateOpeningHoursMutation__
 *
 * To run a mutation, you first call `useUpdateOpeningHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpeningHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpeningHoursMutation, { data, loading, error }] = useUpdateOpeningHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOpeningHoursMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOpeningHoursMutation, UpdateOpeningHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOpeningHoursMutation, UpdateOpeningHoursMutationVariables>(UpdateOpeningHoursDocument, options);
      }
export type UpdateOpeningHoursMutationHookResult = ReturnType<typeof useUpdateOpeningHoursMutation>;
export type UpdateOpeningHoursMutationResult = Apollo.MutationResult<UpdateOpeningHoursMutation>;
export type UpdateOpeningHoursMutationOptions = Apollo.BaseMutationOptions<UpdateOpeningHoursMutation, UpdateOpeningHoursMutationVariables>;
export const UpdateOrderStatusDocument = gql`
    mutation UpdateOrderStatus($input: UpdateOrderStatusInput!) {
  updateOrderStatus(input: $input) {
    id
    order_number
    statuses {
      type
      status_date
    }
    order_date
    customer {
      customer_id
      email
      first_name
      last_name
    }
    line_items {
      product {
        sku
        name
      }
      quantity
    }
    shipping_request {
      id
      carrier_code
      address_line_1
      address_line_2
      city
      consignment_type
      option_code
      postal_code
      service_level
      province_code
      statuses {
        type
        status_date
      }
      candidate {
        id
        expires_at
        storage_location_id
      }
    }
  }
}
    `;
export type UpdateOrderStatusMutationFn = Apollo.MutationFunction<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(UpdateOrderStatusDocument, options);
      }
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationResult = Apollo.MutationResult<UpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;
export const CountOrdersDocument = gql`
    query CountOrders($input: CountOrdersInput!) {
  countOrders(input: $input) {
    bucket
    count
  }
}
    `;

/**
 * __useCountOrdersQuery__
 *
 * To run a query within a React component, call `useCountOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountOrdersQuery(baseOptions: Apollo.QueryHookOptions<CountOrdersQuery, CountOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountOrdersQuery, CountOrdersQueryVariables>(CountOrdersDocument, options);
      }
export function useCountOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountOrdersQuery, CountOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountOrdersQuery, CountOrdersQueryVariables>(CountOrdersDocument, options);
        }
export type CountOrdersQueryHookResult = ReturnType<typeof useCountOrdersQuery>;
export type CountOrdersLazyQueryHookResult = ReturnType<typeof useCountOrdersLazyQuery>;
export type CountOrdersQueryResult = Apollo.QueryResult<CountOrdersQuery, CountOrdersQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  me {
    id
    email
    first_name
    last_name
    retailer {
      storage_location_id
      site
      name
      postal_code
      city
      address_line_1
      address_line_2
      province
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetOrdersDocument = gql`
    query GetOrders($input: OrdersQueryInput!) {
  orders(input: $input) {
    orders {
      id
      order_number
      statuses {
        type
        status_date
      }
      order_date
      expires_at
      customer {
        customer_id
        email
        first_name
        last_name
      }
      line_items {
        product {
          sku
          name
        }
        quantity
      }
      shipping_request {
        id
        carrier_code
        address_line_1
        address_line_2
        city
        consignment_type
        option_code
        postal_code
        service_level
        province_code
        statuses {
          type
          status_date
        }
        candidate {
          id
          expires_at
          storage_location_id
          rejected_at
        }
      }
    }
    page_info {
      prev_page
      next_page
    }
  }
}
    `;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetInventoryDocument = gql`
    query GetInventory($input: InventoryQueryInput!) {
  inventory(input: $input) {
    id
    product {
      sku
      name
    }
    unrestricted
    reserved
    blocked
    pack_upc
  }
}
    `;

/**
 * __useGetInventoryQuery__
 *
 * To run a query within a React component, call `useGetInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInventoryQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryQuery, GetInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryQuery, GetInventoryQueryVariables>(GetInventoryDocument, options);
      }
export function useGetInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryQuery, GetInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryQuery, GetInventoryQueryVariables>(GetInventoryDocument, options);
        }
export type GetInventoryQueryHookResult = ReturnType<typeof useGetInventoryQuery>;
export type GetInventoryLazyQueryHookResult = ReturnType<typeof useGetInventoryLazyQuery>;
export type GetInventoryQueryResult = Apollo.QueryResult<GetInventoryQuery, GetInventoryQueryVariables>;
export const GetOpeningHoursDocument = gql`
    query GetOpeningHours {
  opening_hours {
    name
    day_of_month
    month
    year
    day_of_week
    start_time
    duration_minutes
    closed
  }
}
    `;

/**
 * __useGetOpeningHoursQuery__
 *
 * To run a query within a React component, call `useGetOpeningHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpeningHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpeningHoursQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOpeningHoursQuery(baseOptions?: Apollo.QueryHookOptions<GetOpeningHoursQuery, GetOpeningHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOpeningHoursQuery, GetOpeningHoursQueryVariables>(GetOpeningHoursDocument, options);
      }
export function useGetOpeningHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpeningHoursQuery, GetOpeningHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOpeningHoursQuery, GetOpeningHoursQueryVariables>(GetOpeningHoursDocument, options);
        }
export type GetOpeningHoursQueryHookResult = ReturnType<typeof useGetOpeningHoursQuery>;
export type GetOpeningHoursLazyQueryHookResult = ReturnType<typeof useGetOpeningHoursLazyQuery>;
export type GetOpeningHoursQueryResult = Apollo.QueryResult<GetOpeningHoursQuery, GetOpeningHoursQueryVariables>;
export const GetOrderByIdDocument = gql`
    query GetOrderById($order_no: String!) {
  order(order_no: $order_no) {
    id
    order_number
    statuses {
      type
      status_date
    }
    order_date
    expires_at
    customer {
      customer_id
      email
      first_name
      last_name
    }
    line_items {
      product {
        sku
        name
      }
      quantity
    }
    shipping_request {
      id
      carrier_code
      address_line_1
      address_line_2
      city
      consignment_type
      option_code
      postal_code
      service_level
      province_code
      statuses {
        type
        status_date
      }
      candidate {
        id
        expires_at
        storage_location_id
      }
    }
  }
}
    `;

/**
 * __useGetOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByIdQuery({
 *   variables: {
 *      order_no: // value for 'order_no'
 *   },
 * });
 */
export function useGetOrderByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, options);
      }
export function useGetOrderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, options);
        }
export type GetOrderByIdQueryHookResult = ReturnType<typeof useGetOrderByIdQuery>;
export type GetOrderByIdLazyQueryHookResult = ReturnType<typeof useGetOrderByIdLazyQuery>;
export type GetOrderByIdQueryResult = Apollo.QueryResult<GetOrderByIdQuery, GetOrderByIdQueryVariables>;
export const GetOrdersChartDocument = gql`
    query GetOrdersChart($input: OrdersChartInput!) {
  orders_chart(input: $input) {
    date
    count
  }
}
    `;

/**
 * __useGetOrdersChartQuery__
 *
 * To run a query within a React component, call `useGetOrdersChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrdersChartQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersChartQuery, GetOrdersChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersChartQuery, GetOrdersChartQueryVariables>(GetOrdersChartDocument, options);
      }
export function useGetOrdersChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersChartQuery, GetOrdersChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersChartQuery, GetOrdersChartQueryVariables>(GetOrdersChartDocument, options);
        }
export type GetOrdersChartQueryHookResult = ReturnType<typeof useGetOrdersChartQuery>;
export type GetOrdersChartLazyQueryHookResult = ReturnType<typeof useGetOrdersChartLazyQuery>;
export type GetOrdersChartQueryResult = Apollo.QueryResult<GetOrdersChartQuery, GetOrdersChartQueryVariables>;
export const GetPossibleAnomalyReasonsDocument = gql`
    query GetPossibleAnomalyReasons {
  shipment_anomaly_possible_reasons {
    reason_code
    title
  }
}
    `;

/**
 * __useGetPossibleAnomalyReasonsQuery__
 *
 * To run a query within a React component, call `useGetPossibleAnomalyReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPossibleAnomalyReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPossibleAnomalyReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPossibleAnomalyReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetPossibleAnomalyReasonsQuery, GetPossibleAnomalyReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPossibleAnomalyReasonsQuery, GetPossibleAnomalyReasonsQueryVariables>(GetPossibleAnomalyReasonsDocument, options);
      }
export function useGetPossibleAnomalyReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPossibleAnomalyReasonsQuery, GetPossibleAnomalyReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPossibleAnomalyReasonsQuery, GetPossibleAnomalyReasonsQueryVariables>(GetPossibleAnomalyReasonsDocument, options);
        }
export type GetPossibleAnomalyReasonsQueryHookResult = ReturnType<typeof useGetPossibleAnomalyReasonsQuery>;
export type GetPossibleAnomalyReasonsLazyQueryHookResult = ReturnType<typeof useGetPossibleAnomalyReasonsLazyQuery>;
export type GetPossibleAnomalyReasonsQueryResult = Apollo.QueryResult<GetPossibleAnomalyReasonsQuery, GetPossibleAnomalyReasonsQueryVariables>;
export const GetRetailerStatsDocument = gql`
    query GetRetailerStats($input: RetailerStatsQueryInput!) {
  retailer_stats(input: $input) {
    kind
    unit
    change
    value
  }
}
    `;

/**
 * __useGetRetailerStatsQuery__
 *
 * To run a query within a React component, call `useGetRetailerStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetailerStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetailerStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRetailerStatsQuery(baseOptions: Apollo.QueryHookOptions<GetRetailerStatsQuery, GetRetailerStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRetailerStatsQuery, GetRetailerStatsQueryVariables>(GetRetailerStatsDocument, options);
      }
export function useGetRetailerStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRetailerStatsQuery, GetRetailerStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRetailerStatsQuery, GetRetailerStatsQueryVariables>(GetRetailerStatsDocument, options);
        }
export type GetRetailerStatsQueryHookResult = ReturnType<typeof useGetRetailerStatsQuery>;
export type GetRetailerStatsLazyQueryHookResult = ReturnType<typeof useGetRetailerStatsLazyQuery>;
export type GetRetailerStatsQueryResult = Apollo.QueryResult<GetRetailerStatsQuery, GetRetailerStatsQueryVariables>;
export const GetShipmentsDocument = gql`
    query GetShipments($input: ShipmentsQueryInput!) {
  shipments(input: $input) {
    shipments {
      id
      delivery_no
      shipment_date
      delivery_date
      statuses {
        type
        status_date
      }
      line_items {
        id
        product {
          sku
          name
        }
        quantity_packs
      }
      anomalies {
        id
        sku
        affected_quantity_packs
        reason_code
        reason_details
        product {
          name
          sku
        }
      }
    }
    page_info {
      prev_page
      next_page
    }
  }
}
    `;

/**
 * __useGetShipmentsQuery__
 *
 * To run a query within a React component, call `useGetShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShipmentsQuery(baseOptions: Apollo.QueryHookOptions<GetShipmentsQuery, GetShipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentsQuery, GetShipmentsQueryVariables>(GetShipmentsDocument, options);
      }
export function useGetShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentsQuery, GetShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentsQuery, GetShipmentsQueryVariables>(GetShipmentsDocument, options);
        }
export type GetShipmentsQueryHookResult = ReturnType<typeof useGetShipmentsQuery>;
export type GetShipmentsLazyQueryHookResult = ReturnType<typeof useGetShipmentsLazyQuery>;
export type GetShipmentsQueryResult = Apollo.QueryResult<GetShipmentsQuery, GetShipmentsQueryVariables>;