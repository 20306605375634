import React from "react"
import styled from "styled-components"

import Step, { StepStatus } from "./Step"

const Container = styled.div`
  display: flex;
`

export type TimelineStep = {
  label: string
  timeLabel: string
  duration?: string
  status: StepStatus
}

export type TimelineProps = {
  steps: TimelineStep[]
}

const Timeline = (props: TimelineProps): JSX.Element => (
  <Container>
    {props.steps.map((step) => {
      const { label, timeLabel, duration, status } = step
      return (
        <Step
          key={label + timeLabel}
          label={label}
          time={timeLabel}
          duration={duration}
          status={status}
        />
      )
    })}
  </Container>
)

export default Timeline
