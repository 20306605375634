import { css, FlattenSimpleInterpolation } from "styled-components"

import { rgba } from "polished"
import { black } from "./colors"

// Vertical padding
export const verticalPadding = (padding: string): FlattenSimpleInterpolation => css`
  padding-top: ${padding};
  padding-bottom: ${padding};
`

// Square
export const square = (size: string): FlattenSimpleInterpolation => css`
  width: ${size};
  height: ${size};
`

export const boxShadow = {
  small: `box-shadow: 0 2px 4px 0 ${rgba(black, 0.05)}`,
  medium: `box-shadow: 0 3px 10px 0 ${rgba(black, 0.075)}`,
  large: `box-shadow: 0 5px 22px 0 ${rgba(black, 0.05)}`
}

// Cover
export type CoverPosition = "absolute" | "fixed"
export const cover = (position: CoverPosition): string => {
  return `
    position: ${position} !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
}
