import { v4 } from "uuid"
import { OpeningHour } from "../../generated/graphql"
import { OpeningHoursStateEntry } from "./types"
import { DEFAULT_ENTRY_BASE, DEFAULT_OPENING_HOURS } from "./utils"

type ReducerState = OpeningHoursStateEntry[]

interface ActionInit {
  type: "init"
  payload: {
    entries: OpeningHour[]
  }
}

interface ActionUpdate {
  type: "update"
  payload: {
    _id: string
    data: Partial<OpeningHoursStateEntry>
  }
}

interface ActionDelete {
  type: "delete"
  payload: { _id: string }
}

interface ActionAdd {
  type: "add"
  // payload: never
}

export type ReducerAction = ActionInit | ActionDelete | ActionUpdate | ActionAdd

export type ReducerHandler<A extends ReducerAction> = {
  (state: ReducerState, action: A): ReducerState
}

const HandlerInit: ReducerHandler<ActionInit> = (state, action) => {
  const { entries } = action.payload
  const hasAllWeekDays = entries.filter((e) => e.day_of_week >= 0).length === 7
  if (!hasAllWeekDays) {
    return DEFAULT_OPENING_HOURS
  }
  return entries.map((e) => ({ ...e, _id: v4() }))
}

const HandleUpdate: ReducerHandler<ActionUpdate> = (state, action) => {
  const { _id, data } = action.payload
  const entry = state.find((e) => e._id === _id)
  if (!entry) return state
  const updatedEntry = { ...entry, ...data }
  return [...state.filter((e) => e._id !== _id), updatedEntry]
}

const HandlerDelete: ReducerHandler<ActionDelete> = (state, action) => {
  const entry = state.find((e) => e._id === action.payload._id)
  if (!entry) return state
  if (entry.day_of_week >= 0) return state // can't delete a weekly entry
  return state.filter((e) => e._id !== action.payload._id)
}

const HandlerAdd: ReducerHandler<ActionAdd> = (state) => {
  const entry = DEFAULT_ENTRY_BASE()
  return [...state, entry]
}

export const OPENING_HOURS_REDUCER: ReducerHandler<ReducerAction> = (state, action) => {
  switch (action.type) {
    case "init":
      return HandlerInit(state, action)
    case "delete":
      return HandlerDelete(state, action)
    case "update":
      return HandleUpdate(state, action)
    case "add":
      return HandlerAdd(state, action)
  }
}
